import axios from "@/plugin/vueAxios";
import {FlowStepsBulkInput, FlowStepsCounts} from "@/types";
import {BulkChangeResult} from "@/types/dto";

const FLOW_STEPS_PATH = '/flow-steps';
const FLOW_STEPS_COUNTS_PATH = `${FLOW_STEPS_PATH}/counts`;
const FLOW_STEPS_REJECTED_PATH = `${FLOW_STEPS_PATH}/rejected`;

export async function apiFlowStepsCounts(): Promise<FlowStepsCounts> {
  const response = await axios.get<FlowStepsCounts>(FLOW_STEPS_COUNTS_PATH);
  return response.data;
}

export async function apiFlowStepsReject(stepIds: FlowStepsBulkInput['stepIds'],
                                         rejectionNote: FlowStepsBulkInput['rejectionNote']): Promise<BulkChangeResult> {
  const rejection: FlowStepsBulkInput = {
    stepIds,
    rejectionNote
  };

  const response = await axios.post<BulkChangeResult>(FLOW_STEPS_REJECTED_PATH, rejection);
  return response.data;
}


import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import SListMenu, {MenuItem} from "@/component/ui/SListMenu.vue";
import {Replace} from "@/types";

type NotificationMenuItem = Replace<MenuItem, { name: 'processing' | 'authorization' | 'error' }>

export default mixins(mutationMixin).extend({
  name: "NotificationsMenu",
  components: {SListMenu},
  data() {
    return {
      items: [
        {
          name: 'processing',
          text: this.$t('nav.notifications.items.inProgress').toString(),
          icon: 'pen',
          url: 'processing',
          visible: true
        },
        {
          name: 'authorization',
          text: this.$t('nav.notifications.items.auth').toString(),
          icon: 'authorization',
          url: 'authorization',
          visible: true
        },
        {
          name: 'error',
          text: this.$t('nav.notifications.items.error').toString(),
          icon: 'errorCircle',
          url: 'error',
          visible: true
        }
      ] as Array<NotificationMenuItem>
    }
  },
  computed: {
    processingCount(): number {
      return this.$store.getters["interactionCenter/processingCount"]
    },
    authorizationCount(): number {
      return this.$store.getters["interactionCenter/authorizationCount"]
    },
    errorCount(): number {
      return this.$store.getters["interactionCenter/errorCount"]
    },
  },
  methods: {
    count(item: NotificationMenuItem): number {
      switch (item.name) {
        case 'processing':
          return this.processingCount
        case 'authorization':
          return this.authorizationCount
        case "error":
          return this.errorCount
      }
      throw "Unknown menu item"
    }
  }
});

import {BatchStub} from "@/types/batch";
import {HalResource, Link} from "@/types/hal";
import {Category} from "@/types/navigation";
import {BusinessLevel} from "@/types/other";
import {SignatureMethod, SignatureProfile} from "@/types/signature";
import {UserAccount} from "@/types/user";
import {OptionalString, Replace} from "@/types/utility";

export type FlowState =
    "draft"
    | "active"
    | "active_my"
    | "active_others"
    | "finished"
    | "recalled"
    | "rejected"
    | "canceled"
    | "expired";
export type FlowStepType = "signature" | "flow_activation" | "flow_restamp" | "final_processing";

export enum FlowReadFlag {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED_AND_READ = 'REQUIRED_AND_READ',
  REQUIRED_AND_UNREAD = 'REQUIRED_AND_UNREAD'
}

export enum FlowStepState {
  NEW = 'new',
  ACTIVE = 'active',
  PROCESSING = 'processing',
  ERROR = 'error',
  FINISHED = 'finished',
  REJECTED = 'rejected',
  INACTIVE = 'inactive',
  EXPIRED = 'expired'
}

/**********************************
 * FLOW
 *********************************/

export interface Flow extends Required<HalResource<FlowEmbedded, FlowLinks>> {
  changedAt: string;
  createdAt: string;
  defaultDueDate: string;
  currentPhase: number;
  dueDate: string;
  dueDateMode: string;
  enclosuresCount: number;
  hasPdfAttachments: boolean;
  flowId: number;
  highlighted: boolean;
  isStepParallel: boolean;
  lockedByStepId?: number;
  logRecords?: Array<LogRecord>;
  myActiveStepId: number;
  note: string;
  orderingDate?: string;
  state: FlowState;
  stepDueDate: string;
  stepState: FlowStepState | undefined;
  subject: string;
  submittedAt: string;
}

interface FlowEmbedded {
  category: Category;
  document: Document;
  documentFields: Array<DocumentField>;
  finalRecipients: Array<UserAccount>;
  owner: UserAccount;
  signingProfile?: Partial<SignatureProfile>;
  steps: Array<FlowStep>;
}

interface FlowLinks {
  self: Link;
  "sef:delete-flow-draft"?: Link;
  "sef:document-data-download"?: Link;
  "sef:edit-flow"?: Link;
  "sef:get-step-signing-profiles"?: Link;
  "sef:mark-step-read"?: Link;
  "sef:my-active-step"?: Link;
  "sef:recall-flow"?: Link;
  "sef:reject-flow"?: Link;
  "sef:resume-signing"?: Link;
  "sef:set-step-signing-profile"?: Link;
  "sef:sign"?: Link;
  "sef:unset-step-signing-profile"?: Link;
  "sef:enclosures-list": Link;
}

export type InputFlow = Replace<Flow, { _embedded: InputFlowEmbedded }>
type InputFlowEmbedded = Replace<FlowEmbedded, { steps: Array<InputFlowStep> }>

export interface FlowsRecall {
  flowIds: Array<Flow['flowId']>;
  recallNote?: OptionalString;
}

/**********************************
 * FLOW STEP
 *********************************/

export enum VisualFieldUsage {
  NOT_SUPPORTED = 'not_supported',
  OPTIONAL_ALL = 'optional_all',
  OPTIONAL_SP_ONLY = 'optional_sp_only',
  REQUIRED_ANY = 'required_any',
  REQUIRED_SP = 'required_sp',
}

export interface FlowStepBase {
  stepId: number;
}

export interface FlowStep extends FlowStepBase, HalResource<FlowStepEmbedded, FlowStepLinks> {
  changedAt: string;
  createdAt: string;
  dueDate: string;
  flowSubject: string;
  lastError?: string;
  logRecords?: Array<LogRecord>;
  notifyWhenFinished: true;
  otherPerson?: string;
  readFlag: FlowReadFlag;
  rejectionNote?: string;
  requestedSignatureLevel: BusinessLevel | null;
  signingMethodName?: string;
  state: FlowStepState;
  stepOrder: number;
  stepType: FlowStepType;
  thirdPersonName?: string;
  visualFieldUsage: VisualFieldUsage;
}

interface FlowStepEmbedded {
  account?: UserAccount;
  batch?: BatchStub;
  signingProfile?: SignatureProfile;
  applicableSigningProfiles?: Array<SignatureProfile>;
  applicableSignatureProviderTypes?: Array<SignatureMethod>;
}

interface FlowStepLinks {
  self?: Link;
  "sef:applicable-signing-profiles"?: Link;
  "sef:document-data-download"?: Link;
  "sef:step-remove-signature-field"?: Link;
  "sef:step-set-signature-field"?: Link;
  "sef:external-signature": Link;
  "sef:workflow": Link;
}

export interface InputFlowStep {
  requestedSignatureLevel: BusinessLevel | null;
  notifyWhenFinished: boolean;
  stepType: FlowStepType;
  _embedded: {
    account: UserAccount
  };
  stepOrder?: number;
  thirdPersonName?: string;
}

export interface FlowStepsBulkInput {
  stepIds: Array<FlowStep['stepId']>;
  rejectionNote?: OptionalString;
}

export interface FlowStepsCounts {
  authorizationCount?: number;
  errorCount?: number;
  processingCount?: number;
}

/**********************************
 * Enclosures
 *********************************/
export type FlowEnclosureList = HalResource<FlowEnclosureListEmbedded, FlowEnclosureListLinks>

interface FlowEnclosureListEmbedded {
  enclosures: Array<FlowEnclosure>
}

interface FlowEnclosureListLinks {
  self: Link;
  "sef:add-enclosure": Link;
  "sef:add-data-enclosure": Link;
}

export interface FlowEnclosureBase extends HalResource<Record<string, never>, FlowEnclosureLinks> {
  enclosureId: string;
  name: string;
  note?: string;
  itemOrder: number;
  enclosureType: 'url' | 'data';
}

export interface UrlFlowEnclosure extends FlowEnclosureBase {
  enclosureType: 'url';
  externalUrl: string;
}

export interface DataFlowEnclosure extends FlowEnclosureBase {
  enclosureType: 'data';
  contentType: string;
  fileSize: number;
  filename: string;
}

export type FlowEnclosure = UrlFlowEnclosure | DataFlowEnclosure

export const isUrlEnclosure = (enclosure: FlowEnclosure): enclosure is UrlFlowEnclosure => {
  return enclosure.enclosureType === 'url'
}
export const isDataEnclosure = (enclosure: FlowEnclosure): enclosure is DataFlowEnclosure => {
  return enclosure.enclosureType === 'data'
}

interface FlowEnclosureLinks {
  "self": Link;
  "sef:delete-enclosure": Link;
  "sef:edit-enclosure": Link;
  "sef:download-enclosure-data"?: Link;
}

/**********************************
 * LOGS
 *********************************/

export interface LogRecord extends HalResource<LogRecordEmbedded, unknown> {
  eventName: string;
  eventTimestamp: string;
  logMessage: string;
}

export interface LogRecordEmbedded {
  account?: UserAccount;
}

/**********************************
 * DOCUMENT
 *********************************/

export enum DocumentFieldType {
  SP = 'sp',
  SP_ANCHOR = 'sp_anchor',
  PDF_INVISIBLE = 'pdf_invisible',
  PDF_VISIBLE = 'pdf_visible',
}

export interface Document extends HalResource<DocumentEmbedded, DocumentLinks> {
  id: number;
  logRecords?: Array<LogRecord>;
  documentName: string;
  archivedAt?: string;
  activationProcessing?: DocumentProcessingInfo;
  finalProcessing?: DocumentProcessingInfo;
}

export interface DocumentProcessingInfo {
  sealedAt?: string;
  timestampedAt?: string;
}

export interface DocumentEmbedded {
  documentData?: DocumentData;
  tags: Array<Tag>;
}

export interface DocumentLinks {
  self: Link;
  "sef:validate-doc"?: Link;
}

export interface DocumentField extends HalResource<unknown, unknown> {
  bottom: number;
  fieldId?: number;
  height: number;
  left: number;
  name: string;
  page: number;
  setBySigner: boolean;
  stepId?: number;
  type: DocumentFieldType;
  uiName: string;
  width: number;
  stepInputNumber?: number;
}

export interface DocumentData {
  content?: string;
}

export interface Tag {
  text: string;
}


import Vue from "vue";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";

export default Vue.extend({
  name: 'SignatureOnlineDocumentInvalid',

  components: {SignatureContentLayout, STextButton},

  props: {
    actionBack: Boolean
  },

  methods: {
    back(): void {
      this.$emit('back', true);
    }
  }
})

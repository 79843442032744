import { render, staticRenderFns } from "./PDFWidget.vue?vue&type=template&id=084e4bd9&scoped=true&"
import script from "./PDFWidget.vue?vue&type=script&lang=ts&"
export * from "./PDFWidget.vue?vue&type=script&lang=ts&"
import style0 from "./PDFWidget.vue?vue&type=style&index=0&id=084e4bd9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084e4bd9",
  null
  
)

export default component.exports
import Vue from 'vue'
import axios from "@/plugin/vueAxios";
import {BANK_LIST, PERSONAL_INFO, SIGNATURE_METHODS, SIGNING_PROFILES, VISUALISATION} from "@/utils/urls";
import {i18n} from "@/plugin/i18n";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";
import {
  Bank,
  isTerminalState,
  SignatureMethod,
  SignatureProfile,
  SignatureProfileConfiguration,
  UserAccount,
  Visualisation,
  VisualisationList
} from "@/types";
import {formatDate} from "@/utils/dateUtils";
import {nameToDisplay} from "@/utils/accountUtils";
import store from '../store'

export type SignatureMethodCode = string

interface ProfileState {
  personalInfo: UserAccount | undefined;
  selectedVisualisation: Visualisation | undefined;
  visualisations: Array<Visualisation> | undefined;
  selectedSignatureMethod: SignatureMethod | undefined;
  signatureMethods: Array<SignatureMethod> | undefined;
  signatureProfiles: { [key: SignatureMethodCode]: Array<SignatureProfile> };
  signingProfilesById: { [key: number]: SignatureProfile }; // Mapování podpisových profilů podle identifikátoru profilu (profileId)
  signingProfilesByProvider: { [key: number]: Array<SignatureProfile> }; // Mapování podpisových profilů podle identifikátoru podpisové metody (providerId)
  signatureProfileConfiguration: { [key: string]: SignatureProfileConfiguration };
  banks: Array<Bank> | undefined;
  visualConfigLinks: VisualisationList | undefined;
}

const state = (): ProfileState => ({
  // Personal info
  personalInfo: undefined,

  // Visualisation
  selectedVisualisation: undefined,
  visualisations: undefined,
  visualConfigLinks: undefined,

  // Signature methods
  selectedSignatureMethod: undefined,
  signatureMethods: undefined,

  // Signature profiles
  signatureProfiles: {},

  signingProfilesById: {},
  signingProfilesByProvider: {},

  signatureProfileConfiguration: {
    "SP-A": {
      title: 'profile.signature.audit.headline',
      addNewProfile: false,
      cardConfig: {
        actions: ['edit'],
        rows: []
      },
      detail: {
        multipleProviders: true,
        visualisation: false,
        features: []
      }
    },
    "SP-ES": {
      title: 'profile.signature.simpleESig.headline',
      prependComponents: [
        {
          name: 'AddVisualisationInfo',
          props: {
            i18nText: 'profile.signature.simpleESig.info'
          }
        }
      ],
      addNewProfile: false,
      cardConfig: {
        rows: [
          {
            showIf: (profile) => !!profile?._embedded?.visual?.visualId,
            rowKey: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.name,
              showIf: (profile) => profile._embedded?.visual?.name
            },
            rowValue: {
              type: 'function',
              value: (profile: Required<SignatureProfile>) => {return profile.signingCertificate? i18n.t('profile.signature.card.validity[0]') + " " +
                  formatDate(profile.signingCertificate.validFrom, 'dd.LL.yyyy') + " " +
                  i18n.t('profile.signature.card.validity[1]') + " " +
                  formatDate(profile.signingCertificate?.validTo, 'dd.LL.yyyy'): undefined},
              showIf: (profile: SignatureProfile) => profile.signingCertificate
            },
          },
          {
            showIf: (profile) => !!profile?._embedded?.visual?.visualId,
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.visualization'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.visualName,
              showIf: (profile) => profile._embedded?.visual
            },
            visualization:(profile)=> profile?._embedded?.visual
          },
          {
            showIf: (profile) => !profile?._embedded?.visual?.visualId,
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.visualization'
            },
            rowValue: {
              type: 'i18nText',
              value: 'profile.signature.adHocVisualization'
            }
          }
        ]
      }
    },
    "SP-WES": {
      title: 'profile.signature.witnessESig.headline',
      prependComponents: [
        {
          name: 'AddVisualisationInfo',
          props: {
            i18nText: 'profile.signature.witnessESig.info'
          }
        },
        {
          name: 'PhoneNumberSelector'
        }
      ],
      addNewProfile: false,
      cardConfig: {
        rows: [
          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.name,
              showIf: (profile: SignatureProfile) => profile._embedded?.visual?.name
            },
            rowValue: {
              type: 'function',
              value: (profile: Required<SignatureProfile>) => {return profile.signingCertificate? i18n.t('profile.signature.card.validity[0]') + " " +
                  formatDate(profile.signingCertificate.validFrom, 'dd.LL.yyyy') + " " +
                  i18n.t('profile.signature.card.validity[1]') + " " +
                  formatDate(profile.signingCertificate?.validTo, 'dd.LL.yyyy'): undefined},
              showIf: (profile: SignatureProfile) => profile.signingCertificate
            },
          },

          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.visualization'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.visualName,
              showIf: (profile) => profile._embedded?.visual
            },
            visualization: (profile)=>profile._embedded?.visual
          },
          {
            showIf: ()=> true,
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.sigLevel'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile.legalLevel
            }
          }
          ]
      }
    },
    "RS-ICA": {
      title: 'profile.signature.remoteICA.headline',
      addNewProfile: true,
      cardConfig: {
        actions: ['remove', 'edit'],
        rows: [
          {
            rowKey:{
              type: 'i18nText',
              value: 'profile.signature.remoteICA.externalId',
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile.externalId
            },
            showIf: ()=> true
          },
          {
            rowKey:{
              type: 'i18nText',
              value: 'profile.signature.card.visualization',
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.visualName,
              showIf: (profile) => profile._embedded?.visual,
              fallback: {
                type: 'i18nText',
                value: 'profile.signature.noVisual',
                bold: true
              }
            },
            showIf: ()=> true
          },
          {
            rowKey:{
              type: 'i18nText',
              value: 'profile.signature.card.sigLevel',
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile.legalLevel
            },
            showIf: ()=> true
          }
        ]
      },
      detail: {
        multipleProviders: true,
        visualisation: true,
        features: [
          {
            name: "ExternalIdFeature",
            props: {
              titleI18n: "profile.signature.remoteICA.externalId"
            }
          }
        ]
      }
    },
    "SP-ClientSigning": {
      title: 'profile.signature.card.headline',
      addNewProfile: true,
      prependComponents: [
        {
          name: 'ClientSignatureAppWarning'
        }
      ],
      cardConfig: {
        actions: (profile: SignatureProfile) => profile.isCaProfile ? ['edit'] : ['remove', 'edit'],

        caActionComponent:{
          showIf: (profile: SignatureProfile) => !profile.isCaProfile
        },
        rows: [
          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.name,
              showIf: (profile: SignatureProfile) => profile._embedded?.visual?.name
            },
            rowValue: {
              type: 'function',
              value: (profile: Required<SignatureProfile>) => {return profile.signingCertificate? i18n.t('profile.signature.card.validity[0]') + " " +
                  formatDate(profile.signingCertificate.validFrom, 'dd.LL.yyyy') + " " +
                  i18n.t('profile.signature.card.validity[1]') + " " +
                  formatDate(profile.signingCertificate?.validTo, 'dd.LL.yyyy'): undefined},
              showIf: (profile: SignatureProfile) => profile.signingCertificate
            },
          },
          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.visualization'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.visualName,
              showIf: (profile) => profile._embedded?.visual,
              fallback: {
                type: 'i18nText',
                value: 'profile.signature.noVisual',
                bold: true
              }
            },
          },
          {
            showIf: ()=> true,
            rowKey: {
              type: "i18nText",
              value: 'profile.signature.card.sigLevel'
            },
            rowValue: {
              type: 'function',
              value:(profile: SignatureProfile) => profile.legalLevel
            }
          },
          {
            showIf: (profile) => !!profile?.isCaProfile,
            rowKey: {
              type: "i18nText",
              value: 'profile.signature.card.ca'
            },
            rowValue: {
              type: "function",
              value: (profile: SignatureProfile) => profile.caSubject
            }
          },
        ],
        busyState: {
          // !! is ok, because there is no certificate state 0
          isBusy: (profile: SignatureProfile) => !!profile.certificateState &&
              !isTerminalState(profile.certificateState),
          component: 'ClientProviderCardBusyState',
          refresh: {
            interval: 1000,
            callback: (profile: SignatureProfile, providerTypeCode: string) => {
              store.dispatch('profile/loadSignatureProfile',
                  {id: profile.profileId, method: providerTypeCode})
            }
          }
        }
      },
      detail: {
        allowNameEdit: (profile: SignatureProfile) => !profile.isCaProfile,
        multipleProviders: true,
        visualisation: true,
        features: [
          {
            name: 'ClientProviderCardCertCreateWarning'
          }
        ],
        // we create profiles disabled by default, because there is no cert initially
        beforeCreateHook: (profile: SignatureProfile) => { profile.isEnabled = false }
      }
    },
    "RS-TX-EIDAS": {
      title: 'profile.signature.remoteTX.headline',
      addNewProfile: false,
      cardConfig: {
        actions: ['edit'],
        rows: [
          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.name,
              showIf: (profile: SignatureProfile) => profile._embedded?.visual?.name
            },
            rowValue: {
              type: 'function',
              value: (profile: Required<SignatureProfile>) => i18n.t('profile.signature.card.validity[0]') + " " +
                  formatDate(profile.signingCertificate.validFrom, 'dd.LL.yyyy') + " " +
                  i18n.t('profile.signature.card.validity[1]') + " " +
                  formatDate(profile.signingCertificate.validTo, 'dd.LL.yyyy'),
              showIf: (profile: SignatureProfile) => profile.signingCertificate
            }
          },
          {
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.remoteTX.externalId'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile.externalId
            },
            showIf: () => true
          },
          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: "i18nText",
              value: 'profile.signature.card.visualization'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.visual?.visualName,
              showIf: (profile) => profile._embedded?.visual,
              fallback: {
                type: 'i18nText',
                value: 'profile.signature.noVisual',
                bold: true
              }
            },
          },
          {
            showIf: ()=> true,
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.sigLevel'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile.legalLevel
            }
          }
        ]
      },
      detail: {
        multipleProviders: false,
        visualisation: true
      }
    },
    "RS-BANK-ID": {
      title: 'profile.signature.remoteBankId.headline',
      addNewProfile: true,
      cardConfig: {
        actions: ['remove', 'edit'],
        rows: [
          {
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.bank'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile._embedded?.bank?.title,
              showIf: (profile) => profile._embedded?.bank,
              fallback: {
                type: 'i18nText',
                value: 'profile.signature.remoteBankId.noBank',
                bold: true
              }
            },
            showIf: ()=> true
          },
          {
            rowKey: {
              type: 'i18nText',
              value: 'profile.signature.card.sigLevel'
            },
            rowValue: {
              type: 'function',
              value: (profile: SignatureProfile) => profile.legalLevel
            },
            showIf: ()=> true
          }
        ]
      },
      detail: {
        multipleProviders: true,
        visualisation: false,
        features: [
          {
            name: "BankFeature"
          }
        ]
      }
    },
    "SP-3PHS": {
      title: 'profile.signature.thirdPerson.headline',
      addNewProfile: false,
      cardConfig: {
        actions: ['edit'],
        rows:[
          {
            showIf: (profile) => !!profile?._embedded?.visual,
            rowKey: {
              type: "i18nText",
              value: 'profile.signature.card.visualization'
            },
            rowValue: {
              type: 'i18nText',
              value: 'profile.signature.adHocVisualization'
            }
          }
        ]
      },
      detail: {
        multipleProviders: true,
        visualisation: false,
        features: []
      }
    },
    "SP-EXS": {
      title: 'profile.signature.externalSignature.headline',
      prependComponents: [
        {
          name: 'AddVisualisationInfo',
          props: {
            i18nText: 'profile.signature.externalSignature.info',
            textWithoutLink: true
          }
        }
      ],
      addNewProfile: false,
      cardConfig: {
        actions: [],
        rows: []
      },
      detail: {
        multipleProviders: true,
        visualisation: false,
        features: []
      }
    },
  },

  // Other
  banks: undefined

})

const mutations: MutationTree<ProfileState> = {
  // Personal info
  setPersonalInfo(state, payload: { info: UserAccount }) {
    state.personalInfo = payload.info;
  },

  // Visualisations
  selectVisualisation(state, payload: { visualisation: Visualisation }) {
    state.selectedVisualisation = payload.visualisation
  },

  createNewVisualisation() {
    // Empty on purpose
    // Acts as an event
  },

  setVisualisations(state, payload: { visuals: Array<Visualisation> }) {
    state.visualisations = payload.visuals;
  },

  setVisualConfigLinks(state, payload: { config: VisualisationList }) {
    state.visualConfigLinks = payload.config;
  },

  addVisualisation(state, payload: { visual: Visualisation }) {
    state.visualisations?.push(payload.visual)
  },

  editVisualisation(state, payload: { visual: Visualisation }) {
    const index = state.visualisations?.findIndex(v => v.visualId === payload.visual.visualId);
    if (index !== undefined && index !== -1) {
      if (state.visualisations) {
        state.visualisations[index] = payload.visual;
      }
    }
  },

  removeVisualisation(state, payload: { visualisations: Array<Visualisation>; id: number }) {
    state.visualisations = state.visualisations?.filter(v => v.visualId !== payload.id);
    state.signatureProfiles = {};
  },

  // Signature methods
  selectSignatureMethod(state, payload: { method: SignatureMethod }) {
    state.selectedSignatureMethod = payload.method;
  },

  setSignatureMethods(state, payload: { methods: Array<SignatureMethod> }) {
    state.signatureMethods = payload.methods;
  },

  // Signature profiles
  setSignatureProfilesForMethod(state, payload: { method: string; profiles: Array<SignatureProfile> }) {
    const code = payload.method;
    // Trigger Vue reactivity
    state.signatureProfiles = Object.assign({}, state.signatureProfiles, {[code]: payload.profiles});
  },

  addSignatureProfileForMethod(state, payload: { method: string; profile: SignatureProfile }) {
    const code = payload.method;
    if (state.signatureProfiles[code]) {
      state.signatureProfiles[code].push(payload.profile);
    }
    else {
      this.setSignatureProfilesForMethod(state, {method: code, profiles: [payload.profile]});
    }
  },

  updateSignatureProfileForMethod(state, payload: { method: string; profile: SignatureProfile }) {
    const code = payload.method;
    if (state.signatureProfiles[code]) {
      const index = state.signatureProfiles[code].findIndex(p => p.profileId === payload.profile.profileId);
      if (index !== -1) {
        Vue.set(state.signatureProfiles[code], index, payload.profile);
      }
    }
  },

  deleteSignatureProfileForMethod(state, payload: { method: string; profile: SignatureProfile }) {
    const code = payload.method;
    if (state.signatureProfiles[code]) {
      const index = state.signatureProfiles[code].findIndex(p => p.profileId === payload.profile.profileId);
      state.signatureProfiles[code].splice(index, 1);
    }
  },

  setSignatureProfileActivity(state, payload: { code: string; profileId: number; enabled: boolean }) {
    const code = payload.code;
    for (const profile of state.signatureProfiles[code]) {
      if (profile.profileId === payload.profileId) {
        profile.isEnabled = payload.enabled;
        // prohodime linky, podle kterych se pozna, jestli profil muzeme deaktivovat
        // (linky vedou na stejne misto)
        if (profile._links) {
          const temp = profile._links['sef:activate-signing-profile'];
          profile._links['sef:activate-signing-profile'] =
              profile._links['sef:deactivate-signing-profile'];
          profile._links['sef:deactivate-signing-profile'] = temp;
        }
      }
    }
  },

  setSigningProfilesById(state, payload: { signingProfilesById: { [key: number]: SignatureProfile } }) {
    state.signingProfilesById = payload.signingProfilesById;
  },
  setSigningProfilesByProvider(state,
                               payload: { signingProfilesByProvider: { [key: number]: Array<SignatureProfile> } }) {
    state.signingProfilesByProvider = payload.signingProfilesByProvider;
  },
  setBanks(state, payload: { banks: Array<Bank> }) {
    state.banks = payload.banks
  },
}

const actions: ActionTree<ProfileState, RootState> = {
  loadPersonalInfo({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(PERSONAL_INFO)
          .then((response) => {
            commit('setPersonalInfo', {info: response.data})
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
  loadVisualisations({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(VISUALISATION)
          .then((response) => {
            commit('setVisualisations', {visuals: response.data._embedded.visuals})
            commit('setVisualConfigLinks', {config: response.data._links});
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
  loadSignatureMethods({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(SIGNATURE_METHODS)
          .then((response) => {
            commit('setSignatureMethods', {methods: response.data._embedded.signatureProviderTypes})
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
  async loadSigningProfiles({commit}): Promise<void> {
    const response = await axios.get(SIGNING_PROFILES);
    const signingProfiles = response.data._embedded.signingProfiles as Array<SignatureProfile>;
    const signingProfilesById = {} as { [key: number]: SignatureProfile };
    const signingProfilesByProvider = {} as { [key: string]: Array<SignatureProfile> };
    if (signingProfiles) {
      signingProfiles.forEach(profile => {
        signingProfilesById[profile.profileId] = profile;

        const provider = profile._embedded?.signatureProvider;
        if (provider) {
          if (!signingProfilesByProvider[provider.providerId]) {
            signingProfilesByProvider[provider.providerId] = [];
          }
          signingProfilesByProvider[provider.providerId].push(profile);
        }
      });
    }
    commit('setSigningProfilesById', {signingProfilesById: signingProfilesById});
    commit('setSigningProfilesByProvider', {signingProfilesByProvider: signingProfilesByProvider});
  },
  loadSignatureProfilesForMethod({commit}, payload: { id: number, code: string }) {
    axios.get(SIGNING_PROFILES, {params: {providerTypeId: payload.id}})
        .then((response) => {
          commit('setSignatureProfilesForMethod',
              {method: payload.code, profiles: response.data._embedded.signingProfiles})
        })
        .catch(() => {/**/})
  },
  // method is in the payload, because there is no way right now, how to derive it from the profile data
  loadSignatureProfile({commit}, payload: { id: SignatureProfile['profileId'], method: string }) {
    axios.get(SIGNING_PROFILES + '/' + payload.id).then((response) => {
      commit('updateSignatureProfileForMethod', {method: payload.method, profile: response.data})
    }).catch(() => {/**/})
  },

  // Other
  loadBanks({commit}): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.get(BANK_LIST)
          .then((response) => {
            commit('setBanks', {banks: response.data.items})
            resolve();
          })
          .catch(() => {
            reject();
          })
    })
  },
}

const getters: GetterTree<ProfileState, RootState> = {
  // Personal info
  info: (state): UserAccount | undefined => {
    return state.personalInfo;
  },

  name: (state) => {
    if (!state.personalInfo) return undefined;
    return nameToDisplay(state.personalInfo);
  },
  accountName: (state) => {
    if (!state.personalInfo) return undefined;
    return state.personalInfo.accountName;
  },
  email: (state) => {
    if (!state.personalInfo) return undefined;
    return state.personalInfo.userEmail;
  },
  avatar: (state): string | undefined => {
    if (!state.personalInfo) return undefined;
    return state.personalInfo?._links?.userAvatar?.href;
  },
  number: (state) => {
    if (!state.personalInfo) return undefined;
    return state.personalInfo.userPhoneNumber;
  },
  accountType: (state) => {
    if (!state.personalInfo) return undefined;
    return state.personalInfo.accountType;
  },

  // Visualisation
  selectedVisualisation: (state): Visualisation | undefined => {
    return state.selectedVisualisation;
  },

  visualisations: (state): Array<Visualisation> | undefined => {
    return state.visualisations;
  },

  visualConfigLinks: (state) => {
    return state.visualConfigLinks;
  },

  // Signature
  selectedSignatureMethod: (state): SignatureMethod | undefined => {
    return state.selectedSignatureMethod;
  },

  signatureMethods: (state) => {
    return state.signatureMethods;
  },

  // Signature profiles
  signatureProfiles: (state): { [key: string]: Array<SignatureProfile> } => {
    return state.signatureProfiles;
  },
  signingProfileById: (state) => (id: number): SignatureProfile => {
    return state.signingProfilesById[id];
  },
  signatureProfileConfiguration: (state) => {
    return state.signatureProfileConfiguration;
  },

  // Other
  banks: (state): Array<Bank> | undefined => {
    return state.banks;
  }
}

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

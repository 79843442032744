
import Vue, {PropType} from 'vue';
import VueI18n from "vue-i18n";
import {NavigationMode} from "@/types";

interface NavTab {
  icon: string;
  title: VueI18n.TranslateResult,
  value: NavigationMode
}

export default Vue.extend({
  name: 'NavigationTabs',
  props: {
    value: {
      type: String as PropType<NavigationMode>,
      required: true
    }
  },
  computed: {
    localValue: {
      get(): number|undefined {
        return this.findItemIndex(this.value);
      },
      set(value: number) {
        this.$emit('input', this.findItemByIndex(value));
      }
    },
    tabs(): Array<NavTab> {
      return [
        {
          icon: 'folders',
          title: this.$t('nav.content.categories'),
          value: NavigationMode.NAV
        },
        {
          icon: 'user',
          title: this.$t('nav.content.profile'),
          value: NavigationMode.PROFILE
        }
      ]
    }
  },
  methods: {
    findItemByIndex(index: number): NavigationMode {
      return this.tabs[index].value;
    },
    findItemIndex(activeItem: NavigationMode): number|undefined {
      let index = this.tabs.findIndex((item) => item.value === activeItem);
      return index < 0 ? undefined : index;
    }
  }
})

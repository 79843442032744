
import Vue from "vue";
import SImg from "@/component/ui/SImg.vue";
import {organisationLogoURL} from "@/services/api/appDetailsApi";

export default Vue.extend({
  name: 'SignatureContentLogo',

  components: {SImg},

  computed: {
    organisationLogoURL(): string {
      return organisationLogoURL();
    }
  }
})

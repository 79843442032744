
import InteractionCenter from "@/component/notifications/list/InteractionCenter.vue";
import mutationMixin from "@/mixins/mutationMixin";
import mixins from "vue-typed-mixins";
import {MutationPayload} from "vuex";
import {Batch} from "@/types";

export default mixins(mutationMixin).extend({
  name: 'OverviewBottomSheet',
  components: {InteractionCenter},
  data() {
    return {
      localValue: false,
      localBatch: undefined as Batch|undefined
    }
  },
  created() {
    this.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'interactionCenter/showBatchProcessing') {
        this.localBatch = mutation.payload.batch;
        this.localValue = true;
      }
    });
  }
})

import { render, staticRenderFns } from "./ProfileHeaderMain.vue?vue&type=template&id=3275dfaa&scoped=true&"
import script from "./ProfileHeaderMain.vue?vue&type=script&lang=ts&"
export * from "./ProfileHeaderMain.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileHeaderMain.vue?vue&type=style&index=0&id=3275dfaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3275dfaa",
  null
  
)

export default component.exports

import {PropType} from "vue"
import mixins from "vue-typed-mixins";
import formMixin from "@/mixins/formMixin";
import {ValidationProvider} from 'vee-validate';
import SImg from "@/component/ui/SImg.vue";
import {CancelTokenSource} from "axios";

export default mixins(formMixin).extend({
  name: "SFileDropZone",
  components: {
    ValidationProvider,
    SImg
  },
  props: {
    disable: Boolean,
    imageFallBack: String,
    uploadUrl: String,
    filePlaceholder: String,
    value: Object as PropType<File | undefined>,
    type: {
      type: String as PropType<'pdf' | 'image' | 'generic'>,
      default: 'pdf'
    }
  },
  data() {
    return {
      dropzoneOver: false,
      hover: false,
      uploading: false,
      validationDisable: false,
      uploadError: undefined as Error | undefined,
      source: undefined as CancelTokenSource | undefined,

    }
  },
  computed: {
    localValue: {
      get(): File | undefined {
        return this.value;
      },
      set(value: File | undefined) {
        this.$emit('input', value);
      }
    },
    isFilePresent(): boolean {
      return this.localValue !== undefined;
    },
    docTypeText(): string {
      switch (this.type) {
        case "image":
          return this.$t('common.fileUploader.docType.image').toString();
        case "pdf":
          return this.$t('common.fileUploader.docType.pdf').toString();
        case "generic":
          return this.$t('common.fileUploader.docType.generic').toString();
        default:
          throw "Unsupported type";
      }
    },
    filename(): string | undefined {
      if (this.filePlaceholder) {
        return this.filePlaceholder;
      }
      else {
        return this.localValue?.name;
      }
    },
    image() {
      return this.type === 'image';
    }
  },
  methods: {
    async onFileChange(e: InputEvent): Promise<void> {
      if (!!this.localValue && (e.target as HTMLInputElement).files?.length === 0) return;

      const {valid} = await (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).validate(e);
      if (valid) {
        const file: File | undefined = (e.target as HTMLInputElement).files?.[0];
        this.localValue = file;
        if (this.uploadUrl && file) {
          this.uploadFile(file);
        }
      }
    },
    uploadFile(file: File): void {
      if (!file || !this.uploadUrl) return;
      this.$emit('uploaded');
      this.uploading = true;
      this.source = this.axios.CancelToken.source();

      // Multipart Content
      const uploadFile = new FormData();
      let fileName = file.name;
      uploadFile.append("documentData", file, encodeURIComponent(fileName));

      this.axios.post(this.uploadUrl, uploadFile,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            cancelToken: this.source.token,
          }
      ).then((response) => {
        setTimeout(() => {
          this.uploading = false;
          this.$emit('uploaded', response.data);
        }, 500);
      }).catch((e: Error) => {
        this.localValue = undefined;
        this.uploading = false;
        this.uploadError = e;
      });

    },
    removeFile(): void {
      this.localValue = undefined;
      this.$emit('file-remove');
    },
    cancelUpload() {
      if (this.source) {
        this.source.cancel();
        this.removeFile();
      }
    },
    reset() {
      this.uploading = false;
      this.uploadError = undefined;
      (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).value = undefined;
      (this.$refs.fileValidator as InstanceType<typeof ValidationProvider>).reset();
      (this.$refs.fileInput as any).value = null;
    }
  },
  watch: {
    localValue(value: File | undefined) {
      if (!value) this.reset();
    }
  }
})


import {ValidationProvider} from "vee-validate";
import mixins from "vue-typed-mixins";
import formMixin from "@/mixins/formMixin";

export default mixins(formMixin).extend({
  name: 'STextField',
  components: {
    ValidationProvider
  },
  props: {
    appendIcon: String,
    counter: [Number, String],
    customMessages: Object,
    dataTestid: {
      type: String,
      default: 'STextField'
    },
    disabled: Boolean,
    hideDetails: Boolean,
    hint: String,
    id: String,
    label: String,
    maxLength: Number,
    placeholder: String,
    prefix: String,
    prependIcon: String,
    readonly: Boolean,
    suffix: String,
    type: String,
    value: [Number, String]
  },
  computed: {
    localValue: {
      get(): number | string {
        return this.value;
      },
      set(value: number | string): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    maxLength(): void {
      this.setInputMaxLength();
    }
  },
  mounted() {
    this.setInputMaxLength();
  },
  methods: {
    onkeydown(e: KeyboardEvent): void {
      this.$emit('keydown', e);
    },
    setInputMaxLength(): void {
      if (!this.maxLength)
        return;

      const input = this.$el.querySelector('input') as HTMLInputElement;
      input.maxLength = this.maxLength;
    }
  }
})

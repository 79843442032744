
import {Component} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import ProfileHeaderDetail from "@/component/profile/ProfileHeaderDetail.vue";
import ProfileHeaderMain from "@/component/profile/ProfileHeaderMain.vue";
import ProfileLayoutDual from "@/component/profile/ProfileLayoutDual.vue";
import ProfileLayoutSingle from "@/component/profile/ProfileLayoutSingle.vue";
import SignatureMethodList from "@/component/profile/signatures/SignatureMethodList.vue";
import VisualisationList from "@/component/profile/visualisation/VisualisationList.vue";

enum LayoutType {
  SINGLE = 'single',
  DUAL = 'dual'
}

export default mixins(displayMixin).extend({
  name: 'UserProfileView',
  components: {ProfileHeaderDetail, ProfileHeaderMain},
  data() {
    return {
      detail: false,
      detailTitle: undefined as string | undefined,
    }
  },
  computed: {
    layoutComponent(): Component {
      switch (this.layoutName) {
        case LayoutType.SINGLE:
          return ProfileLayoutSingle;
        case LayoutType.DUAL:
        default:
          return ProfileLayoutDual;
      }
    },
    layoutName(): string {
      return this.$route.meta?.layout || 'dual';
    },
    title(): string {
      return this.$route.meta?.title;
    },
    mainBackgroundColor(): string|undefined {
      switch (this.layoutName) {
        case LayoutType.DUAL:
          return 'mainBackground';
        case LayoutType.SINGLE:
        default:
          return 'transparent';
      }
    },
    detailBackgroundColor(): string {
      return 'transparent';
    },
  },
  methods: {
    navOpen(): void {
      this.$emit('nav-open');
    },
    showDetail(): void{
      this.detail = true;
    },
    closeDetail(): void {
      let mainViewComponent = (this.$refs.mainView as InstanceType<typeof VisualisationList | typeof SignatureMethodList>);
      if(this.displayMobile && mainViewComponent.unselect){
        mainViewComponent.unselect();
      }
      this.detail = false;
    },
    setDetailTitle(title: string|undefined){
      this.detailTitle = title;
    },
  }
})


import Vue from "vue";

export default Vue.extend({
  name: 'UserActions',

  created() {
    this.$emit('set-title', undefined);
  }
})


import Vue from "vue";

export default Vue.extend({
  name: 'InteractionCenterCard',

  props: {
    dense: Boolean,
    disabled: Boolean
  }
})

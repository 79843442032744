
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import {DisplayVariant} from "@/types";

export default mixins(displayMixin).extend({
  name: 'ProfileLayoutDual',
  mixins: [displayMixin],
  props: {
    detail: Boolean
  },
  data() {
    return {
      layoutConfig: {
        mobile: {
          colsMain: 12,
          colsDetail: 12
        },
        tablet: {
          colsMain: 6,
          colsDetail: 6
        },
        desktop: {
          colsMain: 6,
          colsDetail: 6
        }
      } as Record<DisplayVariant, {colsMain: number, colsDetail: number}>
    }
  },
  computed: {
    colsMain(): number {
      return this.layoutConfig[this.displayActive].colsMain;
    },
    colsDetail(): number {
      return this.layoutConfig[this.displayActive].colsDetail;
    },
    showMain(): boolean {
      return !this.displayMobile || !this.detail;
    },
    showDetail(): boolean {
      return !this.displayMobile || this.detail;
    }
  }
})

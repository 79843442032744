import {HalResource, Link} from "@/types/hal";
import {Bank} from "@/types/other";

/**********************************
 * CARD CLIENT SIGNING
 *********************************/

export interface CardClientConfig {
  clientDownloadUrl: string;
}

/**********************************
 * SIGNATURE METHOD
 *********************************/

export interface SignatureMethod extends HalResource<SignatureMethodEmbedded, SignatureMethodLinks> {
  description?: string;
  legalLevelNames: Array<string>;
  providerTypeCode: string;
  providerTypeId: number;
  title: string;
}

export interface SignatureMethodEmbedded {
  providers: Array<SignatureProvider>;
}

export interface SignatureMethodLinks {
  self: Link;
}

/**********************************
 * SIGNATURE PROFILE
 *********************************/

export enum CertificateStateValues {
  INIT_TIMEOUT = -1,
  SELECT_TIMEOUT = -2,
  INIT = 1,
  SELECT = 2,
  FINISHED = 3,
  FAILED = 4,
  CLIENT_CANCEL = 5
}

export type CertificateState = CertificateStateValues | null
export const isTerminalState = (certificateState: CertificateState) => certificateState !==
    CertificateStateValues.INIT && certificateState !== CertificateStateValues.SELECT
export const isCancelled = (certificateState: CertificateState) => certificateState ===
    CertificateStateValues.CLIENT_CANCEL
// !!certificateState works because certificateState cannot be 0
export const isTimeout = (certificateState: CertificateState) => !!certificateState && certificateState < 0
export const isFailed = (certificateState: CertificateState) => certificateState === CertificateStateValues.FAILED

export interface SignatureProfile extends HalResource<SignatureProfileEmbedded, SignatureProfileLinks> {
  bankId?: string;
  externalId?: string;
  fieldRestrictionsNewField: SigningProfileFieldRestrictions;
  fieldRestrictionsPdfField: SigningProfileFieldRestrictions;
  isEnabled: boolean;
  itemOrder: number;
  legalLevel: string;
  profileId: number;
  profileName: string;
  signingCertificate?: SigningCertificate;
  signingMethodCode: string
  signingMethodName: string;
  signingMethodNameShort: string;
  signingMethodSuffix: string;
  certificateState?: CertificateState;
  isCaProfile: boolean;
  caSubject?: string;
  inputs: SignatureProfileInputs;
}

export interface SignatureProfileEmbedded {
  signatureProvider?: SignatureProvider;
  visual?: Visualisation;
  bank?: Bank;
}

export interface SignatureProfileInputs {
  required: Array<SignatureProfileInputsItem>;
  notSupported: Array<SignatureProfileInputsItem>;
  optional: Array<SignatureProfileInputsItem>;
  redundant: Array<SignatureProfileInputsItem>;
}

export enum SignatureProfileInputsItem {
  IMAGE = 'IMAGE',
  USERNAME = 'USERNAME',
  SIGNATURE_DETAILS = 'SIGNATURE_DETAILS'
}

export interface SignatureProfileLinks {
  self: Link;
  "sef:edit-signing-profile"?: Link;
  "sef:remove-signing-profile"?: Link;
  "sef:activate-signing-profile"?: Link;
  "sef:deactivate-signing-profile"?: Link;
  "sef:get-certificate-select-uri"?: Link;
  "sef:cancel-certificate-select"?: Link;
  "sef:delete-certificate-select-state"?: Link;
}

export interface SigningCertificate {
  algorithm: string;
  certificateChain: Array<CertificateChain>;
  issuer: string;
  serialNumber: string;
  subject: string;
  validFrom: string;
  validTo: string;
}

export interface CertificateChain {
  x509: string;
}

export interface SigningProfileFieldRestrictions {
  maxWidth: Array<string>;
  minWidth: Array<string>;
  maxHeight: Array<string>;
  minHeight: Array<string>;
}

export interface SignatureProfileConfiguration {
  title: string;
  prependComponents?: Array<SignatureProfileComponent>;
  appendComponent?: SignatureProfileComponent;
  addNewProfile?: boolean,
  cardConfig: SignatureProfileCardConfig;
  detail?: SignatureProfileDetailConfig;
}

export interface SignatureProfileComponent {
  name: string;
  props?: { [key: string]: string | boolean};
}

type ValueOrDerivedFromProfile<T> = T | ((profile: SignatureProfile) => T)

export type SignatureProfileCardConfigActions = Array<"remove" | "edit">;

export interface SignatureProfileCardConfig {
  actions?: ValueOrDerivedFromProfile<SignatureProfileCardConfigActions>;
  rows: Array<SignatureProfileCardRow>;
  caActionComponent?: CaActionComponent;
  busyState?: {
    isBusy: (profile: SignatureProfile) => boolean;
    // BEWARE: the component must be imported
    component: string,
    refresh?: {
      interval: number,
      callback: (profile: SignatureProfile, providerTypeCode: string) => void
    }
  }
}

export type SignatureProfileCardRow = {
  showIf: (profile?: SignatureProfile) => boolean
  rowKey: SignatureProfileCardRowValue
  rowValue: SignatureProfileCardRowValue
  visualization?: (profile: SignatureProfile) => Visualisation | undefined,
}

export type CaActionComponent = {
  showIf: (profile: SignatureProfile) => boolean
}

export type SignatureProfileCardRowValueType = "function" | "text" | "i18nText";
export type SignatureProfileCardRowValueContent =
    string
    | ((profile: SignatureProfile) => string | undefined)
    | ((profile: Required<SignatureProfile>) => string | undefined);

export interface SignatureProfileCardRowValue {
  type: SignatureProfileCardRowValueType;
  value: SignatureProfileCardRowValueContent;
  showIf?: (profile: SignatureProfile) => unknown;
  fallback?: SignatureProfileCardRowValue;
  bold?: boolean;
}

export interface SignatureProfileDetailConfig {
  allowNameEdit?: ValueOrDerivedFromProfile<boolean>;
  multipleProviders: boolean;
  visualisation: boolean;
  features?: Array<SignatureProfileComponent>;
  // enables provider type specific modification before the profile is created
  beforeCreateHook?: (profile: SignatureProfile) => void;
  postCreateHook?: (profile: SignatureProfile) => void;
}

export enum SignatureProfileFormMode {
  NONE, CREATE, UPDATE
}

/**********************************
 * SIGNATURE PROVIDER
 *********************************/

export interface SignatureProvider extends HalResource<unknown, SignatureProviderLinks> {
  providerId: number;
}

export interface SignatureProviderLinks {
  self: Link
}

/**********************************
 * VISUALISATION
 *********************************/

export interface Visualisation extends HalResource<unknown, VisualisationLinks> {
  changedAt?: string;
  company?: string;
  contactInfo?: string;
  createdAt?: string;
  location?: string;
  logo?: string;
  name?: string;
  reason?: string;
  signatureImage?: string;
  signingText?: string;
  title?: string;
  visualId?: number;
  visualName?: string;
}

export interface VisualisationLinks {
  self?: Link;
  "sef:delete-visual"?: Link;
  "sef:update-visual"?: Link;
}

export interface VisualisationListLinks {
  self: Link;
  "sef:add-visual"?: Link;
}

export interface VisualisationListEmbedded {
  visuals?: Array<Visualisation>
}

export type VisualisationList = HalResource<VisualisationListEmbedded, VisualisationListLinks>

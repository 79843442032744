
import _ from "lodash";
import Vue, {PropType} from "vue";

enum AlertVariant {
  ERROR = 'error',
  INFO = 'info'
}

export default Vue.extend({
  name: 'SignatureContentAlert',

  props: {
    variant: {
      type: String as PropType<AlertVariant>,
      default: 'info',
      validator(value: unknown) {
        return _.isString(value) && ['error', 'info'].includes(value);
      }
    }
  },

  computed: {
    error(): boolean {
      return this.variant === AlertVariant.ERROR;
    },
    info(): boolean {
      return this.variant === AlertVariant.INFO;
    }
  }
})

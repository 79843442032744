
import Vue from "vue";

export default Vue.extend({
    name: 'SHorizontalScrollableContainer',
    data(){
      return {
        isDown: false,
        startX: 0,
        scrollLeft: 0,
      }
    },
    methods: {
      mouseDown(e: MouseEvent){
        this.isDown = true;
        this.startX = e.pageX - (this.$refs.draggableContainer as HTMLElement).offsetLeft;
        this.scrollLeft = (this.$refs.draggableContainer as HTMLElement).scrollLeft;
      },
      mouseLeave(){
        this.isDown = false;
      },
      mouseUp(){
        this.isDown = false;
      },
      mouseMove(e: MouseEvent){
        if(!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - (this.$refs.draggableContainer as HTMLElement).offsetLeft;
        const walk = (x - this.startX);
        (this.$refs.draggableContainer as HTMLElement).scrollLeft = this.scrollLeft - walk;
      }
    }
  })

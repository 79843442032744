
import interactionCenterBatchMixin from "@/mixins/interactionCenterBatchMixin";
import InteractionCenterBatchReportRows from "@/component/notifications/list/InteractionCenterBatchReportRows.vue";
import SProgressLinear from "@/component/ui/SProgressLinear.vue";
import SProgressStacked from "@/component/ui/SProgressStacked.vue";
import mixins from "vue-typed-mixins";
import STooltip from "@/component/ui/STooltip.vue";

export default mixins(interactionCenterBatchMixin).extend({
  name: 'InteractionCenterBatchAuthorization',
  components: {STooltip, InteractionCenterBatchReportRows, SProgressLinear, SProgressStacked},

  props: {
    mobileView: Boolean,
  },
  computed: {
    authorizationDone(): number {
      return 1;
    },

    authorizationDoneColor(): string {
      let colors = ['mutedText', 'secondaryContrast', 'mainContrast'];
      let index = this.authorizationStats.findIndex(stateCount => stateCount === 1);
      return colors[index];
    },

    authorizationStats(): [number, number, number] {
      let authorized = this.stepsStats[0] + this.stepsStats[1]; // finished + in_progress
      return [authorized, this.stepsStats[2], this.stepsStats[3]]; // [authorized, authorization, error]
    }
  },
})


import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SDialog from "@/component/ui/SDialog.vue";

export default mixins(mutationMixin).extend({
  name: 'FlowDetailActionDialog',
  components: {SDialog, STextButton},
  props: {
    flow: Object,
    title: String,
    icon: String,
    text: String,
    badge: Boolean,
    badgeContent: Number,
    badgeValue: Number,
    value: Boolean,
  },
  data() {
    return {
      permanent: false,
      hover: false,
      expandedDialog: false,
    }
  }
});

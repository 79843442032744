
import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import displayMixin from "@/mixins/displayMixin";
import SSelect from "@/component/ui/form/SSelect.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import STextField from "@/component/ui/form/STextField.vue";
import STextarea from "@/component/ui/form/STextarea.vue";
import SFileSelector from "@/component/ui/form/SFileSelector.vue";
import SForm from "@/component/ui/form/SForm.vue";
import {PropType} from "vue";
import {FlowEnclosureBase} from "@/types";

type EnclosureFormInput = FlowEnclosureBase & { file?: File, externalUrl?: string }

export default mixins(mutationMixin, displayMixin).extend({
  name: 'StepEnclosuresForm',
  components: {
    SSelect,
    STextButton,
    STextField,
    STextarea,
    SFileSelector,
    SForm,
  },
  props: {
    value: {
      type: Object as PropType<EnclosureFormInput>,
      required: true,
    },
    mode: {
      type: String as PropType<'new' | 'edit'>,
      required: true
    }
  },
  data() {
    return {
      error: '' as string | null,
      enclosureTypes: [
        {value: 'url', text: this.$t('uploadWizard.steps.enclosures.new.type.url')},
        {value: 'data', text: this.$t('uploadWizard.steps.enclosures.new.type.data')}
      ]
    }
  },
  watch: {
    enclosureType(enclosureType) {
      if(enclosureType === 'url') {
        (this.$refs.fileSelector as InstanceType<typeof SFileSelector>).removeFile();
      }
    }
  },
  computed: {
    name: {
      get(): string {
        return this.value.name
      },
      set(value: string) {
        this.$emit('input', {...this.value, name: value})
      }
    },
    note: {
      get(): string | null {
        return this.value.note ?? null
      },
      set(value: string) {
        this.$emit('input', {...this.value, note: value})
      }
    },
    externalUrl: {
      get(): string | null {
        return this.value.externalUrl ?? null
      },
      set(value: string) {
        this.$emit('input', {...this.value, externalUrl: value})
      }
    },
    file: {
      get(): File | undefined {
        return this.value.file
      },
      set(value: File | undefined) {
        this.$emit('input', {...this.value, file: value})
      }
    },
    enclosureType: {
      get(): 'url' | 'data' {
        return this.value.enclosureType
      },
      set(value: 'url' | 'data') {
        this.$emit('input', {...this.value, enclosureType: value})
      }
    }
  }
})

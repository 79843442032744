import axios from "@/plugin/vueAxios";
import {AppAbout} from "@/types";

const ABOUT_PATH = '/about';
const ORGANISATION_LOGO_PATH = '/resources/logo';

export async function apiAboutGet(): Promise<AppAbout> {
  const response = await axios.get<AppAbout>(ABOUT_PATH);
  return response.data;
}

export function organisationLogoURL(): string {
  return obeliskConfig.guiUrl + ORGANISATION_LOGO_PATH;
}

import Vue, {PropType} from "vue";
import SImg from "@/component/ui/SImg.vue";
import {Bank, SignatureProfile} from "@/types";

export default Vue.extend({
  name: 'BankFeature',
  components: {SImg},
  props: {
    item: {
      type: Object as PropType<SignatureProfile>,
      required: true
    }
  },
  data() {
    return {
      validServices: ['SIGN_SINGLE_PDF', 'SIGN_MULTIPLE_PDF']
    }
  },
  computed: {
    banks(): Array<Bank> | undefined {
      return this.$store.getters["profile/banks"];
    },
    validBanks(): Array<Bank> | undefined {
      return this.banks?.filter(b => b.available_services.some(a => this.validServices.includes(a)));
    }
  },
  created() {
    if (!this.banks)
      this.$store.dispatch("profile/loadBanks");
  },
  methods: {
    clear(): void {
      // nothing here
    },
    valid(): boolean {
      if (this.item.bankId === "NONE")
        this.item.bankId = undefined;

      return true;
    }
  }
})


import Vue, {PropType} from "vue";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import UserInfo from "@/component/UserInfo.vue";
import {Flow, UserAccount} from "@/types";

export default Vue.extend({
  name: 'SignatureOnlineSuccess',

  components: {SignatureContentLayout, STextButton, UserInfo},

  props: {
    actionBack: Boolean,
    actionNext: Boolean,
    flow: {
      type: Object as PropType<Flow>,
      required: true
    }
  },

  computed: {
    autoredirect(): boolean {
      return this.$store.getters['config/autoredirect'];
    },
    autoredirectDelay(): number {
      return this.$store.getters['config/autoredirectDelay'];
    },
    subject(): string | undefined {
      return this.flow?.subject;
    },
    user(): UserAccount | undefined {
      return this.flow?._embedded?.owner;
    },
    redirectType(): string {
      return this.autoredirect ? 'automatic' : 'manual';
    },
    redirectInstructions(): string {
      return this.$t(`signature.online.success.redirect.${this.redirectType}`,
          {delay: this.autoredirectDelay}).toString();
    },
    showGratitude(): boolean {
      // nebudeme děkovat sami sobě
      return !!(this.user?.accountName) &&
          this.user.accountName.localeCompare(this.$store.getters['profile/accountName']) != 0;
    }
  },

  created() {
    if (this.autoredirect)
      this.$emit('next', false);
  },

  methods: {
    back(): void {
      this.$emit('back');
    },
    next(): void {
      this.$emit('next', true)
    }
  }

})


import mixins from "vue-typed-mixins";
import {PropType} from "vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import {NavigationMode, NotificationTreeItem, ReportRow, ReportRowFlow, ReportStateType} from "@/types";
import {redirectTo} from "@/utils/utils";

type InteractionCenterBatchReportRowType = "authorization" | "authorized";

export default mixins(authorizationMixin).extend({
  name: 'InteractionCenterBatchReBatchReportRows',

  props: {
    reportRows: {
      type: Array as PropType<Array<ReportRow>>,
      required: true
    },
    states: {
      type: Array as PropType<Array<InteractionCenterBatchReportRowType>>,
      default: () => ['authorization'] as Array<InteractionCenterBatchReportRowType>,
      validator(value: unknown) {
        return Array.isArray(value) && value.every(state => ['authorization', 'authorized'].includes(state));
      }
    },
    mobileView: Boolean,
    navigationUserMenu: Boolean
  },

  data() {
    return {
      //treeModel: [] as Array<TreeItem>,
      stateTypesMapper: {
        authorization: ['authorization'],
        authorized: ['in_progress', 'finished']
      } as Record<InteractionCenterBatchReportRowType, Array<ReportStateType>>
    };
  },

  computed: {
    filteredReportRows(): Array<ReportRow> {
      let stateTypes = this.states.flatMap(state => this.stateTypesMapper[state]);
      return this.reportRows.filter(row => stateTypes.includes(row.reportStateType));
    },
    clientSigningDialogVisible: {
      get() {
        return this.$store.getters['notification/clientSigningInProgress'];
      },
      set(value) {
        this.$store.commit('notification/setClientSigningInProgress', value);
      }
    },
    treeModelData(): Array<NotificationTreeItem> {
      let flows = [] as Array<ReportRowFlow>;
      return this.filteredReportRows.map((object, index) => {
        if(object._embedded) {
          flows = object._embedded.flows
        }

        return {
          id: index + 1,
          subject: object.sigProfileIdent,
          count: object.count,
          authorizationType: object.authorizationType,
          reportStateType: object.reportStateType,
          reportStateId: object.reportStateId,
          signatureProfile: object._embedded?.signingProfile,
          _links: object._links,
          children: [...flows]
        }
      });
    }
  },
  methods: {
    async authorize(item: NotificationTreeItem): Promise<void> {
      switch (item.authorizationType) {
        case 1:
          // Klientské zařízení
          await this.authorizeClient(item);
          break;
        case 2:
          // Přesměrování na autorizační server
          await this.authorizeExternal(item);
          break;
        default:
          console.error('Interaction center: Unknown authorization type %s', item.authorizationType);
          this.$store.commit('notification/showMessage', {
            type: 'error',
            content: this.$t('workflow.errors.authorization.unknown')
          });
      }
    },

    async authorizeClient(item: NotificationTreeItem): Promise<void> {
      let providerId = item.signatureProfile?._embedded?.signatureProvider?.providerId;
      if (!providerId) return;

      // Loads signing URI from API
      let singingUri;
      try {
        if (item._links) {
          if (!item._links['sef:get-client-signing-uri']?.href) return;
          let getSigningUriResponse = await this.axios.get(item._links['sef:get-client-signing-uri'].href);
          if (!getSigningUriResponse.data) {
            console.error('Interaction center: No data to sign loaded');
            return;
          }
          singingUri = getSigningUriResponse.data.uri;
        }
      }
      catch (e) {
        console.error('Interaction center: Error occurred while loading data to sign');
        return;
      }

      this.processClientSignature(singingUri);
      this.$store.commit('navigation/refreshCounters');
    },

    async authorizeExternal(item: NotificationTreeItem): Promise<void> {
      try {
        if (item._links) {
          let response = await this.axios.get(item._links['sef:external-authorization']?.href + '&redirectUri=' +
              encodeURIComponent(window.location.href));
          redirectTo(response.data.redirectUri, false);
        }
      }
      catch (e) {
        console.log(e);
      }
    },

    processClientSignature(signingUri: string): void {
      try {
        redirectTo(signingUri, false);
        this.clientSigningDialogVisible = true;
      }
      catch (e) {
        this.$store.commit('notification/showMessage',
            {type: 'error', content: this.$t('workflow.errors.signature.failure')});
        console.error('Interaction center: Error occurred while updating signature value');
      }
    },

    async cancelAuthorization(item: NotificationTreeItem): Promise<void> {
      this.clientSigningDialogVisible = false;
      let link;
      if (item._links) {
        link = item._links["sef:abandon-authorization"]?.href;
      }
      if (!link) return;
      try {
        await this.abandonAuthorization(link);
        //this.$store.commit('notification/showMessage', {type: 'success', content: "Autorizace dokumenty byla úspěšně zrušena"});
      }
      catch (e) {
        // ignored
      }
    },

    isAuthorization(item: NotificationTreeItem): boolean {
      return this.stateTypesMapper.authorization.includes(item.reportStateType) && !!item.authorizationType &&
          item.authorizationType !== 3;
    },

    isAuthorized(item: NotificationTreeItem): boolean {
      return this.stateTypesMapper.authorized.includes(item.reportStateType);
    },

    isPhoneAuthorization(item: NotificationTreeItem): boolean {
      return this.stateTypesMapper.authorization.includes(item.reportStateType) && item.authorizationType === 3;
    },

    async viewFlow(item: ReportRowFlow) {
      if (item) {
        const flowUrl = item.links.self.href;
        try {
          if (this.$store.getters["navigation/mode"] === NavigationMode.PROFILE) {
            this.$router.push('/notifications/error').catch(e => console.log(e));
          }
          await this.$store.dispatch('workflow/viewFlow', {flowUrl: flowUrl});
        }
        catch (e) {
          this.$store.commit('notification/showMessage', {
            content: this.$t('common.error.general.short'),
            type: 'error'
          });
        }
      }
    }
  }
})


import {VISUALISATION} from "@/utils/urls";
import mutationMixin from "@/mixins/mutationMixin";
import SForm from "@/component/ui/form/SForm.vue";
import STextField from "@/component/ui/form/STextField.vue";
import mixins from "vue-typed-mixins";
import {Visualisation} from "@/types";
import {cloneDeep} from "lodash";
import {MutationPayload} from "vuex";
import {ValidationObserver} from "vee-validate";
import SBase64ImageSelector from "@/component/ui/form/SBase64ImageSelector.vue";
import displayMixin from "@/mixins/displayMixin";
import SSignatureSelector from "@/component/ui/form/SSignatureSelector.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import ConfirmDialog from "@/component/dialog/ConfirmDialog.vue";

enum Mode {
  NONE, CREATE, UPDATE
}

export default mixins(mutationMixin, displayMixin).extend({
  name: 'VisualisationDetail',
  components: {
    ConfirmDialog,
    STextButton,
    SSignatureSelector,
    SBase64ImageSelector,
    SForm, STextField },

  data() {
    return {
      loading: false,
      mode: Mode.NONE as Mode,
      signatureDialog: false,
      visualisation: undefined as Partial<Visualisation>|undefined,
      saving: false,
      confirmDialog: false,
      confirmDialogMethod: {} as () => void,
      dialogSubtitle: undefined as string|undefined,
      dialogTitle: undefined as string|undefined,

      isShowDeleteButton: false,
      isCreateNewVisualAvailable: undefined as boolean|undefined,
    }
  },

  computed: {
    pageTitle(): string|undefined {
      if(!this.visualisation){
        return undefined;
      } else if(this.mode === Mode.UPDATE) {
        return this.visualisation?.visualName;
      } else if(this.mode === Mode.CREATE) {
        return "profile.visualisation.new";
      } else {
        return undefined;
      }
    },

    isUpdateAvailable(): boolean {
      //kontrola zda jsou dostupné potřebné linky pro povolení / zakázání editace vizualizace
      if (this.visualisation?._links != undefined && this.visualisation?._links["sef:update-visual"]?.href != undefined) {
        return true;
      }
      else {
        return false;
      }
    }
  },

  created() {
    this.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'profile/selectVisualisation') {
        this.isShowDeleteButton = true;
        if(this.$refs.signatureSelector) (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).clear();
        if(this.$refs.logoSelector) (this.$refs.logoSelector as InstanceType<typeof SBase64ImageSelector>).clear();
        let visualisation = this.$store.getters['profile/selectedVisualisation'] as Visualisation|undefined;
        if (visualisation) {
          this.visualisation = cloneDeep(visualisation);
          this.mode = Mode.UPDATE;
          this.$nextTick(() => {
            (this.$refs.visualisationDetailForm as InstanceType<typeof ValidationObserver>).reset();
          });
        }
        else {
          this.visualisation = undefined;
        }
      }

      if (mutation.type === 'profile/createNewVisualisation') {
        this.isShowDeleteButton = false;
        //isCreateNew... je tu kvůli zobrazení formuláře po kliknutí na tlačítko, když je uživatelská modifikace povolená
        this.isCreateNewVisualAvailable = true;
        if(this.$refs.signatureSelector) (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).clear();
        if(this.$refs.logoSelector) (this.$refs.logoSelector as InstanceType<typeof SBase64ImageSelector>).clear();
        if(this.$refs.visualisationDetailForm) (this.$refs.visualisationDetailForm as InstanceType<typeof ValidationObserver>).reset();
        this.visualisation = {};
        this.mode = Mode.CREATE;
      }

    });
  },

  methods: {
    removeDialog(){
      this.confirmDialog = true;
      this.confirmDialogMethod = this.remove;
      this.dialogTitle = this.$t('profile.visualisation.visualDeleteDialog').toString();
      this.dialogSubtitle = this.$t('profile.visualisation.visualDeleteDialogSub').toString();
    },
    async remove(): Promise<void> {
      this.loading = true;
      if(this.mode === Mode.CREATE) return;

      try {
        await this.axios.delete(VISUALISATION + '/' + this.visualisation?.visualId);
        this.$store.commit('notification/showMessage', { content: this.$t('profile.visualisation.visualisationDeleted'), type: "success" })
        this.$store.commit('profile/removeVisualisation', { id: this.visualisation?.visualId })
        this.visualisation = undefined;
        this.$emit('close-detail')

      }
      catch (ignore) {
        /**/
      }finally {
        this.loading = false;
        this.confirmDialog = false;
      }
    },

    cancel(): void {
      if(this.$router.currentRoute.name === 'visualisation-new') {
        this.$router.push({name:'visualisation', query: {...this.$router.currentRoute.query}});
      }
      this.visualisation = undefined;
    },

    async save(): Promise<void> {
      let visualisationFormValid = await (this.$refs.visualisationDetailForm as InstanceType<typeof ValidationObserver>).validate();
      let signatureImageValid = await (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).validate();
      let valid = signatureImageValid && visualisationFormValid;

      if(!valid) return;
      if(this.mode === Mode.CREATE) {
        await this.create();
      }
      else {
        await this.update();
      }
    },

    async create() {
      try {
        this.saving = true;
        let response = await this.axios.post(VISUALISATION, this.visualisation)
        this.mode = Mode.UPDATE;
        this.$store.commit('profile/addVisualisation', { visual: response.data })
        this.$store.commit('notification/showMessage', { content: this.$t('profile.visualisation.visualisationCreated'), type: "success" })
        this.$store.commit('profile/selectVisualisation', { visualisation: response.data })
      }
      catch (ignore) {
        /**/
      } finally {
        this.saving = false;
      }
    },
    async update() {
      try {
        this.saving = true;
        let response = await this.axios.put(VISUALISATION + '/' + this.visualisation?.visualId, this.visualisation)
        this.visualisation = cloneDeep(response.data);
        this.$store.commit('profile/editVisualisation', { visual: response.data })
        this.$store.commit('notification/showMessage', { content: this.$t('profile.visualisation.visualisationUpdated'), type: "success" })
      }
      catch (ignore) {
        /**/
      } finally {
        this.saving = false;
      }
    },
  },
  watch: {
    pageTitle: {
      immediate: true,
      handler(value){
        this.$emit('set-title', value);
      }
    },
  }
})


import Vue, {PropType} from "vue";
import {SignatureMethod} from "@/types";

export default Vue.extend({
    name: 'SignatureMethodListItem',
    props: {
      method: {
        type: Object as PropType<SignatureMethod>,
        required: true
      }
    }

  })


import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import SignatureExternalView from "@/view/SignatureExternalView.vue";
import SignatureOnlineView from "@/view/SignatureOnlineView.vue";
import TwoColumnLayout from "@/component/layouts/TwoColumnLayout.vue";
import {
  SignatureOnlinePhase,
  UserAccount,
  SignatureComponentName,
  SignatureComponentProps
} from "@/types";
import {ACCOUNT_TYPE} from "@/utils/constants";
import displayMixin from "@/mixins/displayMixin";

export default mixins(mutationMixin, displayMixin).extend({
  name: 'SignatureViewResolver',

  components: {TwoColumnLayout, SignatureExternalView, SignatureOnlineView},
  /*
   * Hodnoty všech atributů přicházejí z routeru jako text. Konverze hodnot do příslušných datových typů probíhá až v
   * této komponentě.
   */
  props: {
    flowId: {
      type: String,
      required: true
    },
    flowStepId: {
      type: String,
      required: true
    },
    redirectUri: {
      type: String,
      required: false
    },
    skipIntro: {
      type: String,
      default: false
    },
    skipConfirmation: {
      type: String,
      default: false
    }
  },

  data() {
    return {
      personalInfo: undefined as UserAccount | undefined,
      token: undefined as string | undefined,
      navVisible: false
    }
  },

  computed: {
    convertedFlowId(): number | null {
      if (Number.isNaN(Number(this.flowId))) {
        console.error(`FlowId is not a number: ${this.flowId}`);
        return null;
      }
      else
        return Number(this.flowId);
    },
    convertedFlowStepId(): number | null {
      if (Number.isNaN(Number(this.flowStepId))) {
        console.error(`FlowStepId is not a number: ${this.flowStepId}`);
        return null;
      }
      else
        return Number(this.flowStepId);
    },
    convertedSkipIntro(): boolean {
      return this.skipIntro === 'true';
    },
    convertedSkipConfirmation(): boolean {
      return this.skipConfirmation === 'true';
    },
    currentComponent(): SignatureComponentName {
      if (this.externalAccount || this.temporaryAccount)
        return 'SignatureExternalView';
      else
        return 'SignatureOnlineView';
    },
    currentProperties(): SignatureComponentProps {
      let propsExternal
      if (this.externalAccount) {
        // Extra atributy pro externí podpis
        propsExternal = {
          personalInfo: this.personalInfo,
          token: this.token
        };
      }
      else if (this.temporaryAccount) {
        // Extra atributy pro anonymní podpis
        propsExternal = {
          token: this.token
        };
      }
      return {
        ...propsExternal,
        flowId: this.convertedFlowId,
        flowStepId: this.convertedFlowStepId,
        redirectUri: this.redirectUri,
        skipIntro: this.convertedSkipIntro,
        skipConfirmation: this.convertedSkipConfirmation
      };
    },
    externalAccount(): boolean {
      return this.$store.getters['profile/accountType'] === ACCOUNT_TYPE.EXTERNAL;
    },
    temporaryAccount(): boolean {
      return this.$store.getters['profile/accountType'] === ACCOUNT_TYPE.TEMPORARY;
    },
    showComponent(): boolean {
      return !!this.convertedFlowId && !!this.convertedFlowStepId && !!this.personalInfo && !!this.token;
    },
    currentPhase(): SignatureOnlinePhase {
      return this.$store.getters["onlineSignature/onlineSignaturePhase"];
    }
  },

  created() {
    const token = this.$store.getters['auth/token'];
    const personalInfo = this.$store.getters['profile/info'];
    this.$store.commit('onlineSignature/setOnlineRedirectUri', this.redirectUri);
    this.$store.commit('onlineSignature/setFlowId', this.convertedFlowId);
    this.$store.commit('onlineSignature/setFlowStepId', this.convertedFlowStepId);
    if (!token || !personalInfo) {
      this.subscribe((mutation) => {
        const token = this.$store.getters['auth/token'];
        const personalInfo = this.$store.getters['profile/info'];

        if (mutation.type === 'auth/login' && personalInfo) {
          this.setViewProps(personalInfo, mutation.payload.accessToken);
          if (this.unsubscribe)
            this.unsubscribe();
        }

        if (mutation.type === 'profile/setPersonalInfo' && token) {
          this.setViewProps(mutation.payload.info, token);
          if (this.unsubscribe)
            this.unsubscribe();
        }
      });
    }
    else {
      this.setViewProps(personalInfo, token);
    }
  },

  methods: {
    setViewProps(personalInfo: UserAccount, token: string): void {
      this.personalInfo = personalInfo;
      this.token = token;
    },
    navOpen() {
      this.$emit('nav-open');
    }
  }
})

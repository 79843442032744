
import {MutationPayload} from "vuex";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import mutationMixin from "@/mixins/mutationMixin";
import FlowDetailDesktop from "@/component/workflow/detail/FlowDetailDesktop.vue";
import FlowDetailMobile from "@/component/workflow/detail/FlowDetailMobile.vue";
import OverviewBottomSheet from "@/component/workflow/overview/OverviewBottomSheet.vue";
import OverviewContent from "@/component/workflow/overview/OverviewContent.vue";
import OverviewHeader from "@/component/workflow/overview/OverviewHeader.vue";
import OverviewLayout from "@/component/workflow/overview/OverviewLayout.vue";
import {
  BatchProcessed,
  BatchProcessedAction,
  BatchState,
  DisplayVariant,
  Flow, Folder,
  FolderMode,
  ThemeFullGuiCustomization
} from "@/types";
import UploadWizard from "@/component/workflow/uploadWizard/UploadWizard.vue";
import OverviewModeSwitch from "@/component/workflow/overview/OverviewModeSwitch.vue";

export default mixins(displayMixin, mutationMixin).extend({
  name: 'ContentView',

  components: {
    OverviewModeSwitch,
    UploadWizard,
    FlowDetailDesktop,
    FlowDetailMobile,
    OverviewBottomSheet,
    OverviewContent,
    OverviewHeader,
    OverviewLayout,
  },

  props: {
    folderId: String
  },

  data() {
    return {
      detail: false,
      notifications: {
        batchSignProcessed: {
          [BatchState.ACTION_REQUIRED]: {
            message: 'workflow.batch.sign.messages.processed.actionRequired',
            type: 'info'
          },
          [BatchState.COMPLETED]: {
            message: 'workflow.batch.sign.messages.processed.completed',
            type: 'success'
          },
          [BatchState.WITH_ERRORS]: {
            message: 'workflow.batch.sign.messages.processed.withErrors',
            type: 'error'
          }
        } as Record<BatchState, { message: string, type: string }>
      }
    }
  },

  computed: {
    batchMode(): boolean {
      return this.$store.getters['workflow/batchMode'];
    },
    flowSelected(): Flow | undefined {
      return this.$store.getters['workflow/flowActive'];
    },
    detailVariant() {
      switch (this.displayActive) {
        case DisplayVariant.MOBILE:
        case DisplayVariant.TABLET:
          return FlowDetailMobile;
        case DisplayVariant.DESKTOP:
        default:
          return FlowDetailDesktop;
      }
    },
    search(): boolean {
      return this.$route.query.search === "true";
    },
    fullGuiCustomization(): ThemeFullGuiCustomization {
      return this.$store.getters['theme/fullGuiCustomization'];
    },
    foldersFlat(): Array<Folder> {
      return this.$store.getters["navigation/foldersFlat"];
    }
  },

  watch: {
    flowSelected(value): void {
      if(value) {
        this.detail = true;
      }
    },
    folderId() {
      this.detail = false;
    }
  },

  async created() {
    await this.loadFolders();
    this.subscribe(async (mutation: MutationPayload) => {
      if (mutation.type === 'batches/batchProcessed') {
        await this.handleBatchProcessedEvent(mutation.payload);
      }
    });
  },

  methods: {
    closeDetail(): void {
      if(this.displayMobile){
        this.$store.commit("workflow/setFlowActive", {flowActive: undefined});
      }
      this.detail = false;
    },
    async handleBatchProcessedEvent(payload: BatchProcessed): Promise<void> {
      if (payload.action === BatchProcessedAction.SIGN && payload.batch) {
        const notification = this.notifications.batchSignProcessed[payload.batch.batchState];
        if (notification) {
          this.$store.commit('notification/showMessage', {
            content: this.$t(notification.message, { batchName: payload.batch.batchName || '' }),
            type: notification.type
          });
        }
      }

      if (this.batchMode) {
        const folderMode = this.batchMode ? FolderMode.BATCH : FolderMode.STANDARD;
        await this.$store.dispatch('navigation/loadFolders', { folderMode });

        this.$store.commit('workflow/flowsUpdate', { flowIds: payload.flowIds, flowsPath: payload.flowsPath });
      }
    },
    navOpen(): void {
      this.$emit('nav-open');
    },
    async loadFolders(): Promise<void> {
      if(this.batchMode) {
        const folderMode = this.batchMode ? FolderMode.BATCH : FolderMode.STANDARD;
        await this.$store.dispatch('navigation/loadFolders', { folderMode });
      }
    },
    selectedFolder(id: string): Folder | undefined {
      return this.foldersFlat.find(folder => folder.folderId == parseInt(id));
    }
  }
})


import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import signatureOnlineMixin from "@/mixins/signatureOnlineMixin";
import AccountInfo from "@/component/AccountInfo.vue";
import CenteredPanelLayout from "@/component/layouts/CenteredPanelLayout.vue";
import SignatureContentAlert from "@/component/signature/SignatureContentAlert.vue";
import SignatureContentLogo from "@/component/signature/SignatureContentLogo.vue";
import SignatureOnlineContent from "@/component/signature/online/SignatureOnlineContent.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import {apiFlowStepsReject} from "@/services/api/flowStepsApi";
import {apiWorkflowGet, apiWorkflowsRecall} from "@/services/api/workflowsApi";
import {FlowStepState, SignatureOnlinePhase, SignatureOnlineRejectionPhase,} from "@/types";
import {getStepActive, isStepActive} from "@/utils/flowUtils";

export default mixins(displayMixin, signatureOnlineMixin).extend({
  name: 'SignatureOnlineView',

  components: {
    AccountInfo,
    CenteredPanelLayout,
    SIconButton,
    SignatureContentAlert,
    SignatureContentLogo,
    SignatureOnlineContent
  },

  data() {
    return {
      phaseOrig: undefined as SignatureOnlinePhase | undefined
    }
  },

  computed: {
    currentPhase(): SignatureOnlinePhase {
      return this.$store.getters["onlineSignature/onlineSignaturePhase"];
    },
    flowRecallAvailable(): boolean {
      return this.flowActive && this.flowOwner;
    },
    phaseDocumentDetail(): boolean {
      return this.currentPhase === SignatureOnlinePhase.DOCUMENT_DETAIL;
    }
  },

  created() {
    this.loadFlow();
  },

  methods: {
    changePhase(phase: SignatureOnlinePhase | undefined): void {
      this.$store.commit('onlineSignature/setOnlineSignaturePhase', phase);
    },
    cancel(): void {
      this.rejection(SignatureOnlineRejectionPhase.CONFIRMATION);
    },
    async loadFlow(setPhase = true): Promise<void> {
      if (!this.flowId || !this.flowStepId)
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID)
      try {
        this.flow = await apiWorkflowGet(this.flowId);
        if (setPhase)
          this.onFlowLoaded();
      }
      catch (e) {
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      }
    },
    onFlowLoaded(): void {
      if (!this.step || !this.flow)
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      else {
        if (getStepActive(this.flow) && this.stepsAvailable)
          this.changePhase(this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO);
        // 'finished' je jediná situace, kdy chceme říkat "úspěšně jste podepsali"
        else if ((getStepActive(this.flow)?.state === FlowStepState.FINISHED || this.flow.state == "finished") &&
            !this.stepsAvailable)
          this.changePhase(SignatureOnlinePhase.SUCCESS);
        // // kromě 'finished' vyřazeného výše zahrnují neaktivní kroky i 'rejected' a 'inactive' (recalled flow nebo rejectnutý jiný krok)
        else if (!isStepActive(this.step, this.flow))
          this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
        else
          this.changePhase(this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO);
      }
    },
    async onFlowRejected(): Promise<void> {
      if (this.redirectUri)
        this.redirectToSystem(true);
      else {
        this.changePhase(SignatureOnlinePhase.REJECTION_CONFIRMATION)
        await this.loadFlow(false);
      }
    },
    async rejection(rejectionPhase: SignatureOnlineRejectionPhase, rejectionReason?: string): Promise<void> {
      switch (rejectionPhase) {
        case SignatureOnlineRejectionPhase.CANCEL:
          this.changePhase(this.phaseOrig)
          break;
        case SignatureOnlineRejectionPhase.CONFIRMATION:
          if (this.currentPhase !== SignatureOnlinePhase.REJECTION)
            this.phaseOrig = this.currentPhase;
          this.changePhase(SignatureOnlinePhase.REJECTION);
          break;
        case SignatureOnlineRejectionPhase.CONFIRMED:
          if (this.flowRecallAvailable) {
            // Odvolání
            if (this.flowId) {
              try {
                const data = await apiWorkflowsRecall([this.flowId], rejectionReason);
                if (data.succeeded[0] === this.flowId)
                  await this.onFlowRejected();
                else
                  console.error(`An incorrect flow ${data.succeeded[0]} has been recalled`);
              }
              catch (e) {
                console.error(`An error occurred while recalling the flow ${this.flowId}`, e);
              }
            }
            else
              console.warn('The flow cannot be recalled due to a missing identifier');
          }
          else {
            // Odmítnutí
            if (this.flow?.myActiveStepId) {
              try {
                const data = await apiFlowStepsReject([this.flow.myActiveStepId], rejectionReason);
                if (data.succeeded[0] === this.flow.myActiveStepId)
                  await this.onFlowRejected();
                else
                  console.error(`An incorrect step ${data.succeeded[0]} has been rejected`);
              }
              catch (e) {
                console.error(`An error occurred while rejecting the step ${this.flow.myActiveStepId}`, e);
              }
            }
            else
              console.warn('The step cannot be rejected due to a missing identifier');
          }
          break;
      }
    },
    async success(): Promise<void> {
      if (!this.isNextStepMine) {
        if (this.skipConfirmation && this.redirectUri) this.redirectToSystem(true);
        else this.changePhase(SignatureOnlinePhase.SUCCESS);
      }
      else await this.loadFlow(false);
    },
    navOpen(): void {
      this.$emit('nav-open');
    }
  }
})

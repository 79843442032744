
import {ValidationProvider} from "vee-validate";
import mixins from "vue-typed-mixins";
import formMixin from "@/mixins/formMixin";

export default mixins(formMixin).extend({
  name: 'SSelect',
  components: {
    ValidationProvider
  },
  props: {
    clearable: Boolean,
    color: String,
    dataTestid: {
      type: String,
      default: 'SSelect'
    },
    dense: Boolean,
    disabled: Boolean,
    hint: String,
    hideDetails: Boolean,
    hideSelected:{
      type: Boolean,
      default: false
    },
    id: String,
    itemColor: {
      type: String,
      default: 'mainText'
    },
    itemText: {
      type: [Array, Function, String],
      default: 'text'
    },
    itemValue: {
      type: [Array, Function, String],
      default: 'value'
    },
    items: Array,
    label: String,
    placeholder: String,
    prefix: String,
    prependIcon: String,
    readonly: Boolean,
    returnObject: {
      type: Boolean,
      default: false
    },
    suffix: String,
    solo: {
      type: Boolean,
      default: false
    },
    value: [Number, String],
    appendIcon: String,
  },
  computed: {
    localValue: {
      get(): number | string {
        return this.value;
      },
      set(value: number | string): void {
        this.$emit('input', value);
      }
    }
  }
})


import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import {DisplayVariant} from "@/types";

interface OverviewLayoutConfig {
  colsList: number;
  colsDetail: number;
}

export default mixins(displayMixin).extend({
  name: 'OverviewLayout',
  props: {
    detail: Boolean
  },
  data() {
    return {
      layoutConfig: {
        mobile: {
          colsList: 12,
          colsDetail: 12
        },
        tablet: {
          colsList: 5,
          colsDetail: 7
        },
        desktop: {
          colsList: 4,
          colsDetail: 8
        }
      } as Record<DisplayVariant, OverviewLayoutConfig>
    }
  },
  computed: {
    colsList(): number {
      return this.layoutConfig[this.displayActive].colsList;
    },
    colsDetail(): number {
      return this.layoutConfig[this.displayActive].colsDetail;
    },
    showList(): boolean {
      return !this.displayMobile || !this.detail;
    },
    showDetail(): boolean {
      return !this.displayMobile || this.detail;
    }
  }
})


import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";

export default mixins(displayMixin).extend({
  name: 'PDFWidgetToolbarBtn',

  props: {
    disabled: Boolean,
    icon: {
      type: String,
      required: true
    },
    text: String,
    dataTestId: {
      type: String,
      default: "delete"
    }
  }
})


import mixins from "vue-typed-mixins";
import {BusinessLevel, Dictionary, InputFlowStep, UserAccount} from "@/types";
import SForm from "@/component/ui/form/SForm.vue";
import SignerListItem from "@/component/workflow/uploadWizard/SignerListItem.vue";
import {PropType} from "vue";
import SignerSearch from "@/component/workflow/uploadWizard/SignerSearch.vue";
import displayMixin from "@/mixins/displayMixin";
import {ValidationObserver} from "vee-validate";
import Draggable from "vuedraggable";

export default mixins(displayMixin).extend({
  name: "StepSigners",
  components: {SignerSearch, SignerListItem, SForm, Draggable},
  data(){
    return {
      signatureLevels: [] as Array<BusinessLevel>,
      error: undefined as string | undefined,
      searchActive: false,
      signatureCategory: {},
    }
  },
  props: {
    value: {
      type: Array as PropType<InputFlowStep[]>,
      required: true
    },
    businessLevelsMap: {
      type: Object as PropType<Dictionary<{ [key: number]: boolean }>>,
      required: true
    },
    categoryCode: {
      type: String,
      required: true,
    }
  },

  computed: {
    localValue: {
      get(): Array<InputFlowStep> {
        return this.value;
      },
      set(value: Array<InputFlowStep>) {
        this.$emit('input', value);
      }
    },
    account(): UserAccount {
      return this.$store.getters["profile/info"];
    },
    externalUsersEnabled(): boolean {
      return this.$store.getters["config/externalUsersEnabled"];
    },
    valid(): boolean {
      if (this.localValue?.length > 0) {
        for (const step of this.localValue) {
          if (!step.requestedSignatureLevel) return false;

          //Kontrola zda je vyplněno jméno pro "Ruční podpis třetí osobou"
          if (step.requestedSignatureLevel.thirdPerson) {
            if (step.thirdPersonName === undefined || step.thirdPersonName === null || step.thirdPersonName === "") return false;
          }
        }
        return true;
      }
      else {
        return false;
      }
    }
  },

  methods: {
    async validate(): Promise<boolean> {
      if (this.localValue.length === 0) {
        this.error = this.$t('uploadWizard.steps.people.noSigners').toString();
        return false;
      }
      return (this.$refs.signersStep as InstanceType<typeof ValidationObserver>).validate();
    },
    isSignedInUser(signer: InputFlowStep): boolean {
      return this.account.accountName === signer._embedded.account?.accountName;
    },
    getBusinessLevelMapping(signer: InputFlowStep): { [key: number]: boolean } {
      if (!signer._embedded.account) return {};
      return this.businessLevelsMap[signer._embedded.account.accountName];
    },
    async addSigner(account: UserAccount): Promise<void> {
      const signer = {
        notifyWhenFinished: true,
        stepType: 'signature',
        _embedded: {
          account: account
        }
      } as InputFlowStep
      const newValue = this.localValue ? [...this.localValue] : [];
      newValue.push(signer);
      this.localValue = newValue;
      this.error = undefined;
      this.searchActive = false;

      if (!this.businessLevelsMap[signer._embedded.account.accountName]) {
        let response = await this.axios.get(signer._embedded.account._links.businessLevels.href);
        this.$set(this.businessLevelsMap, signer._embedded.account.accountName, response.data)
      }

    },
    onSearchFocus() {
      if(this.displayMobile) {
        // Timeout is necessary, otherwise the component will be blurred immediately
        setTimeout(() => {
          this.searchActive = true;
          if (!this.displayDesktop) window.scrollTo(0, 0);
        }, 200)
      }
    },
    removeSigner(index: number) {
      const newValue = [...this.localValue];
      newValue.splice(index, 1);
      this.localValue = newValue;
    },
    personUpdate(index: number, value: InputFlowStep) {
      const newValue = [...this.localValue];
      newValue[index] = value;
      this.localValue = newValue;
    },
  },

  watch: {
    valid: {
      handler(value) {
        this.$emit('valid', value);
      },
      immediate: true
    }
  },

  async created() {
    let signatureLevels: Array<BusinessLevel> | undefined = this.$store.getters['signature/signatureCategories'][this.categoryCode];
    if (!signatureLevels) {
      await this.$store.dispatch('signature/loadSignatureLevels', this.categoryCode);
      this.signatureLevels = this.$store.getters['signature/signatureCategories'][this.categoryCode] ?? [];
    }
    else {
      this.signatureLevels = signatureLevels;
    }
  }
})

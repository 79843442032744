
import {SEARCH} from "@/utils/urls";
import {toArray} from "@/utils/utils";
import {Folder, SearchKeywordsDTO, SortingItem} from "@/types/navigation";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import FlowList from "@/component/workflow/list/FlowList.vue";
import SList from "@/component/ui/SList.vue";
import SSelect from "@/component/ui/form/SSelect.vue";
import {PropType} from "vue";
import {FOLDERS_LIMITATIONS} from "@/utils/constants";

export default mixins(displayMixin).extend({
  name: 'OverviewContent',
  components: {SSelect, FlowList, SList},
  props: {
    folderId: String,
    search: Boolean,
    folder: Object as PropType<Folder>
  },
  data() {
    return {
      sortingItems: [] as Array<SortingItem>,
      selectedItem: undefined as SortingItem | undefined
    }
  },
  computed: {
    folderUrl(): string|undefined {
      if(!this.folderId) return undefined;
      return this.$store.getters['navigation/url'](this.folderId);
    },
    searchQueryParams(): string|undefined {
      if(!this.search) return undefined;
      const query = this.$route.query as SearchKeywordsDTO;
      let accounts = toArray(query.account).map(a => 'account=' + encodeURIComponent(a));
      let subjects = toArray(query.subject).map(s => 'subject=' + encodeURIComponent(s));
      let tags = toArray(query.tag).map(t => 'tag=' + encodeURIComponent(t));
      let category = query.category ? "categoryCode=" + encodeURIComponent(query.category) : "";
      return accounts.concat(subjects).concat(tags).concat(category).join('&');
    },
    state(): string|undefined {
      if(this.search) return 'search';
      return this.$store.getters['navigation/state'](this.folderId);
    },
    url(): string | undefined {
      if (this.search) {
        let baseUrl =  SEARCH + "?" + this.searchQueryParams;
        if(this.selectedItem && this.selectedItem.value) {
          return this.selectedItem.value;
        }
        return baseUrl;
      }
      else if (this.selectedItem) {
        return this.selectedItem?.value;
      }
      else {
        return this.folderUrl;
      }
    },
    manualFlowCreationEnabled(): boolean {
      return this.$store.getters['config/manualFlowCreationEnabled'];
    },
    hasCategory(): boolean {
      return this.$store.getters['config/categories'].length >= 1;
    },
    folderLimitation(): Array<string> {
      return FOLDERS_LIMITATIONS[this.state ? this.state : this.folder?.state];
    },
    lastSelectedSorting(): SortingItem | undefined {
      return this.$store.getters['navigation/lastSelectedSorting'](this.folderId);
    }
  },
  watch: {
    folder() {
      let folderLink = this.folder ? this.folder?._links?.['sef:folder-content']?.href : this.folderUrl
      if (folderLink) {
        this.formatSortingOptions(folderLink);
      }
    },
    search(value: boolean) {
      if (!value) {
        this.sortingItems = [];
        this.selectedItem = undefined;
      }
    },
    selectedItem() {
      if (!this.search) {
        this.$store.commit('navigation/setLastSelectedSorting', {folderId: this.folderId, selectedSort: this.selectedItem});
      }
    },
    '$route.query': {
      handler() {
        if(this.search) {
          this.$nextTick(() => {
            this.formatSortingOptions(SEARCH + "?" + this.searchQueryParams);
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    createWorkflow(){
      this.$store.commit('workflow/openWorkflowWizard');
    },
    async formatSortingOptions(link: string): Promise<void> {
      this.sortingItems = [];
      try {
        if (link) {
          let response = await this.axios.get(link);
          Object.keys(response.data._links).forEach(item => {
            if(item !== "sef:cursor-next" && item !== "sef:cursor-prev") {
              let key = this.reformatUrlToKey(response.data._links[item].href);
              let orderKey = this.determineOrder(response.data._links[item].href);
              this.folderLimitation.forEach(limit => {
                if (key && limit == key) {
                  let text = this.$t('workflow.sortFields.' + key);
                  let sortText = orderKey ? this.$t('workflow.sortFields.determineOrder.' + orderKey) : "";
                  let sortingKey = this.getSortingKey(response.data._links[item].href);

                  this.sortingItems.push({text: text.toString(), sortText: sortText.toString(), value: response.data._links[item].href, keyOfSorting: sortingKey.toString()})
                }
              })
            }
          })
          this.selectedItem = this.lastSelectedSorting != undefined ? this.lastSelectedSorting : this.sortingItems.find(item => item.keyOfSorting === response.data.orderBy);
        }
      }
      catch (e) {
        console.warn(e);
      }
    },
    reformatUrlToKey(text: string): string {
      const regex = /order-by=(.*?)(?:-(desc|asc))?(&|$)/
      const match = text.match(regex);
      if (match) {
        const key = match[1].replace(/-(.)/g, (_, letter) => letter.toUpperCase());
        return key;
      }
      return "";
    },
    determineOrder(text: string): string {
      if (text.includes("desc")) {
        return "desc";
      }
      else if (text.includes("asc")) {
        return "asc";
      }
      return "";
    },
    getSortingKey(text: string): string {
      const lastIndex = text.lastIndexOf("=");
      return text.substring(lastIndex + 1);
    }
  }
})


import Vue from "vue";
import {AxiosError} from "axios";
import {DateTime} from "luxon";
import {SUPPORT_TICKETS} from "@/utils/urls";
import {ValidationObserver} from "vee-validate";
import SForm from "@/component/ui/form/SForm.vue";
import STextarea from "@/component/ui/form/STextarea.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import STextField from "@/component/ui/form/STextField.vue";

enum TicketType {
  SUPPORT = 'SUPPORT',
  ERROR = 'ERROR',
}

enum Priority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export default Vue.extend({
  name: 'SupportView',
  components: {STextButton, SForm, STextarea, STextField},
  data() {
    return {
      type: TicketType.SUPPORT as TicketType,
      priority: Priority.MEDIUM as Priority,
      date: undefined as string|undefined,
      time: undefined as string|undefined,
      ticketName: undefined as string|undefined,
      environment: undefined as string|undefined,
      description: undefined as string|undefined,
      request: undefined as string|undefined,
      response: undefined as unknown,
      submitting: false
    }
  },
  computed: {
    supportEnabled(): boolean {
      return this.$store.getters["config/supportEnabled"];
    },
    supportEmail(): string {
      return this.$store.getters["config/supportEmail"] ?? '';
    },
    error(): AxiosError|undefined {
      return this.$store.getters["notification/notificationError"];
    },
    errorType(): boolean{
      return this.type === TicketType.ERROR;
    },
    supportType(): boolean{
      return this.type === TicketType.SUPPORT;
    },
  },
  methods: {
    async submit(): Promise<void> {
      let valid = await (this.$refs.ticketForm as InstanceType<typeof ValidationObserver>).validate();
      if(!valid) return;

      this.submitting = true;
      try {
        await this.axios.post(SUPPORT_TICKETS, {
          type: this.type,
          title: this.ticketName,
          date: this.errorType ? this.date : undefined,
          time: this.errorType ? this.time : undefined,
          description: this.description,
          environment: this.environment,
          request: this.errorType ? this.request : undefined,
          response: this.errorType ? this.response : undefined
        })
        this.clear();
        this.$store.commit('notification/showMessage', {content: this.$t('profile.support.successMessage'), type: 'success'});
      } catch (e) {
        console.log(e);
      } finally {
        this.submitting = false;
      }
    },
    parseError(error: AxiosError): void {
      this.type = TicketType.ERROR;
      this.date = DateTime.now().toISODate();
      this.time = DateTime.now().toFormat('HH:mm');
      this.request = error.request.responseURL;
      if (typeof error.response?.data === 'object')
        this.response = JSON.stringify(error.response.data);
      else
        this.response = error.response?.data;
    },
    clear(){
      this.type = TicketType.SUPPORT;
      this.date = undefined;
      this.time = undefined;
      this.ticketName = undefined;
      this.environment = undefined;
      this.description = undefined;
      this.request = undefined;
      this.response = undefined;
      (this.$refs.ticketForm as InstanceType<typeof ValidationObserver>).reset();
    }
  },
  watch: {
    error(): void{
      if(this.error) this.parseError(this.error);
    }
  },
  mounted(): void {
    this.environment = navigator.userAgent;
    if(this.error) this.parseError(this.error);
  },
  destroyed() {
    this.$store.commit("notification/setNotificationError", {error: undefined});
  }
})


import Vue, {PropType} from 'vue'
import {ButtonVariant, isCancelled, isFailed, isTimeout, SignatureMethod, SignatureProfile} from "@/types";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {redirectTo} from "@/utils/utils";
import axios from "axios";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";

export default Vue.extend({
  name: 'SignatureProfileCardActions',
  components: {SIconButton, STextButton},
  props: {
    hasCaActionComponent:{
      type:Boolean,
      required:true
    },
    profile: {
      type: Object as PropType<SignatureProfile>,
      required: true
    },
    narrow: {
      type: Boolean,
      required: true
    },
    busy: {
      type: Boolean,
      required: true
    },
    method: {
      type: Object as PropType<SignatureMethod>,
      required: true
    }
  },
  computed: {
    showLastStateInfo(): boolean {
      if (this.hasCaActionComponent) {
        return !!this.profile.certificateState && (isTimeout(this.profile.certificateState) ||
            isFailed(this.profile.certificateState) || isCancelled(this.profile.certificateState))
      }
      return false;
    },
    selectButtonColor(): ButtonVariant {
      // if this is not a CA profile and there is no certificate, then we want to highlight the button
      return this.profile.isCaProfile || !!this.profile.signingCertificate ? 'secondary-muted' : 'primary-success'
    }
  },
  methods: {
    async selectCert() {
      if (!this.profile._links["sef:get-certificate-select-uri"]) {
        throw "Invalid profile type"
      }
      try {
        const profileId = this.profile.profileId;
        const response = await axios.get(this.profile._links["sef:get-certificate-select-uri"]?.href, {
          params: {
            profileId: profileId
          }
        })
        const obspUri = response.data.uri as string
        redirectTo(obspUri)
        await this.$store.dispatch('profile/loadSignatureProfile',
            {id: this.profile.profileId, method: this.method.providerTypeCode})
      }
      finally {
        //
      }
    },
    async cancel() {
      // I have left the original code here in case we would want to revert to the "old" behaviour
      // if (!this.profile._links["sef:cancel-certificate-select"]) {
      //   throw "Invalid profile type"
      // }
      //
      // try {
      //   await axios.put(this.profile._links["sef:cancel-certificate-select"]?.href)
      // }
      // finally {
      //   //
      // }

      // when we cancel the operation from GUI, the information that it was cancelled is superfluous, because user will most likely use reset immediately anyway
      await this.reset()
    },
    async reset() {
      if (!this.profile._links["sef:delete-certificate-select-state"]) {
        throw "Invalid profile type"
      }

      try {
        await axios.delete(this.profile._links["sef:delete-certificate-select-state"]?.href)
        await this.$store.dispatch('profile/loadSignatureProfile',
            {id: this.profile.profileId, method: this.method.providerTypeCode})
      }
      finally {
        //
      }

    }
  }
})


import SSelect from "@/component/ui/form/SSelect.vue";
import {PropType} from "vue";
import {BusinessLevel, Dictionary, InputFlowStep, UserAccount} from "@/types";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import SignerName from "@/component/workflow/uploadWizard/SignerName.vue";
import SignerEmail from "@/component/workflow/uploadWizard/SignerEmail.vue";
import STextField from "@/component/ui/form/STextField.vue";
import STooltip from "@/component/ui/STooltip.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";

export default mixins(displayMixin).extend({
  name: 'SignerListItem',

  components: {SIconButton, STooltip, STextField, SignerEmail, SignerName, SSelect},

  props: {
    person: {
      type: Object as PropType<InputFlowStep>,
      required: true
    },
    myself: {
      type: Boolean,
      default: false
    },
    signatureLevels: {
      type: Array as PropType<BusinessLevel[]>,
      required: true
    },
    businessLevelsMapping: Object as PropType<Dictionary<{ [key: number]: boolean }>>
  },

  computed: {
    requestedSignatureLevel: {
      get(): BusinessLevel | null {
        return this.person.requestedSignatureLevel;
      },
      set(value: BusinessLevel): void {
        if (!value.thirdPerson)
          this.person.thirdPersonName = undefined;

        this.$emit('update:person', {
          ...this.person,
          requestedSignatureLevel: value
        });
      }
    },
    account(): UserAccount{
      return this.person._embedded.account;
    },
    thirdPersonBusinessLevel(): boolean {
      return !!this.requestedSignatureLevel && this.requestedSignatureLevel.thirdPerson;
    },
    thirdPersonName: {
      get(): string | undefined {
        return this.person.thirdPersonName;
      },
      set(value: string | undefined) {
        this.$emit('update:person', {...this.person, thirdPersonName: value});
      }
    }
  },

  watch: {
    signatureLevels(value: Array<BusinessLevel>): void {
      if (value.length === 1)
        this.requestedSignatureLevel = value[0];
    }
  },

  created() {
    if (this.signatureLevels.length === 1)
      this.requestedSignatureLevel = this.signatureLevels[0];
  },

  methods: {
    remove(): void {
      this.$emit('remove');
    },
    onBellClick(): void {
      if (this.person.notifyWhenFinished) {
        this.$set(this.person, 'notifyWhenFinished', false);
      }
      else {
        this.$set(this.person, 'notifyWhenFinished', true);
      }
    }
  }
})

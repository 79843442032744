
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import AccountInfo from "@/component/AccountInfo.vue";
import CenteredPanelLayout from "@/component/layouts/CenteredPanelLayout.vue";
import SignatureContentAlert from "@/component/signature/SignatureContentAlert.vue";
import SignatureContentLogo from "@/component/signature/SignatureContentLogo.vue";
import SignatureOnlineContent from "@/component/signature/online/SignatureOnlineContent.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {
  Flow, FlowStep, SignatureOnlinePhase, ThemeOnlineSignatureCustomization
} from "@/types";
import {apiWorkflowGet} from "@/services/api/workflowsApi";

export default mixins(displayMixin).extend({
  name: 'NavigationSidebarOnline',

  components: {
    AccountInfo,
    CenteredPanelLayout,
    SIconButton,
    SignatureContentAlert,
    SignatureContentLogo,
    SignatureOnlineContent,
    STextButton
  },

  props: {
    value: Boolean
  },

  data() {
    return {
      flow: undefined as Flow | undefined
    }
  },

  created() {
    this.loadFlow();
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    flowId(): number | undefined {
      return this.$store.getters["onlineSignature/flowId"];
    },
    flowStepId(): number | undefined {
      return this.$store.getters["onlineSignature/flowStepId"];
    },
    currentPhase(): SignatureOnlinePhase {
      return this.$store.getters["onlineSignature/onlineSignaturePhase"];
    },
    phaseDocumentDetail(): boolean {
      return this.currentPhase === SignatureOnlinePhase.DOCUMENT_DETAIL;
    },
    accountName(): string | undefined {
      return this.$store.getters['profile/accountName'];
    },
    applicableSigningProfiles(): boolean {
      return !!this.flow && !!this.step && !!this.step?._embedded?.applicableSigningProfiles?.length;
    },
    flowActive(): boolean {
      return !!this.flow && this.flow.state === 'active';
    },
    flowOwner(): boolean {
      return !!this.flow && this.flow._embedded?.owner?.accountName === this.accountName;
    },
    redirectUri(): string {
      return this.$store.getters['onlineSignature/onlineRedirectUri']
    },
    step(): FlowStep | undefined {
      return !this.flow ? undefined : this.flow._embedded.steps.find(s => s.stepId == this.flow?.myActiveStepId);
    },
    stepActive(): boolean {
      return !!this.step && this.step._embedded?.account?.accountName === this.accountName && (this.step.state ===
          'active' || this.step.state === 'error');
    },
    stepProcessing(): boolean {
      return !!this.step && this.step.state === 'processing';
    },
    themeCustomization(): ThemeOnlineSignatureCustomization {
      return this.$store.getters['theme/onlineGuiCustomization'];
    },
    autoredirect(): boolean {
      return this.$store.getters['config/autoredirect'];
    },
    autoredirectDelay(): number {
      return this.$store.getters['config/autoredirectDelay'];
    }
  },

  methods: {
    signatureSettings(): void {
      const queryObject = Object.assign({...this.$router.currentRoute.query},
          {skipIntro: 'true'}) as Record<string, string>;
      const redirectUri = this.$router.currentRoute.path + '?' + new URLSearchParams(queryObject).toString();

      this.$router.push({
        path: '/profile/signature',
        query: {'online-signature-redirect-uri': redirectUri}
      }).catch(() => { /* ignore */ });
    },
    redirectToSystem(instant?: boolean): void {
      if (this.redirectUri) {
        if (!instant && this.autoredirect && this.autoredirectDelay) {
          setTimeout(() => {
            window.location.href = this.redirectUri;
          }, this.autoredirectDelay * 1000);
        }
        else window.location.href = this.redirectUri;
      }
      else {
        console.error('No redirect URL provided');
      }
    },
    async loadFlow(): Promise<void> {
      try {
        if (this.flowId) {
          this.flow = await apiWorkflowGet(this.flowId);
        }
      }
      catch (e) {
        console.error('Failed to load flows', e);
      }
    }
  }
})

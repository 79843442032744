
import mixins from "vue-typed-mixins";

export default mixins().extend({
  name: "OverviewModeSwitch",
  data() {
    return {
      items: [
        {name: this.$t('nav.modes.standard'), icon: "$list", mode: "list"},
        {name: this.$t('nav.modes.batch'), icon: "$tableCells", mode: "checkboxOn"}
      ]
    }
  },
  computed: {
    batchMode: {
      get(): boolean {
        return this.$store.getters["workflow/batchMode"];
      },
      set(value: string) {
        this.$store.commit('workflow/setBatchMode', {batchMode: value});
      }
    },
    selectedTab: {
      get(): string {
        return this.batchMode ? 'checkboxOn' : 'list';
      },
      set(value: string) {
        this.batchMode = value === 'checkboxOn';
      }
    }
  }
})


import FlowStepVisualisationAvatar from "@/component/workflow/detail/FlowStepVisualisationAvatar.vue";
import SHorizontalScrollableContainer from "@/component/ui/SHorizontalScrollableContainer.vue";
import Vue, {PropType} from "vue";
import {FlowState, FlowStep} from "@/types";

export default Vue.extend({
  name: 'FlowStepVisualisation',
  components: { SHorizontalScrollableContainer, FlowStepVisualisationAvatar },
  data(){
    return {
      ArraySize: undefined as number | undefined,
    }
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    flowState: {
      type: String as PropType<FlowState>,
      required: true
    },
    steps: {
      type: Array as PropType<FlowStep[]>,
      required: true
    },
  },
  methods: {
    splitArraysToStepOrder() {
      let newArray = {} as any;
      //Split pole, které přijde z api, Vytvoření polí podle stepOrderu, pro lepší práci v gui
      for (let i = 0; i < this.steps.length; i++) {
        //Jelikož je možnost kroku se stepOrder 0 je použito toto řešení, stejné použití jako v komponentě FlowDetailmetadataView
        let calculateOrder = this.steps[i].stepOrder + 2;
        if(!newArray[calculateOrder]) newArray[calculateOrder] = [];
        newArray[calculateOrder].push(this.steps[i]);
      }
      this.ArraySize = Object.keys(newArray).length;
      let arrayOfIndex = Object.keys(newArray);

      //změna klíčů aby byly postupně za sebou a nebyly tam mezery pokud něco takového z API příjde
      for (let i = 0; i <= this.ArraySize - 1; i++) {
        newArray[i] = newArray[arrayOfIndex[i]];
        delete newArray[arrayOfIndex[i]];
      }
      return newArray;
    },
  },
  created() {
    this.splitArraysToStepOrder();
  },
  updated() {
    this.splitArraysToStepOrder();
  }
})

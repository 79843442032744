import { render, staticRenderFns } from "./StepSigners.vue?vue&type=template&id=814acce4&scoped=true&"
import script from "./StepSigners.vue?vue&type=script&lang=ts&"
export * from "./StepSigners.vue?vue&type=script&lang=ts&"
import style0 from "./StepSigners.vue?vue&type=style&index=0&id=814acce4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "814acce4",
  null
  
)

export default component.exports
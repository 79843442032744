
import Vue, {PropType} from "vue";
import SignatureContentLayout from "@/component/signature/SignatureContentLayout.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {CancelType, Flow, NoteInputMode, VariantMode} from "@/types";
import {nameToDisplay} from "@/utils/accountUtils";
import SForm from "@/component/ui/form/SForm.vue";
import STextarea from "@/component/ui/form/STextarea.vue";
import {ACCOUNT_TYPE} from "@/utils/constants";

export default Vue.extend({
  name: 'SignatureOnlineRejection',

  components: {SignatureContentLayout, STextButton, STextarea, SForm},

  props: {
    flow: {
      type: Object as PropType<Flow>,
      required: true
    }
  },

  data() {
    return {
      rejectionReason: null as string | null,
    }
  },

  computed: {
    assignerName(): string | undefined {
      return nameToDisplay(this.flow?._embedded?.owner);
    },
    recall(): boolean {
      return this.flow?.state === 'active' && this.flow?._embedded?.owner.accountName ===
          this.$store.getters['profile/accountName'];
    },
    cancelType(): string {
      return this.recall ? 'recall' : 'rejection';
    },
    documentTitle(): string {
      return this.$t(`signature.online.${this.cancelType}.title`, {subject: this.flow?.subject}).toString();
    },
    cancelInstructions(): string {
      return this.cancelTextConfig.instructions ??
          this.$t(`signature.online.${this.cancelType}.instruction`, {user: this.assignerName}).toString();
    },
    reasonPlaceholder(): string {
      if (this.cancelTextConfig.placeholder || this.cancelTextConfig.placeholder == "") {
        return this.cancelTextConfig.placeholder;
      }
      else {
        switch (this.cancelTextConfig.noteInputMode) {
          case NoteInputMode.OPTIONAL:
            return this.$tc(`workflow.${this.cancelType}.reasonPlaceholder.optional`, 1).toString();
          case NoteInputMode.REQUIRED:
            return this.$tc(`workflow.${this.cancelType}.reasonPlaceholder.required`, 1).toString();
          default:
            return "";
        }
      }
    },
    submitText(): string {
      return this.$t(`signature.online.${this.cancelType}.actions.submit`).toString();
    },
    cancelTextConfig(): CancelType {
      if (this.externalAccount)
        return this.recall ? this.externalUserVariant.recall : this.externalUserVariant.rejection;
      else
        return this.recall ? this.onlineSignatureVariant.recall : this.onlineSignatureVariant.rejection;
    },
    reasonValidation(): string {
      return this.cancelTextConfig.noteInputMode == NoteInputMode.REQUIRED ? 'required|max:500' : 'max:500';
    },
    rejectionInputNotAllowed(): boolean {
      return this.cancelTextConfig.noteInputMode == NoteInputMode.NOT_ALLOWED
    },
    rejectionInputRequired(): boolean {
      return this.cancelTextConfig.noteInputMode == NoteInputMode.REQUIRED
    },
    externalUserVariant(): VariantMode {
      return this.$store.getters['config/externalUserVariant'];
    },
    onlineSignatureVariant(): VariantMode {
      return this.$store.getters['config/onlineSignatureVariant'];
    },
    externalAccount(): boolean {
      return (this.$store.getters['profile/accountType'] === ACCOUNT_TYPE.EXTERNAL
          || this.$store.getters['profile/accountType'] === ACCOUNT_TYPE.TEMPORARY);
    },
  },

  methods: {
    back(): void {
      this.$emit('back');
    },
    async reject(): Promise<void> {
      const isFormValid = await (this.$refs.rejectionForm as InstanceType<typeof SForm>).validate();
      if (!isFormValid) return;
      this.$emit('reject', this.rejectionReason);
    }
  }
})

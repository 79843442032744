
import Vue, {PropType} from 'vue';
import SDialog from "@/component/ui/SDialog.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SFileSelector from "@/component/ui/form/SFileSelector.vue";
import {apiBatchGet} from "@/services/api/batchesApi";
import {Batch, BatchState, Flow, FlowStep} from "@/types";
import SLoader from "@/component/ui/SLoader.vue";

export default Vue.extend({
  name: "ExternalSigningDialog",
  components: {SLoader, SFileSelector, STextButton, SDialog},
  props: {
    value: Boolean,
    flow: Object as PropType<Flow>,
    currentStep: Object as PropType<FlowStep>
  },
  data() {
    return {
      documentFile: undefined as File | undefined,
      fileIsUploaded: false,
      processingFile: false,
      processingCompleted: false,
      batchResponse: undefined as Batch | undefined,
      disableCompleted: false
    }
  },
  computed: {
    BatchState() {
      return BatchState
    },
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    uploadUrl(): string {
      return this.currentStep?._links["sef:external-signature"]?.href;
    },
    batchErrorText(): string {
      let reportRow = this.batchResponse?.reportRows[0];
      if (reportRow?.reportStateDescription) {
        return reportRow?.reportStateDescription;
      }
      else if (reportRow?.reportStateName) {
        return reportRow?.reportStateName;
      }
      else {
        return "";
      }
    },
    batchError(): boolean {
      return this.batchResponse?.batchState === BatchState.WITH_ERRORS;
    },
    processingResultText(): string {
      let resultText = ""
      if(this.batchResponse?.batchState === BatchState.COMPLETED) {
        resultText = this.$t('workflow.externalSigning.successText').toString();
      }
      if(this.batchResponse?.batchState === BatchState.WITH_ERRORS) {
        resultText = this.batchErrorText;
      }
      return resultText
    },
    processingResultIcon(): Record<string, string> {
      return (this.batchResponse?.batchState === BatchState.COMPLETED) ? {icon: '$stateSuccess', color: "success"}
          : (this.batchResponse?.batchState === BatchState.WITH_ERRORS) ? {icon: '$stateError', color: "error"} : {};
    },
    showUploadedButton(): boolean {
      if (this.batchResponse?.batchState === BatchState.WITH_ERRORS) {
        return true;
      }else if(this.batchResponse?.batchState === BatchState.COMPLETED) {
        return false;
      }
      return !this.processingFile;
    }
  },
  methods: {
    close(): void {
      this.localValue = false;
      this.clear();
    },
    async onProcessingCompleted(response: any): Promise<void> {
      this.fileIsUploaded = true;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        try {
          this.processingFile = true;
          this.batchResponse = await apiBatchGet(response.batchId);
          if (this.batchResponse.batchState != BatchState.IN_PROGRESS) {
            this.processingFile = false;
            this.processingCompleted = true;
            break;
          }
          await new Promise(resolve => setTimeout(resolve, 3000));
        }
        catch (error) {
          console.log(error);
          this.processingFile = false
        }
        finally {
          this.processingFile = false
          if (this.batchResponse?.batchState === BatchState.COMPLETED) {
            this.disableCompleted = true;
            this.$emit('processing-document-completed');
          }
          if(this.batchResponse?.batchState === BatchState.WITH_ERRORS) {
            (this.$refs.fileSelector as InstanceType<typeof SFileSelector>).removeFile();
          }
        }
      }
    },
    clear(): void {
      (this.$refs.fileSelector as InstanceType<typeof SFileSelector>).removeFile();
      (this.$refs.fileSelector as InstanceType<typeof SFileSelector>).reset();
      this.documentFile = undefined;
      this.fileIsUploaded = false;
      this.processingFile = false;
      this.processingCompleted = false;
      this.disableCompleted = false;
      this.batchResponse = undefined;
    }
  }
})

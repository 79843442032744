
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SDialog from "@/component/ui/SDialog.vue";

export default mixins(displayMixin).extend({
  name: 'ConfirmDialog',
  components: {SDialog, STextButton},
  props: {
    cancelText: String,
    confirmText: String,
    loading: Boolean,
    subtitle: String,
    title: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    cancel(): void {
      this.$emit('cancel');
    },
    confirm(): void {
      this.$emit('confirm');
    }
  },
  computed: {
    defaultCancelText(): string {
      return this.$t('common.actions.cancel').toString();
    },
    defaultConfirmText(): string {
      return this.$t('common.actions.confirm').toString();
    },
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  }
})

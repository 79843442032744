
import _ from "lodash";
import mixins from "vue-typed-mixins";
import buttonMixin from "@/mixins/buttonMixin";

export default mixins(buttonMixin).extend({
  name: 'SIconButton',

  props: {
    background: Boolean,
    disabled: Boolean,
    icon: {
      type: String,
      required: true
    },
    loading: Boolean,
    large: Boolean,
    size: Number,
    small: Boolean,
    xSmall: Boolean,
    tooltip: String,
    tooltipOpenDelay: {
      type: Number,
      default: 1000
    },
    tooltipPosition: {
      type: String,
      default: 'bottom',
      validator(value: unknown) {
        return _.isString(value) && ['top', 'left', 'right', 'bottom'].indexOf(value) !== -1;
      }
    },
    iconSize: {
      type: String,
      default: 'medium',
      validator(value: unknown) {
        return _.isString(value) && ['x-small', 'small', 'medium', 'large'].indexOf(value) !== -1;
      }
    }
  },

  computed: {
    localIcon(): string {
      return '$' + this.icon;
    },
    touchScreen(): boolean {
      return this.$store.getters['config/touchScreen'];
    },
    //Nastavení velikosti ikony
    //TODO: sjednotit nastavení velikosti ikon pro SP
    convertIconSize(): number | string {
      return this.iconSize === 'x-small' ? 8 : this.iconSize === 'small' ? 16 : this.iconSize === 'medium' ? 20 : this.iconSize === 'large' ? 24 : 20;
    }
  }
})

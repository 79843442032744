import Vue from 'vue'
import axios from "@/plugin/vueAxios";
import {WORKFLOW_HIGHLIGHT} from "@/utils/urls";
import {Flow} from "@/types";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";

interface WorkflowState {
  batchMode: boolean;
  draft: Flow | undefined;
  selectableFlowCount: number;
  flowStates: {
    flowActive: Flow | undefined;
    flowSelected: Array<Flow>;
    flowDisabled: Array<Flow>;
    flowError: Array<Flow>;
  };
  //Map flowId -> errorMessage
  flowErrorMessagesMap: {[key: number]: string};
}

const state = (): WorkflowState => ({
  batchMode: false,
  draft: undefined,
  selectableFlowCount: 0,
  flowStates: {
    flowActive: undefined,
    flowSelected: [],
    flowDisabled: [],
    flowError: [],
  },
  //TODO probably just temporary solution
  flowErrorMessagesMap:{}
})

const mutations: MutationTree<WorkflowState> = {
  flowCancelSelection() {
    // Empty on purpose
    // Acts as an event
  },
  flowNext() {
    // Empty on purpose
    // Acts as an event
  },
  flowPrev() {
    // Empty on purpose
    // Acts as an event
  },
  flowSelectAll() {
    // Empty on purpose
    // Acts as global event
  },
  flowLoadAll() {
    // Empty on purpose
    // Acts as global event
  },
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  flowsUpdate(state, payload: { flowIds: Array<number>, flowsPath: string | undefined }) {
    // Empty on purpose, acts as global event
  },
  openDraft(state, payload: {draft: Flow}) {
    state.draft = payload.draft;
  },
  // eslint-disable-next-line no-unused-vars
  updateFlow() {
    // Empty on purpose
    // Acts as global event
  },
  draftDeleted() {
    //Empty on purpose
    //Acts as global event
  },
  clearDetail() {
    //Empty on purpose
    //Acts as global event
  },
  updateDetail() {
    // Empty on purpose, acts as global event
  },
  openWorkflowWizard(){
    //Empty on purpose
    //Acts as global event
  },
  setBatchMode(state, payload: {batchMode: boolean}) {
    state.batchMode = payload.batchMode;
  },
  setFlowActive(state, payload: {flowActive: Flow|undefined}) {
    state.flowStates.flowActive = payload.flowActive;
  },
  setSelectableFlowCount(state, payload: {count: number}) {
    state.selectableFlowCount = payload.count;
  },
  setFlowSelected(state, payload: {flowSelected: Array<Flow>}) {
    state.flowStates.flowSelected = payload.flowSelected;
  },
  setFlowDisabled(state, payload: {flowDisabled: Array<Flow>}){
    state.flowStates.flowDisabled = payload.flowDisabled;
  },
  addDisabledFlow(state, payload: {flow: Array<Flow> | Flow}){
    if (Array.isArray(payload.flow)) {
      state.flowStates.flowDisabled = state.flowStates.flowDisabled.concat(payload.flow);
    }
    else {
      state.flowStates.flowDisabled.push(payload.flow)
    }
  },
  setErroredFlow(state, payload: {erroredFlow: Array<Flow>}){
    state.flowStates.flowError = payload.erroredFlow;
    state.flowErrorMessagesMap = {};
    for (const flow of payload.erroredFlow){
      //TODO here should be error from flow
      Vue.set(state.flowErrorMessagesMap, flow.flowId, 'Some exception')
    }
  },
  addErroredFlow(state,  payload: {flow: Array<Flow> | Flow, message: string|undefined}){
    if (Array.isArray(payload.flow)) {
      state.flowStates.flowError = state.flowStates.flowError.concat(payload.flow);
      for (const flow of payload.flow) {
        //TODO here should be error from flow
        Vue.set(state.flowErrorMessagesMap, flow.flowId, 'Some exception')
      }
    } else {
      state.flowStates.flowError.push(payload.flow)
      if(payload.message){
        Vue.set(state.flowErrorMessagesMap, payload.flow.flowId, payload.message);
      } else {
        //TODO here should be error from flow
        Vue.set(state.flowErrorMessagesMap, payload.flow.flowId, 'Some exception')
      }
    }
  },
  removeErroredFlow(state, payload: {flow: Flow}){
    state.flowStates.flowError = state.flowStates.flowError.filter(f => f.flowId !== payload.flow.flowId)
    Vue.set(state.flowErrorMessagesMap, payload.flow.flowId, undefined);
  }
}

const actions:ActionTree<WorkflowState, RootState> = {
  setBatchMode({ commit, state }, payload: {batchMode: boolean}) {
    commit('setBatchMode', payload);
    if (!state.batchMode) {
      commit('flowCancelSelection');
    }
  },

  async unmarkFlow({ commit }, payload: {flow: Flow}) {
      await axios.delete(WORKFLOW_HIGHLIGHT + payload.flow.flowId)
    commit('navigation/refreshCounters', null, {root: true});
    payload.flow.highlighted = false;
  },

  async viewFlow({ commit }, payload: {flowUrl: string}) {
    if (payload.flowUrl) {
      const response = await axios.get(payload.flowUrl);
      if (response.data)
        commit('setFlowActive', {flowActive: response.data});
    }
  }
}

const getters: GetterTree<WorkflowState, RootState> = {
  batchMode: (state): boolean => {
    return state.batchMode;
  },
  flowActive: (state): Flow|undefined => {
    return state.flowStates.flowActive;
  },
  selectableFlowCount: (state): number => {
    return state.selectableFlowCount;
  },
  flowSelected: (state): Array<Flow> => {
    return state.flowStates.flowSelected;
  },
  flowDisabled: (state): Array<Flow> => {
    return state.flowStates.flowDisabled;
  },
  flowInError: (state): Array<Flow> => {
    return state.flowStates.flowError;
  },
  flowSelectedCount: (state): number => {
    return state.flowStates.flowSelected.length;
  },
  flowErrorMessagesMap: (state): {[key: number]: string} => {
    return state.flowErrorMessagesMap;
  }
}

export const workflow: Module<WorkflowState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiFoldersGet} from "@/services/api/foldersApi";
import {RootState} from "@/store/store";
import {Category, Folder, FolderMode, NavCategory, NavigationMode, SortingItem} from "@/types/navigation";
import {flatFolders} from "@/utils/folderUtils";

interface NavigationState {
  draftFlowId: number | undefined;
  folders: Array<Folder> | undefined;
  foldersFlat: Array<Folder> | undefined;
  // Pre-defined navigation categories
  navCategories: {[key: string]: NavCategory};
  lastVisitedCategory: Category | undefined;
  lastSelectedSorting: {[key: number]: SortingItem}
  mode: NavigationMode;
  selectedFolderId: number | undefined;
}

const state = (): NavigationState => ({
  draftFlowId: undefined,
  folders: undefined,
  foldersFlat: undefined,
  navCategories: {
    draft: {
      title: 'nav.navCategory.drafts',
      icon: 'file',
    },
    'active-my': {
      title: 'nav.navCategory.toSign',
      icon: 'sig',
    },
    'active-others': {
      title: 'nav.navCategory.waiting',
      icon: 'clock',
    },
    finished: {
      title: 'nav.navCategory.finished',
      icon: 'finished',
    },
    canceled: {
      title: 'nav.navCategory.canceled',
      icon: 'ban',
    },
    events: {
      title: 'nav.navCategory.events',
      icon: 'bell',
    }
  },
  lastVisitedCategory: undefined,
  lastSelectedSorting: {} as {[key: number]: SortingItem},
  mode: NavigationMode.NAV,
  selectedFolderId: undefined
})

const mutations: MutationTree<NavigationState> = {
  foldersRefresh() {
    // Empty on purpose, acts as global notification
  },
  openDraft(state, payload: { flowId: number }) {
    state.draftFlowId = payload.flowId;
  },
  refreshCounters() {
    // Empty on purpose, acts as global notification
  },
  setNavMode(state, payload: { mode: NavigationMode }) {
    state.mode = payload.mode;
  },
  clearSearch() {
    // Empty on purpose, acts as global notification
  },
  setFolders(state, payload: { folders: Array<Folder> }) {
    state.folders = payload.folders;
    state.foldersFlat = flatFolders(payload.folders);
  },
  setLastVisitedCategory(state, payload: { category: Category }) {
    state.lastVisitedCategory = payload.category;
  },
  setSelectedFolderId(state, payload: { folderId: number | undefined }) {
    state.selectedFolderId = payload.folderId;
  },
  setLastSelectedSorting(state, payload: {folderId: number, selectedSort: SortingItem}) {
    state.lastSelectedSorting[payload.folderId] = payload.selectedSort;
  }
}

const actions: ActionTree<NavigationState, RootState> = {
  async loadFolders({ commit }, payload: { folderMode: FolderMode }): Promise<Array<Folder>> {
    try {
      const folders = await apiFoldersGet(payload.folderMode);
      /*
       * Režim "counters" vrací plochý seznam složek, kde pro každou složku je uvdeno pouze folderId a počty.
       * Tento seznam se neukládá.
       */
      if (payload.folderMode !== FolderMode.COUNTERS)
        commit('setFolders', { folders });
      return folders ?? [];
    }
    catch (e) {
      console.error(`Error occurred while getting folders in mode "${payload.folderMode}"`, e);
      return [];
    }
  }
}

const getters: GetterTree<NavigationState, RootState> = {
  folders: (state): Array<Folder> => {
    return state.folders ?? [];
  },
  foldersFlat: (state): Array<Folder> => {
    return state.foldersFlat ?? [];
  },
  navCategories: (state) => {
    return state.navCategories;
  },
  lastVisitedCategory: (state): Category | undefined => {
    return state.lastVisitedCategory;
  },
  mode: (state): NavigationMode => {
    return state.mode;
  },
  selectedFolderId: (state): number | undefined => {
    return state.selectedFolderId;
  },
  state: (state) => (folderId: number): string | undefined => {
    return state.foldersFlat?.find(folder => folder.folderId == folderId)?.state;
  },
  url: (state) => (folderId: number): string | undefined => {
    return state.foldersFlat?.find(folder => folder.folderId == folderId)?._links?.['sef:folder-content']?.href;
  },
  lastSelectedSorting: (state) => (folderId: number): SortingItem => {
    return state.lastSelectedSorting[folderId];
  }
}

export const navigation: Module<NavigationState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};


import Vue from "vue";
import SAvatar from "@/component/ui/SAvatar.vue";

export default Vue.extend({
  name: 'AccountInfo',

  components: {SAvatar},

  props: {
    notification: String,
    small: Boolean,
    text: Boolean
  },

  computed: {
    avatar(): string | undefined {
      return this.$store.getters['profile/avatar'];
    },
    email(): string {
      return this.$store.getters['profile/email'];
    },
    name(): string {
      return this.$store.getters['profile/name'];
    }
  }
})

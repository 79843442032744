
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import QuickActionDropZone from "@/component/quickActions/QuickActionDropZone.vue";
import Vue from "vue";

export default Vue.extend({
  name: "NavigationSignActions",
  components: {SIconButton, STextButton, QuickActionDropZone},
  props: {
    showQuickAction: Boolean
  },
  computed: {
    hasCategory(): boolean {
      return this.$store.getters['config/categories'].length >= 1;
    },
    displayShowQuickAction(): boolean {
      return this.$store.getters['config/showQuickActionBtn'];
    },
    multipleActions(): boolean {
      return this.hasCategory && this.displayShowQuickAction;
    }
  },
  methods: {
    createWorkflow() {
      this.$store.commit("workflow/openWorkflowWizard")
    },
    changeQuickAction(): void {
      this.$emit('hideQuickAction');
    }
  }
})

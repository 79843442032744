import axios from "@/plugin/vueAxios";
import {Batch, BatchCreation, BatchCreationMode, FlowStep, FlowStepBase, OptionalString} from "@/types";

const BATCHES_PATH = '/batches';

async function apiBatches(stepIds: Array<FlowStep['stepId']>, batchName: BatchCreation['batchName'],
                          bulkMode: BatchCreation['bulkMode'], creationMode: BatchCreation['creationMode'],
                          signatureImage?: BatchCreation['signatureImage'],
                          signingTextName?: BatchCreation['signingTextName']): Promise<Batch> {
  const steps: Array<FlowStepBase> = stepIds.map(stepId => ({ stepId }));
  const batch: BatchCreation = {
    _embedded: {
      steps
    },
    batchName,
    bulkMode,
    creationMode,
    signatureImage,
    signingTextName
  };

  const response = await axios.post<Batch>(BATCHES_PATH, batch);
  return response.data;
}

export async function apiBatchCreate(stepIds: Array<FlowStep['stepId']>, batchName: string,
                                     signatureImage?: OptionalString): Promise<Batch> {
  return apiBatches(stepIds, batchName, true, BatchCreationMode.IGNORE_ERRORS, signatureImage);
}

export async function apiBatchGet(batchId: Batch['batchId']): Promise<Batch> {
  const response = await axios.get<Batch>(`${BATCHES_PATH}/${batchId}`);
  return response.data;
}

export async function apiDocumentSign(stepId: FlowStep['stepId'], signatureImage?: OptionalString,
                                      signingTextName?: OptionalString): Promise<Batch> {
  return apiBatches([stepId], undefined, false, BatchCreationMode.WITHOUT_ERRORS_ONLY, signatureImage, signingTextName);
}


import Vue from "vue";

export default Vue.extend({
  name: 'SIconBadge',
  props: {
    color: {
      type: String,
      default: 'mainContrast'
    },
    content: String,
    icon: {
      type: String,
      required: true
    }
  }
})


import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";

export default mixins(displayMixin).extend({
  name: 'ProfileHeaderMain',
  components: {SIconButton},
  props: {
    title: String,
    color: {
      type: String,
      default: 'transparent'
    },
  },
  methods: {
    navOpen() {
      this.$emit('nav-open');
    }
  }
})

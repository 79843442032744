
import interactionCenterMixin from "@/mixins/interactionCenterMixin";
import mutationMixin from "@/mixins/mutationMixin";
import InteractionCenterBatchAuthorization
  from "@/component/notifications/list/InteractionCenterBatchAuthorization.vue";
import InteractionCenterBatchError from "@/component/notifications/list/InteractionCenterBatchError.vue";
import InteractionCenterBatchProcessing from "@/component/notifications/list/InteractionCenterBatchProcessing.vue";
import InteractionCenterCard from "@/component/notifications/list/InteractionCenterCard.vue";
import SLoader from "@/component/ui/SLoader.vue";
import mixins from "vue-typed-mixins";
import {PropType} from "vue";
import {Batch, NotificationFolderType} from "@/types";
import InteractionCenterBatchSimple from "@/component/notifications/list/InteractionCenterBatchSimple.vue";

export default mixins(interactionCenterMixin, mutationMixin).extend({
  name: 'InteractionCenter',
  components: {
    InteractionCenterBatchSimple,
    InteractionCenterBatchAuthorization,
    InteractionCenterBatchError,
    InteractionCenterBatchProcessing,
    InteractionCenterCard,
    SLoader
  },

  props: {
    activeVariant: String as PropType<NotificationFolderType>,
    singleBatch: Object as PropType<Batch | undefined>,
    dense: Boolean,
    hideEmpty: Boolean,
    navigationUserMenu: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // eslint-disable-next-line no-undef
      batchesBuffer: [] as Array<{ batch: Batch, removeTimerId: NodeJS.Timeout | undefined }>,
      batchesLoadInterval: undefined as number | undefined,
      batchesLoadTimeout: 2000,
      batchesRemoveTimeout: 3000,
      loading: false,
      unwatchBatchesBufferFn: undefined as (() => void) | undefined,
      elemWidth: 0
    };
  },

  computed: {
    isVariantAuthorization(): boolean {
      return this.activeVariant === 'authorization';
    },

    isVariantError(): boolean {
      return this.activeVariant === 'error';
    },

    isVariantProcessing(): boolean {
      return this.activeVariant === 'processing' || !!this.singleBatch;
    },

    isVariantNotifications(): boolean {
      return this.activeVariant === 'notifications';
    },

    mobileView(): boolean {
      return this.elemWidth < 450;
    },

    empty(): boolean {
      return this.batchesBuffer.length === 0 && !this.loading;
    }
  },

  methods: {
    async bufferUpdate(batches: Array<Batch>): Promise<void> {
      let updatedBatchIds: Array<number> = [];
      if (batches) {
        /* Příchozí seznam dávek se prochází v opačném pořadí, aby bylo zachováno pořadí příchozího seznamu. Nové dávky
         * se vkládají na začátek bufferu.
         */
        batches.reverse().forEach(batch => {
          updatedBatchIds.unshift(batch.batchId);
          let bufferIndex = this.batchesBuffer.findIndex(bufferItem => bufferItem.batch.batchId === batch.batchId);
          if (bufferIndex > -1) {
            // Aktualizace záznamu bufferu
            this.batchesBuffer[bufferIndex].batch = batch;
          } else {
            // Vložení nového záznamu na začátek bufferu
            this.batchesBuffer.unshift({
              batch: batch,
              removeTimerId: undefined
            });
          }
        });
      }
      // Nastavení zpožděného odstranění záznamu z bufferu
      let bufferItemsToRemove = this.batchesBuffer.filter(
          bufferItem => !bufferItem.removeTimerId && !updatedBatchIds.includes(bufferItem.batch.batchId));
      await Promise.all(bufferItemsToRemove.map(async (bufferItem) => {
        // Zjištění posledního stavu dávky kvůli zobrazení výsledku zpracování předtím, než dojde k odstranění z bufferu
        let data = await this.loadBatchFinalState(bufferItem.batch);
        if (data)
          bufferItem.batch = data;
        bufferItem.removeTimerId = setTimeout(this.bufferItemRemove, this.batchesRemoveTimeout, bufferItem.batch.batchId);
      }));
    },

    bufferItemRemove(batchId: number): void {
      let bufferIndex = this.batchesBuffer.findIndex(bufferItem => bufferItem.batch.batchId === batchId);
      if (bufferIndex < 0) {
        console.warn('Interaction center: Batch %s not found in buffer', batchId);
      } else {
        let removeTimerId = this.batchesBuffer[bufferIndex].removeTimerId;
        if (removeTimerId)
          clearTimeout(removeTimerId);
        this.batchesBuffer.splice(bufferIndex, 1);
      }
    },

    clearBufferTimeouts(): void {
      this.batchesBuffer.filter(bufferItem => bufferItem.removeTimerId).forEach(bufferItem => {
        if (bufferItem.removeTimerId)
          clearTimeout(bufferItem.removeTimerId)
      });
    },

    isBatchProcessingDone(batch: Batch): boolean {
      let reportRowsDone = batch.reportRows.filter(row => ['finished', 'error'].includes(row.reportStateType));
      return reportRowsDone.length === batch.reportRows.length;
    },

    async loadBatches(variant: NotificationFolderType, isUpdate: boolean): Promise<void> {
      try {
        if (!isUpdate)
          this.loading = true;
        let data = await this.apiGetBatchesInState(variant);
        if (data && data._embedded)
          await this.bufferUpdate(data._embedded.batches);
      }
          // eslint-disable-next-line no-empty
      catch (ignore) {
      } finally {
        if (!isUpdate)
          this.loading = false;
      }
    },

    async loadBatchFinalState(batch: Batch): Promise<Batch | undefined> {
      let response;
      try {
        response = await this.axios.get(batch._links.self.href);
      } catch (ignore) {/**/
      }

      return response?.data as Batch;
    },

    resetBatches(): void {
      clearInterval(this.batchesLoadInterval);
      this.unwatchBatchesBuffer();
      this.clearBufferTimeouts();
      this.batchesBuffer = [];
    },

    async updateActiveBatch(getActiveBatchFn: () => Batch) {
      try {
        let batch: Batch = getActiveBatchFn();
        let response = await this.axios.get(batch._links.self.href);
        if (response.data) {
          if (this.isBatchProcessingDone(response.data)) {
            await this.bufferUpdate([]);
            clearInterval(this.batchesLoadInterval);
            this.$emit("active-batch-finished")
          } else {
            await this.bufferUpdate([response.data]);
          }
        }
      } catch (ignore) {/**/
      }
    },

    unwatchBatchesBuffer(): void {
      if (this.unwatchBatchesBufferFn) {
        this.unwatchBatchesBufferFn();
        this.unwatchBatchesBufferFn = undefined;
      }
    },

    watchEmptyBatchesBuffer(): void {
      this.unwatchBatchesBufferFn = this.$watch('batchesBuffer', (value) => {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          this.unwatchBatchesBuffer();
        }
      });
    },

    onResize() {
      this.elemWidth = this.$el.clientWidth;
    }
  },

  watch: {
    activeVariant: {
      immediate: true,
      handler(value) {
        this.resetBatches();
        if (value) {
          this.loadBatches(value, false);
          this.batchesLoadInterval = setInterval(this.loadBatches, this.batchesLoadTimeout, value, true);
        }
      }
    },
    singleBatch: {
      immediate: true,
      handler() {
        if (!this.singleBatch) return;
        this.bufferUpdate([this.singleBatch]);
        this.watchEmptyBatchesBuffer();
        this.batchesLoadInterval = setInterval(this.updateActiveBatch, this.batchesLoadTimeout,
            () => this.singleBatch);
      }
    },
  },

  mounted() {
    this.elemWidth = this.$el.clientWidth;
  },

  beforeDestroy() {
    this.resetBatches();
  },
})

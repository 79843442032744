import {UserAccount} from "@/types";

export function nameToDisplay(info: UserAccount): string {
    if (info.userLastName) {
        if (info.userFirstName) {
            return info.userFirstName + " " + info.userLastName;
        } else {
            return info.userLastName;
        }
    } else if(info.accountName) {
        return info.accountName;
    } else {
        return info.userEmail
    }
}

 export function nameWithEmailToDisplay(info: UserAccount): string {
    if (info.userLastName) {
        if (info.userFirstName) {
            return info.userFirstName + " " + info.userLastName + " (" + info.userEmail + ")";
        } else {
            return info.userLastName;
        }
    } else {
        return info.accountName;
    }
}

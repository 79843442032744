
import Vue, {PropType} from "vue";
import SImg from "@/component/ui/SImg.vue";
import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import {Visualisation} from "@/types";

export default Vue.extend({
  name: 'VisualisationSignature',

  components: {SImg},

  props: {
    height: String,
    visualisation: {
      type: Object as PropType<Visualisation>,
      required: true
    },
    width: String
  },
  computed: {
    containerFlex():string{
      return this.resizeColumn()?'column':'row';
    },
    responsiveWidth():string{
      return this.resizeColumn()?'100%':'50%';
    },
    responsiveHeight():string{
      return this.resizeColumn()?'50%':'100%';
    },

    logoImageSource(): string | undefined {
      if (this.visualisation?.logo)
        return IMAGE_DATA_URI_PREFIX + this.visualisation.logo;
      else
        return undefined;
    },
    backgroundLogo():  string | undefined {
      return this.logoImageSource ? `url(${this.logoImageSource}) center/contain no-repeat` : undefined;
    },
    signatureImageSource(): string | undefined {
      if (this.visualisation?.signatureImage)
        return IMAGE_DATA_URI_PREFIX + this.visualisation.signatureImage;
      else
        return undefined;
    },
    visualisationText(): string | undefined {
      if (!this.visualisation)
        return undefined;
      else if (this.visualisation.signingText)
        return this.visualisation.signingText;
      return undefined;
    }
  },
  methods:{
    resizeColumn():boolean{
      const width = parseFloat(this.width);
      const height = parseFloat(this.height);
      return !isNaN(width) && !isNaN(height) && width < height;
    }
  }
})


import mixins from "vue-typed-mixins";
import buttonMixin from "@/mixins/buttonMixin";
import displayMixin from "@/mixins/displayMixin";

export default mixins(buttonMixin, displayMixin).extend({
  name: 'SFloatingButton',
  props: {
    bottom: Boolean,
    disabled: Boolean,
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: 'white'
    },
    large: Boolean,
    loading: Boolean,
    right: Boolean,
    small: Boolean,
    xSmall: Boolean
  },
  computed: {
    localIcon(): string {
      return '$' + this.icon;
    },
    localIconColor(): string|undefined {
      return this.outlined ? undefined : this.iconColor;
    }
  }
})


import SForm from "@/component/ui/form/SForm.vue";
import SOptionPanel from "@/component/ui/form/SOptionPanel.vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import {Category, InputFlow, UserAccount} from "@/types";
import SFileSelector from "@/component/ui/form/SFileSelector.vue";
import workflowMixin from "@/mixins/workflowMixin";
import {PropType} from "vue";
import {MutationPayload} from "vuex";
import mutationMixin from "@/mixins/mutationMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import PDFViewer from "@/component/pdf/PDFViewer.vue";

export default mixins(displayMixin, workflowMixin, mutationMixin).extend({
  name: "UploadStep",
  components: {PDFViewer, SIconButton, STextButton, SFileSelector, SOptionPanel, SForm},
  props: {
    value: {
      type: Object as PropType<InputFlow>,
      required: true
    },
    active: Boolean,
  },
  data(){
    return {
      selectedCategoryCode: undefined as string | undefined,
      documentFile: undefined as File | undefined,
      filePlaceholder: undefined as string | undefined,
      fileIsUpload: false,
      error: '' as string | undefined,
      PDFToolbar: false,

    }
  },

  computed: {
    localValue: {
      get(): InputFlow | undefined {
        return this.value;
      },
      set(value: InputFlow) {
        this.$emit('input', value);
      }
    },
    categories(): Array<Category> {
      return this.$store.getters['config/categories'];
    },
    styleOptionPanel(): object {
      return {
        height: this.displayDesktop ? '500px' : '300px',
        width: this.displayDesktop ? '200px' : '100%',
        maxWidth: this.displayDesktop ? '200px' : '100%'
      }
    },
    styleFileSelector(): object {
      return {
        height: !this.displayMobile ? '500px' : '250px'
      }
    },
    flowUploadLink(): string | undefined {
      if (this.selectedCategory) {
        this.$emit('selectedCategory', this.selectedCategory);
        this.$emit('getSelectedCode', this.selectedCategoryCode);
        return this.selectedCategory._links['sef:upload-document-data'].href;
      }
      return undefined;
    },
    selectedCategory(): Category | undefined {
      return this.categories.find(c => c.categoryCode === this.selectedCategoryCode);
    },
    presetLoggedUserInCreatedFlow(): boolean {
      return this.$store.getters["config/presetLoggedUserInCreatedFlow"];
    },
    account(): UserAccount {
      return this.$store.getters["profile/info"]
    },
    lastVisitedCategory(): Category | undefined {
      return this.$store.getters['navigation/lastVisitedCategory'];
    },
    valid(): boolean {
      return !!this.localValue;
    }
  },

  methods: {
    onUploadCompleted(flow: InputFlow): void {
      let tmpFlow = this.parseDto(flow);
      if (this.presetLoggedUserInCreatedFlow) {
        tmpFlow._embedded.steps.push({
          requestedSignatureLevel: null,
          notifyWhenFinished: true,
          stepType: "signature",
          _embedded: {
            account: this.account
          }
        });
      }

      let trimmedFilename = tmpFlow._embedded.document.documentName;
      if (trimmedFilename.toLowerCase().endsWith(".pdf")) {
        trimmedFilename = trimmedFilename.substring(0, trimmedFilename.length - 4)
      }
      tmpFlow.subject = trimmedFilename.trim();
      this.fileIsUpload = true;
      this.localValue = tmpFlow;
    },
    onFileRemoved(): void {
      this.filePlaceholder = undefined;
      this.fileIsUpload = false;
      this.$emit('remove');
    },
    clear(): void {
      (this.$refs.fileSelector as InstanceType<typeof SFileSelector>)?.removeFile();
      this.error = undefined;
      this.filePlaceholder = undefined;
      this.fileIsUpload = false;
      this.documentFile = undefined;
    },
    addPlaceholderFile(filename: string) {
      this.filePlaceholder = filename;
    },
    setFileIsUpload(value: boolean) {
      //funkce, která je volaná v UploadWizard, a je tu proto, když se otevře koncept tak aby se to otevřelo v náhledu a né v nahrávání souboru.
      this.fileIsUpload = value;
    },
    async validate(): Promise<boolean> {
      if (!this.valid) {
        this.error = this.$t('uploadWizard.steps.docUpload.noDoc').toString();
        return false;
      }
      return true;
    },
  },

  watch: {
    value(value: InputFlow) {
      if (value) {
        this.selectedCategoryCode = value._embedded.category.categoryCode;
      }
    },
    valid: {
      handler(value) {
        this.$emit('valid', value);
      },
      immediate: true
    }
  },

  created() {
    this.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'navigation/setLastVisitedCategory') {
        this.selectedCategoryCode = this.lastVisitedCategory?.categoryCode;
      }
    });

    if (this.categories.length > 0) {
      this.selectedCategoryCode = this.lastVisitedCategory?.categoryCode ?? this.categories[0].categoryCode;
    }
  },
})

import axios from "@/plugin/vueAxios";
import {i18n} from '@/plugin/i18n';
import {USER_LOCALE} from "@/utils/urls";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {apiActiveLocalesGet} from "@/services/api/businessConfigApi";
import {RootState} from "@/store/store";
import {Locale} from "@/types";
import _ from "lodash";

interface LocaleState {
  dateFormat: string;
  dateTimeFormat: string;
  locales: Array<Locale>;
}

const state = (): LocaleState => ({
  dateFormat: 'yyyy-MM-dd',
  dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
  locales: [{ languageCode: 'en' },{ languageCode: 'cs'},{languageCode: 'sk'}]
})

const mutations: MutationTree<LocaleState> = {
  setDateFormat(state, payload: {dateFormat: string}){
    state.dateFormat = payload.dateFormat;
  },

  setDateTimeFormat(state, payload: {dateTimeFormat: string}) {
    state.dateTimeFormat = payload.dateTimeFormat;
  },

  setActiveLocales(state, payload: Array<Locale>) {
    if (payload) {
      state.locales = payload;
    }
  }
}

const actions: ActionTree<LocaleState, RootState> = {
  // eslint-disable-next-line no-unused-vars
  async changeLocale(context, payload: {locale: Locale}) {
    try {
      await axios.put(USER_LOCALE, {
        languageCode: payload.locale.languageCode
      });
      // Přenačtení celé aplikace, protože některá data se z API načítají při startu aplikace
      window.location.reload();
    }
    catch (e) {
      console.error('Localization: Error occurred while changing user localization');
    }
  },

  async loadLocale({dispatch, state, commit}): Promise<void> {
    try {
      const response = await axios.get(USER_LOCALE);
      dispatch('setLocale', response.data);

      // customizace textů
      try {
        const alterationsResponse = await axios.get(obeliskConfig.guiUrl + '/resources/text-alterations');
        if (alterationsResponse.data && Object.keys(alterationsResponse.data).length) {
          const cumulativeObj = {};
          for (const [key, value] of Object.entries(alterationsResponse.data))
            _.set(cumulativeObj, key, value);
          i18n.mergeLocaleMessage(response.data.languageCode, cumulativeObj);
        }
      }
      catch (e) {
        // selhání načtení customizací nechť není blokující pro nastavení jazyka - odchytíme a zkusíme pokračovat ve zpracování
        console.error('GUI messages customization: Error while loading. Using product defaults instead.');
      }

      //načtení z konfigurace aktivní lokalizace
      const languageResponse = await apiActiveLocalesGet();
      commit('setActiveLocales', languageResponse);

    }
    catch (e) {
      console.error('Localization: Error occurred while loading user localization');
      i18n.locale = state.locales[0].languageCode;
    }
  },

  setLocale({ commit }, payload: {dateFormat: string|undefined, dateTimeFormat: string|undefined, languageCode: string}) {
    if (payload.dateFormat)
      commit('setDateFormat', {dateFormat: payload.dateFormat});
    if (payload.dateTimeFormat)
      commit('setDateTimeFormat', {dateTimeFormat: payload.dateTimeFormat});
    i18n.locale = payload.languageCode;
    document.documentElement.setAttribute("lang", payload.languageCode);
  }
}

const getters: GetterTree<LocaleState, RootState> = {
  dateFormat: (state): string => {
    return state.dateFormat;
  },

  dateTimeFormat: (state): string => {
    return state.dateTimeFormat;
  },

  locale: (): string => {
    return i18n.locale;
  },

  locales: (state): Array<Locale> => {
    return state.locales;
  }
}

export const locale: Module<LocaleState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};


import {DateTime} from "luxon";
import {ValidationProvider} from "vee-validate";
import mixins from "vue-typed-mixins";
import formMixin from '@/mixins/formMixin';

export default mixins(formMixin).extend({
  name: 'SDateField',
  components: {
    ValidationProvider
  },
  props: {
    dataTestid: {
      type: String,
      default: 'SDateField'
    },
    disabled: Boolean,
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    fullWidth: Boolean,
    hint: String,
    id: String,
    label: String,
    locale: String,
    min: String,
    max: String,
    persistentPlaceholder: Boolean,
    placeholder: String,
    prefix: String,
    readonly: Boolean,
    value: String
  },
  data() {
    return {
      dateFormatted: null as string | null,
      firstDayOfWeek: 1, // pondělí
      menu: false
    }
  },
  computed: {
    localValidations(): string | object {
      if (typeof this.validations === 'object') {
        const staticValidations = {
          date: {
            format: this.format
          }
        };
        return { ...staticValidations, ...this.validations };
      }
      else {
        const staticValidations = 'date:' + this.format;
        return this.validations ? (staticValidations + '|' + this.validations) : staticValidations;
      }
    },
    localValue: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    format(value: string): void {
      this.dateFormatted = this.formatDate(this.localValue, value);
    },
    localValue(value): void {
      this.dateFormatted = this.formatDate(value, this.format);
    }
  },
  created() {
    this.dateFormatted = this.formatDate(this.localValue, this.format);
  },
  methods: {
    formatDate(date: string | null, format: string): string | null {
      return date ? DateTime.fromISO(date).toFormat(format) : null;
    },
    parseDate(date: string | null): string | null {
      return date ? DateTime.fromFormat(date, this.format).toISODate() : null;
    }
  }
})

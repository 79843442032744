
import Draggable from "vuedraggable"
import signingProfilesMixin from "@/mixins/signingProfilesMixin";
import SignatureProfileCard from "@/component/profile/signatures/SignatureProfileCard.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import mixins from "vue-typed-mixins";
import {PropType} from "vue";
import {
  SignatureMethod,
  SignatureProfile,
  SignatureProfileCardConfig,
  SignatureProfileCardConfigActions,
  SignatureProfileCardRow,
  SignatureProfileCardRowValue,
  SignatureProfileCardRowValueContent,
  SignatureProfileCardRowValueType
} from "@/types";
import ConfirmDialog from "@/component/dialog/ConfirmDialog.vue";
import SignatureProfileCardActions from "@/component/profile/signatures/component/SignatureProfileCardActions.vue";
import VisualisationSignature from "@/component/profile/visualisation/VisualisationSignature.vue";

export default mixins(signingProfilesMixin).extend({
  name: 'SignatureProfileList',
  components: {
    VisualisationSignature,
    SignatureProfileCardActions,
    ConfirmDialog,
    Draggable,
    SignatureProfileCard,
    STextButton,
  },
  props: {
    cardConfig: {
      type: Object as PropType<SignatureProfileCardConfig>,
      required: true
    },
    draggable: {
      type: Boolean,
      default: true
    },
    method: {
      type: Object as PropType<SignatureMethod>,
      required: true
    }
  },
  data() {
    return {
      confirmDialog: false,
      loading: false,
      confirmDialogMethod: {} as () => void,
      dialogSubtitle: undefined as string | undefined,
      dialogTitle: undefined as string | undefined,
      busyComponentsCheckIntervalId: null as ReturnType<typeof window.setInterval> | null
    }
  },
  computed: {
    profiles(): Array<SignatureProfile> {
      let profiles = this.$store.getters["profile/signatureProfiles"];
      return profiles[this.methodCode];
    },

    methodCode(): string {
      return this.method.providerTypeCode;
    },
    rows(): Array<SignatureProfileCardRow> {
      return this.cardConfig.rows ?? []
    },
    busyComponent(): string | null {
      return this.cardConfig.busyState?.component ?? null
    },
    busyProfiles(): Array<SignatureProfile> {
      return this.profiles.filter(profile => this.isBusy(profile))
    },
    hasCaActionComponent():boolean{
      return !!this.cardConfig?.caActionComponent
    }
  },
  watch: {
    method: {
      immediate: true,
      handler() {
        this.loadProfilesForCurrentMethod()
        if (this.busyComponentsCheckIntervalId != null) {
          clearInterval(this.busyComponentsCheckIntervalId)
        }
        const refresh = this.cardConfig.busyState?.refresh;
        if (refresh && refresh.interval) {
          this.busyComponentsCheckIntervalId = setInterval(() => {
            // iterate all busy profiles and call refresh for them
            this.busyProfiles.forEach((profile) => refresh.callback(profile, this.method.providerTypeCode))
          }, refresh.interval)
        }
      }
    }
  },
  created() {
    // no need to load the profiles here as long as the 'method' watcher is immediate
  },
  methods: {
    actions(profile: SignatureProfile): SignatureProfileCardConfigActions {
      if (this.cardConfig.actions === undefined) {
        return []
      }
      if (Array.isArray(this.cardConfig.actions)) {
        return this.cardConfig.actions
      }
      return this.cardConfig.actions(profile)
    },
    rowsFiltered(profile: SignatureProfile): Array<SignatureProfileCardRow> {
      return this.rows.filter(row => row.showIf ? row.showIf(profile) : true) ?? []
    },
    loadProfilesForCurrentMethod() {
      this.$store.dispatch('profile/loadSignatureProfilesForMethod',
          {id: this.method.providerTypeId, code: this.method.providerTypeCode});
    },
    dividerActive(index:number,length:number):string{
      return  index < length - 1 ? 'row-divider' : '' ;
    },
    edit(profile: SignatureProfile): void {
      this.$emit('edit', profile);
    },
    removeDialog(profile: SignatureProfile): void {
      this.confirmDialog = true;
      this.confirmDialogMethod = () => {
        this.remove(profile);
      }
      this.dialogTitle = this.$t('profile.signature.signatureDeleteDialog').toString();
      this.dialogSubtitle = this.$t('profile.signature.signatureDeleteDialogSub').toString();

    },
    async remove(profile: SignatureProfile): Promise<void> {
      try {
        this.loading = true;
        if (!profile._links['sef:remove-signing-profile']?.href || !this.method) return
        await this.axios.delete(profile._links['sef:remove-signing-profile'].href);
        this.$store.commit('notification/showMessage',
            {content: this.$t('profile.signature.signatureOnDeleted'), type: "success"})
        this.$store.commit('profile/deleteSignatureProfileForMethod', {
          method: this.method.providerTypeCode,
          profile: profile
        })
      }
      catch (ignore) {
        /**/
      }
      finally {
        this.confirmDialog = false;
        this.loading = false;
      }
    },
    onMove({moved}: { moved: { element: SignatureProfile, oldIndex: number, newIndex: number } }) {
      if (!moved || moved.oldIndex === moved.newIndex) return;

      const profileToUpdate = moved.element;
      profileToUpdate.itemOrder = moved.newIndex + 1;
      for (let i = 0; i < this.areAnyProfilesAvailable.length; i++) {
        this.profiles[i].itemOrder = i + 1;
      }
      this.apiUpdateProfile(profileToUpdate);
    },
    processShowFunc(func: (profile: SignatureProfile) => boolean, profile: SignatureProfile): boolean {
      if (!func) return true;
      return func(profile);
    },
    processRowData(data:SignatureProfileCardRowValue,profile: SignatureProfile): string | undefined{
      let value=this.processValue(data.type, data.value, profile);
      if (value)
        return value;
      if (data.fallback)
        return this.processValue(data.fallback.type, data.fallback.value, profile);
      return undefined;
    },
    boldValue(data:SignatureProfileCardRowValue,profile: SignatureProfile):string{
      let value=this.processValue(data.type, data.value, profile);
      if (!value && data.fallback && data.fallback.bold)
        return 'bold';
      return '';
    },
    processValue(type: SignatureProfileCardRowValueType, value: SignatureProfileCardRowValueContent,
                 profile: SignatureProfile): string | undefined {
      switch (type) {
        case 'function':
          return this.processValueFunc(value as ((profile: SignatureProfile) => string | undefined), profile);
        case 'text':
          return value as string;
        case 'i18nText':
          return this.$t(value as string).toString();
        default:
          return undefined;
      }
    },
    processValueFunc(func: ((profile: SignatureProfile) => string | undefined),
                     profile: SignatureProfile): string | undefined {
      if (!func) return undefined;
      return func(profile);
    },
    isBusy(profile: SignatureProfile): boolean {
      return this.cardConfig.busyState?.isBusy(profile) ?? false
    }
  }
})

<template>
  <v-container class="metadata-view s-scrollbar pa-4" v-resize="onResize"   :style="displayMobile ? 'font-size: 12px' : ''">
    <v-row no-gutters justify="end" class="textSecondary--text" >
      <v-col>
        <h3 v-if="!displayMobile"> {{ $t('docDetail.metadata.title') }} </h3>
        <h4 v-else> {{ $t('docDetail.metadata.title') }} </h4>
      </v-col>
      <v-col style="text-align: right; line-height: normal" >
        <div class="text-tiny">{{ $t('docDetail.metadata.lastChange') }}</div>
        <h4 v-if="displayMobile">{{ formatDate(flow.changedAt, 'd.L.yyyy') }}</h4>
        <h3 v-else>{{ formatDate(flow.changedAt, 'd.L.yyyy') }}</h3>
      </v-col>
    </v-row>
    <div v-for="(step, index) in formatArrayByStepOrder" :key="index" class="card__step" :class="{ 'active': step.active, 'pa-2': displayMobile, 'pa-4': !displayMobile }">
      <div v-for="(rowContent, key) in step.rows" :key="key" class="card__userInformation">
        <MetadataRow :key="rowContent" :row-content="rowContent" :mobile-view="displayMobile" />
      </div>
    </div>
    <div v-if="!draft" :class="{'text-large': !displayMobile, 'text-small': displayMobile}" style="text-align: center">
        {{ hardDueDateLabel }} {{ formatDate(flow.dueDate,'d.L.yyyy HH:mm') }}
    </div>

  </v-container>
</template>

<script>

import MetadataRow from "@/component/workflow/detail/FlowDetailMetadataRow";
import metadataMixin from "@/mixins/metadataMixin";
import {formatDate} from "../../../utils/dateUtils";

export default {
  name: 'MetadataView',
  components: {
    MetadataRow
  },
  mixins: [metadataMixin],

  props: {
    flow: Object,
    displayMobile:Boolean
  },

  data(){
    return {
      elemWidth: undefined
    }
  },

  computed: {
    isOwner(){
      return this.flow._embedded.owner.accountName === this.$store.getters["profile/accountName"] ;
    },
    stepsAsRowContents() {
      let rowContents = [];
      // odeslani flow neni opravdovy krok, ale my ho tak zobrazime
      rowContents.push(this.ownerFirstActionRow(this.flow));
      // transformujeme kroky embedovane ve flow
      let showActiveStep = this.showActiveStep(this.flow);
      let activeStepIndex = this.activeStepIndex(this.flow);
      let transformedSteps = this.flow._embedded.steps.map(
          step => this.stepToRowContent(step, showActiveStep, activeStepIndex, this.isOwner))
          // nezname kroky se mapuji na null - nebudeme je zobrazovat
          .filter(step => step !== null);
      // pokud bylo flow odvolano, vlozime za posledni dokonceny krok novy fiktivni krok zadavatele
      if (this.flow.state === 'recalled')
        transformedSteps.splice(this.getLastActionableStepIndex(this.flow), 0, this.ownerRecallRow(this.flow))
      rowContents.push(...transformedSteps);
      return rowContents;
    },
    //Funkce pro vytvoření polí podle stepOrder
    formatArrayByStepOrder(){
      let formatArray = {};
      let showActiveStep = this.showActiveStep(this.flow);
      let activeStepIndex = this.activeStepIndex(this.flow);
      for(let i = 0; i < this.stepsAsRowContents.length; i++){
        // array řadí vzestupně dle pozitivních integers, potom dle stringů a potom dle znaků.
        // následující kalkulaci jsem přidal aby bylo možno zařadit i krok odesílatele vložený zvlášť (ownerFirstActionRow)
        // se záporným stepOrderem (-1)
        let calculatedOrder = this.stepsAsRowContents[i].stepOrder + 2;
        if(!formatArray[calculatedOrder]) formatArray[calculatedOrder] = {active:showActiveStep && activeStepIndex===this.stepsAsRowContents[i].stepOrder,rows:[]};
        formatArray[calculatedOrder].rows.push(this.stepsAsRowContents[i]);
      }
      return formatArray;
    },
    hardDueDateLabel() {
      let text = " ";
      if (this.flow.dueDateMode === 'SOFT') {
        text = this.$tc('docDetail.metadata.hardDueDateLabelSoft', this.stepsAsRowContents.length - 1);
      }
      else {
        text = this.$tc('docDetail.metadata.hardDueDateLabelHard', this.stepsAsRowContents.length - 1);
      }
      return text;
    },
    draft() {
      return this.flow?.state === 'draft';
    }
  },

  methods: {
    formatDate,
    onResize() {
      this.elemWidth = this.$el.clientWidth;
    }
  }
}
</script>

<style scoped>
.metadata-view {
  object-fit: fill;
  display: flex;
  flex-direction:column;
  gap:16px;
  overflow-x: hidden;
  overflow-y: auto;
  /*noinspection CssUnresolvedCustomProperty*/
  scrollbar-color: var(--v-scrollbar-base) var(--v-backgroundPrimary-base);
}

.card__step {
  border-radius: 4px;
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-backgroundSecondary-base);
  display: flex;
  flex-direction: column;
  gap:8px;
}
.card__step.active{
  /*noinspection CssUnresolvedCustomProperty*/
  border: 3px solid var(--v-secondaryContrast-base);
}
.card__userInformation {
}

</style>

import axios from "@/plugin/vueAxios";
import {BusinessConfig, Category, Locale} from "@/types";

const BUSINESS_CONFIG_PATH = '/business-config';
const CATEGORIES_PATH = `${BUSINESS_CONFIG_PATH}/categories`;

export async function apiCategoryGet(categoryCode: Category['categoryCode']): Promise<Category> {
  const response = await axios.get<Category>(`${CATEGORIES_PATH}/${categoryCode}`);
  return response.data;
}

export async function apiActiveLocalesGet(): Promise<Array<Locale>> {
  const response = await axios.get<BusinessConfig>(BUSINESS_CONFIG_PATH);
  return response.data.activeLocales;
}
<template>
  <div
      class="flow-detail-layout"
      :class="{'fullscreen': fullscreen,
      'mobile': displayTablet || displayMobile,
      'short-screen': !fullscreen && selectedOption === 'file'}"
  >
    <div v-if="flow" class="flow-detail">
      <div v-if="!fullscreen" class="flow-detail__info">
        <!-- Detail header -->
        <FlowDetailHeader
            v-if="!onlineSignature"
            background-color="mutedBackground"
        >
          <div class="px-3 d-flex align-center justify-space-between flex-grow-1" style="width: 100%; max-width: 100%">
            <div class="d-flex align-center " style="gap:8px">
              <!-- Back arrow for mobile only-->
              <div v-if="displayMobile">
                <SIconButton
                    icon="back"
                    variant="primary-muted-accent"
                    @click="goBack"
                />
              </div>

              <SIconSelect
                  v-if="customization.showPageSwitch"
                  v-model="selectedOption"
                  :items="selectOptions"
                  background-color="mainBackground"
              />
            </div>

            <FlowStepVisualisation
                :current-step="flow.currentPhase"
                :flow-state="flow.state"
                :steps="flow._embedded.steps"
                :class="{'page_switch': !customization.showPageSwitch}"
            />
          </div>
        </FlowDetailHeader>

        <div class="flow-info">
          <div class="flow-info__prepend">
            <slot name="infoPrepend" :flow="flow" :step="currentStep"></slot>
          </div>

          <div class="flow-info__content">
            <!-- Flow basic info -->
            <div>
              <div class="flow-info-basic">
                <SIconSelect
                    v-if="onlineSignature && customization.showPageSwitch"
                    v-model="selectedOption"
                    background-color="mainBackground"
                    class="mr-4"
                    :class="{'page-switch__online--mobile': !displayDesktop}"
                    :items="selectOptions"
                />

                <div class="flow-info-basic__title">
                  <div class="d-flex align-center overflow-hidden">
                    <h3
                        v-if="displayMobile"
                        class="text-truncate"
                        :class="{'textTernary--text': !flow.subject}"
                    >
                      {{ flowSubject }}
                    </h3>
                    <h2
                        v-else
                        class="text-truncate"
                        :class="{'textTernary--text': !flow.subject}"
                    >
                      {{ flowSubject }}
                    </h2>
                  </div>
                  <div v-if="customization.showDueDate" class="dueDate textSecondary--text ml-3" style="line-height: normal">
                    <div class="text-tiny">{{dateInfo.info}}</div>
                    <h3 v-if="displayMobile">
                      {{ formatDate(dateInfo.date, 'd.L.yyyy') }}
                    </h3>
                    <h2 v-else>
                      {{ formatDate(dateInfo.date, 'd.L.yyyy') }}
                    </h2>
                  </div>
                  <div class="actionInRow ml-5" v-if="(!customization.showDueDate && !customization.showPageSwitch && !customization.showOwnerInfo) && !displayMobile">
                    <div class="flow-info-actions flex-lg-nowrap" :class="{'flex-sm-row' : displayDesktop}">
                      <!-- Flow owner info -->
                      <UserInfo
                          v-if="customization.showOwnerInfo"
                          small
                          :user="flowOwner"
                      >
                        <STooltip bottom>
                          <template #activator="{ on }">
                    <span
                        v-on="on"
                        class="textTernary--text"
                        style="font-size: 14px"
                    >
                      {{ draft ? flowCreated : flowSubmitted }}
                    </span>
                          </template>

                          <span>{{ $t('docDetail.metadata.submittedAt') }}</span>
                        </STooltip>
                      </UserInfo>

                      <!-- Document actions -->
                      <div class="flow-detail-actions">
                        <FlowDetailActions v-if="displayActionContainer" ref="documentActions"
                                           :document-forbidden="signaturePhaseForbidden"
                                           :documentLoaded="documentLoaded"
                                           :draft="draft"
                                           :flow-object="flow"
                                           :online-signature="onlineSignature"
                                           :profiles="applicableSigningProfiles"
                                           :provider-types="applicableProviderTypes"
                                           :selected-profile="selectedProfile"
                                           :ready-to-be-signed="readyToBeSigned"
                                           :pdf-attachments="documentAttachments"
                                           :locked-by-other-user="documentLockedByOtherUser"
                                           :show-next-step-is-mine="showNextStepIsMine"
                                           :no-suitable-profile="noSuitableProfile"
                                           :external-signing-selected="externalSigningSelected"
                                           :current-step="currentStep"
                                           @auth-link-set="setAuthorizationLink"
                                           @confirm-read="confirmRequiredRead"
                                           @deleteDraft="deleteDraft"
                                           @openDraft="openDraft"
                                           @profile-select="onProfileSelected"
                                           @reason-set-reload="reasonSignSetReloadDocument"
                                           @reject="rejectSingle"
                                           @external-signing-mode="setExternalSigningMode"
                                           @open-upload-dialog="externalSigningDialog"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- External signature selected info -->
              <div
                  v-if="externalSigningSelected"
                  class="flow-info-error"
              >
                <v-badge color="info" inline left>
                  <span class="ml-1 textTernary--text">{{ $t('docDetail.messages.externalSignatureMode')}}</span>
                </v-badge>
              </div>

              <!-- Error info -->
              <div
                  v-else-if="inError"
                  class="flow-info-error"
              >
                <v-badge color="error" inline left>
                  <span class="ml-1 textTernary--text">{{ errorMessage }}</span>
                </v-badge>
              </div>
            </div>

            <div class="flow-info-actions flex-lg-nowrap"
                 :class="{'flex-sm-row' : displayDesktop}"
                 v-if="selectedOption !== 'fileMetadata' && (customization.showDueDate || customization.showPageSwitch || customization.showOwnerInfo || displayMobile)">
              <!-- Flow owner info -->
              <UserInfo
                  v-if="customization.showOwnerInfo"
                  small
                  :user="flowOwner"
              >
                <STooltip bottom>
                  <template #activator="{ on }">
                    <span
                        v-on="on"
                        class="textTernary--text"
                        style="font-size: 14px"
                    >
                      {{ draft ? flowCreated : flowSubmitted }}
                    </span>
                  </template>

                  <span>{{ $t('docDetail.metadata.submittedAt') }}</span>
                </STooltip>
              </UserInfo>

              <div class="flow-detail-actions">
                <!-- Document actions -->
                <FlowDetailActions v-if="displayActionContainer" ref="documentActions"
                                   :document-forbidden="signaturePhaseForbidden"
                                   :documentLoaded="documentLoaded"
                                   :draft="draft"
                                   :flow-object="flow"
                                   :online-signature="onlineSignature"
                                   :profiles="applicableSigningProfiles"
                                   :provider-types="applicableProviderTypes"
                                   :selected-profile="selectedProfile"
                                   :ready-to-be-signed="readyToBeSigned"
                                   :pdf-attachments="documentAttachments"
                                   :locked-by-other-user="documentLockedByOtherUser"
                                   :show-next-step-is-mine="showNextStepIsMine"
                                   :no-suitable-profile="noSuitableProfile"
                                   :external-signing-selected="externalSigningSelected"
                                   :current-step="currentStep"
                                   @auth-link-set="setAuthorizationLink"
                                   @confirm-read="confirmRequiredRead"
                                   @deleteDraft="deleteDraft"
                                   @openDraft="openDraft"
                                   @profile-select="onProfileSelected"
                                   @reason-set-reload="reasonSignSetReloadDocument"
                                   @reject="rejectSingle"
                                   @external-signing-mode="setExternalSigningMode"
                                   @open-upload-dialog="externalSigningDialog"
                />
              </div>
            </div>
            <p v-if="rejectionNote" class="flow-detail__note">{{$t('docDetail.noteClarification.rejected') + ': ' + rejectionNote}}</p>
            <p v-else-if="recallNote" class="flow-detail__note">{{$t('docDetail.noteClarification.recalled') + ': ' + recallNote}}</p>
            <p v-else-if="expirationNote" class="flow-detail__note">{{ expirationNote}}</p> </div>
        </div>
      </div>
      <div v-if="selectedOption === 'file' && (signaturePhaseForbidden || !flowDocument || !flowDocumentName || !documentDataAvailable)">
        <v-card
            v-if="signaturePhaseForbidden"
            class="forbidden-card"
            color="backgroundSecondary"
            :elevation="0"
        >
          <v-card-title>{{ $t('docDetail.documentForbiddenTitle') }}</v-card-title>
          <v-card-text>{{ $t('docDetail.documentForbiddenSubtitle') }}</v-card-text>
        </v-card>

        <v-card
            v-else-if="!flowDocument || !flowDocumentName || !documentDataAvailable"
            class="forbidden-card"
            color="backgroundSecondary"
            :elevation="0"
        >
          <v-card-title>{{ $t('docDetail.documentLoadingFailedTitle') }}</v-card-title>
          <v-card-text>{{ $t('docDetail.documentLoadingFailedSubtitle') }}</v-card-text>
        </v-card>
      </div>
      <!--  If flow is ready to be signed by user-->
      <div v-else-if="selectedOption === 'file' && !signaturePhaseForbidden" class="pdf-view-container">
        <PDFViewer
            :custom-tools="pdfCustomTools"
            :flow="flow"
            :has-flow-detail-action="hasFlowDetailAction"
            :flow-step-current="currentStep"
            :fullscreen="fullscreen"
            ref="pdfViewer"
            :signature-annotations="pdfSignatureAnnotations"
            :signature-annotations-readonly="pdfSignatureAnnotationsReadonly"
            @document-attachments="pdfDocumentAttachments = $event"
            @document-error="onDocumentDataError"
            @document-loaded="documentLoaded = true"
            @fullscreen-toggle="fullscreen = !fullscreen"
            @sign="sign"
            @signature-field-added="onSignatureFieldAdded"
            @signature-field-removed="onSignatureFieldRemoved"
        >
          <template #document-actions>
            <FlowDetailActions
                v-if="displayActionContainer"
                :document-forbidden="signaturePhaseForbidden"
                :document-loaded="documentLoaded"
                :draft="draft"
                :flow-object="flow"
                :online-signature="onlineSignature"
                :profiles="applicableSigningProfiles"
                :provider-types="applicableProviderTypes"
                :selected-profile="selectedProfile"
                :ready-to-be-signed="readyToBeSigned"
                :pdf-attachments="documentAttachments"
                :locked-by-other-user="documentLockedByOtherUser"
                :show-next-step-is-mine="showNextStepIsMine"
                :no-suitable-profile="noSuitableProfile"
                :external-signing-selected="externalSigningSelected"
                :current-step="currentStep"
                ref="documentActions"
                @deleteDraft="deleteDraft"
                @openDraft="openDraft"
                @reject="rejectSingle"
                @auth-link-set="setAuthorizationLink"
                @profile-select="onProfileSelected"
                @reason-set-reload="reasonSignSetReloadDocument"
                @confirm-read="confirmRequiredRead"
                @external-signing-mode="setExternalSigningMode"
                @open-upload-dialog="externalSigningDialog"
            />
          </template>
        </PDFViewer>
      </div>

      <MetadataView v-else-if="selectedOption === 'fileMetadata'" :flow="this.flow" :display-mobile="displayMobile || displayTablet"/>
      <FlowDetailAuditView v-else-if="selectedOption === 'logs'" :flow="this.flow"/>
    </div>

    <!--TODO: Inspect the missing "!mobile" -->
    <div v-else-if="!mobile && !standalone" class="text-center empty">
      <h1 class="textTernary--text">{{ $t('docDetail.empty') }}</h1>
    </div>

    <BatchRejectionDialog
        v-model="rejectionDialogVisible"
        :batch="batchRejectionDialogVisible"
        :flows="flowsToReject"
        :recall="recallAvailable"
        @reject-successful="onSuccessfulReject"
    />

    <NoSignatureProfileDialog
        v-model="noSignatureDialogVisible"
        :applicable-provider-types="applicableProviderTypes"
        :dialog-type="noSignatureDialogType"
        :online-signature="onlineSignature"
        :external-signing="isExternalSigningAvailable"
    />

    <ThirdPersonSignatureDialog
        v-if="currentStep"
        v-model="thirdPersonDialogVisible"
        :name="currentStep.thirdPersonName"
        @sign="signByThirdPerson"
    />

    <AdHocSignatureDialog
        v-if="currentStep"
        v-model="adhocSigningDialogVisible"
        :imageMethods="imageInputMethods"
        @sign="signByAdHoc"
    />

    <ExternalSigningDialog
        v-model="externalSigningDialogVisible"
        :flow="flow"
        :current-step="currentStep"
        @processing-document-completed="completedExternalSigning"
    />
  </div>
</template>

<script>
import authorizationMixin from "@/mixins/authorizationMixin";
import avatarMixin from "@/mixins/avatarMixin";
import displayMixin from "@/mixins/displayMixin";
import mutationMixin from "@/mixins/mutationMixin";
import signingProfilesMixin from "@/mixins/signingProfilesMixin";
import validationMixin from "@/mixins/validationMixin";
import NoSignatureProfileDialog from "@/component/dialog/NoSignatureProfileDialog";
import ThirdPersonSignatureDialog from "@/component/dialog/ThirdPersonSignatureDialog";
import PDFViewer from "@/component/pdf/PDFViewer.vue";
import SIconButton from "@/component/ui/buttons/SIconButton";
import SIconSelect from "@/component/ui/form/SIconSelect";
import STooltip from "@/component/ui/STooltip";
import BatchRejectionDialog from "@/component/workflow/batch/BatchRejectionDialog";
import FlowDetailActions from "@/component/workflow/detail/FlowDetailActions";
import FlowDetailAuditView from "@/component/workflow/detail/audit/FlowDetailAuditView";
import FlowDetailHeader from "@/component/workflow/detail/FlowDetailHeader";
import MetadataView from "@/component/workflow/detail/FlowDetailMetadataView";
import FlowStepVisualisation from "@/component/workflow/detail/FlowStepVisualisation";
import UserInfo from "@/component/UserInfo";
import {apiDocumentSign} from "@/services/api/batchesApi";
import {
  AuthorizationType,
  FlowStepState,
  PdfSignatureAnnotations,
  ReportStateId,
  ReportStateType,
  VisualFieldUsage,
} from "@/types";
import {SIGNATURE_PHASE} from "@/utils/constants";
import {getStepActive} from "@/utils/flowUtils";
import {redirectTo} from "@/utils/utils";
import AdHocSignatureDialog from "@/component/dialog/AdHocSignatureDialog";
import {formatDate} from "@/utils/dateUtils";
import ExternalSigningDialog from "@/component/dialog/ExternalSigningDialog";

export default {
  name: 'FlowDetail',
  mixins: [authorizationMixin, avatarMixin, displayMixin, mutationMixin, signingProfilesMixin,
    validationMixin],
  components: {
    ExternalSigningDialog,
    AdHocSignatureDialog,
    BatchRejectionDialog,
    FlowDetailActions,
    FlowDetailAuditView,
    FlowDetailHeader,
    FlowStepVisualisation,
    MetadataView,
    NoSignatureProfileDialog,
    PDFViewer,
    SIconButton,
    SIconSelect,
    STooltip,
    ThirdPersonSignatureDialog,
    UserInfo
  },

  props: {
    flowProp: Object,
    flowUrl: String,
    onlineSignature: Boolean,
    standalone: Boolean,
    customization: {
      type: Object,
      default: function () {
        return {
          showPageSwitch: true,
          showDueDate: true,
          showOwnerInfo: true
        }
      }
    },
  },

  data() {
    return {
      animationShow: false,
      batch: undefined,
      batchPanelExpanded: false,
      clientSigningInProgressTimeoutId: undefined,
      clientSigningUnknown: false,
      clientSigningUri: undefined,
      documentDataAvailable: false,
      documentLoaded: false,
      documentRead: false,
      documentSignatureProcessing: false,
      documentSigned: false,
      flow: undefined,
      flowLogsIncluded: undefined,
      fullscreen: false,
      loading: false,
      selectedOption: 'file',
      selectedProfile: undefined,
      signatureInProgressTimeoutId: undefined,
      noSignatureDialogType: null,
      inputsRequired: undefined,
      pdfDocumentAttachments: undefined,
      externalSigningSelected:false,

      /* Dialogová okna */
      noSignatureDialogVisible: false,
      batchRejectionDialogVisible: false,
      singleRejectionDialogVisible: false,
      thirdPersonDialogVisible: false,
      adhocSigningDialogVisible: false,
      externalSigningDialogVisible: false,

      showNextStepIsMine: false
    }
  },

  computed: {
    applicableProviderTypes() {
      return this.currentStep?._embedded.applicableSignatureProviderTypes;
    },
    applicableSigningProfiles: {
      get() {
        return this.currentStep?._embedded.applicableSigningProfiles;
      },
      set(value) {
        if (this.currentStep) {
          this.currentStep = {
            ...this.currentStep,
            _embedded: {
              applicableSigningProfiles: value
            }
          };
        }
      }
    },
    batchPanelVisible() {
      return this.$store.getters['workflow/batchMode'];
    },
    clientSigningInProgress: {
      get() {
        return this.$store.getters['notification/clientSigningInProgress'];
      },
      set(value) {
        this.$store.commit('notification/setClientSigningInProgress', value);
      }
    },
    currentStep: {
      get() {
        if (!this.flow || this.draft)
          return undefined;

        return getStepActive(this.flow);
      },
      set(value) {
        const currentStepOrder = this.flow.currentPhase;
        const myActiveStepId = this.flow.myActiveStepId;
        const index = this.flow._embedded.steps.findIndex(
            step => step.stepOrder === currentStepOrder || step.stepId === myActiveStepId);
        if (index > -1)
          this.$set(this.flow._embedded.steps, index, value);
      }
    },
    displayActionContainer() {
      return (!this.disabled || this.signaturePhaseInProgress || this.signaturePhaseSuccess || this.onlineSignature)
          && this.selectedOption === 'file'
    },
    documentFieldAssigned() {
      /* Podpisové pole přiřazené přímo tvůrcem workflow. */
      if (!this.currentStep)
        return undefined;

      return this.documentFields.find(field => field.stepId === this.currentStep.stepId && !field.setBySigner);
    },
    documentFieldUsed() {
      /* Podpisové pole použité/vybrané uživatelem. */
      if (!this.currentStep)
        return undefined;

      return this.documentFields.find(field => field.stepId === this.currentStep.stepId && field.setBySigner);
    },
    documentFields: {
      get() {
        return this.flow?._embedded?.documentFields ?? [];
      },
      set(value) {
        this.flow._embedded.documentFields = value;
      }
    },
    documentLockedByOtherUser() {
      return !!this.flow?.lockedByStepId && this.flow.lockedByStepId !== this.flow.myActiveStepId;
    },
    requiredRead() {
      return this.currentStep?.readFlag === 'REQUIRED_AND_UNREAD';
    },
    disabled() {
      if (!this.flow)
        return false;

      const disabled = this.$store.getters['workflow/flowDisabled'];
      const index = disabled.findIndex(d => d.flowId === this.flow.flowId);
      return index > -1;
    },
    draft() {
      return this.flow?.state === 'draft';
    },
    errorMessage() {
      if (!this.inError)
        return undefined;

      let errorMessage = this.$store.getters['workflow/flowErrorMessagesMap'][this.flow.flowId];

      if (errorMessage === undefined || errorMessage === null) {
        const reportRow = this.getReportRowForCurrentStep(this.currentStep?._embedded?.batch)
        if (this.currentStep.lastError) {
          errorMessage = this.currentStep.lastError;
        }
        else if (reportRow?.reportStateDescription) {
          errorMessage = reportRow.reportStateDescription;
        }
        else if (reportRow?.reportStateName) {
          errorMessage = reportRow.reportStateName;
        }
        else {
          errorMessage = this.$t('common.errors.general.short');
        }
      }
      return errorMessage;
    },
    flowCreated() {
      return this.flow?.createdAt ? formatDate(this.flow.createdAt,'d.L.yyyy') : undefined;
    },
    flowSubmitted() {
      return this.flow?.submittedAt ? formatDate(this.flow.submittedAt, 'd.L.yyyy') : undefined;
    },
    flowDocument() {
      return this.flow?._embedded?.document;
    },
    flowDocumentName() {
      return this.flowDocument?.documentName;
    },
    flowDocumentValidation() {
      return !!this.flowDocument?._links?.['sef:validate-doc'];
    },
    flowOwner() {
      return this.flow?._embedded.owner;
    },
    flowSubject() {
      return this.flow?.subject ?? this.$t('docList.unnamedDraft');
    },
    flowsToReject() {
      if (this.singleRejectionDialogVisible)
        return [this.$store.getters['workflow/flowActive']];
      else
        return this.$store.getters['workflow/flowSelected'];
    },
    hasFlowDetailAction() {
      return this.draft || this.hasFlowAttachments || this.flow.note || this.flow.state === 'active';
    },
    hasFlowAttachments() {
      return this.flow?.enclosuresCount > 0 || this.pdfAttachments?.length;
    },
    inError() {
      if (!this.flow || this.signaturePhaseSuccess || this.signaturePhaseNone)
        return false;

      if (this.currentStep?.state === 'error')
        return true;

      const errored = this.$store.getters['workflow/flowInError'];
      const index = errored.findIndex(e => e.flowId === this.flow.flowId);
      return index > -1 || this.signaturePhase === SIGNATURE_PHASE.ERROR;
    },
    documentAttachments() {
      const loadedPdfAttachments = this.pdfDocumentAttachments && Object.values(this.pdfDocumentAttachments);
      if (this.documentLoaded) return loadedPdfAttachments ?? [];
      return null;
    },
    pdfCustomTools() {
      return [
        {
          callback: () => {
            this.validateDocument(this.flowDocument);
          },
          icon: 'pdfValidate',
          textKey: 'pdfViewer.actions.validate',
          visible: this.flowDocumentValidation,
        }
      ];
    },
    pdfSignatureAnnotations() {
      // added authorizationPhase condition because of empty applicableSigningProfiles array in currentStep
      return !this.externalSigningSelected && this.documentLoaded && this.readyToBeSigned && !this.documentSigned && (this.correctProfileSelected || this.authorizationPhase) && !this.requiredRead ?
          PdfSignatureAnnotations.ALL : PdfSignatureAnnotations.NONE;
    },
    pdfSignatureAnnotationsReadonly() {
      return (this.currentStep && this.currentStep.state === FlowStepState.PROCESSING) ||
          this.documentSignatureProcessing;
    },
    noSuitableProfile() {
      return !this.areAnyProfilesAvailable(this.applicableSigningProfiles);
    },
    noSelectedProfile() {
      return !this.isValidProfileSelected(this.selectedProfile, this.applicableSigningProfiles);
    },
    correctProfileSelected() {
      return !(this.noSuitableProfile || this.noSelectedProfile);
    },
    readyToBeSigned() {
      if (!this.flow || this.draft || !this.currentStep)
        return false;

      return this.currentStep._embedded.account.accountName === this.$store.getters['profile/accountName'];
    },
    recallAvailable() {
      return this.flow?.state === 'active' && this.flowOwner?.accountName ===
          this.$store.getters['profile/accountName'];
    },
    recallNote() {
      if (!this.flow || this.flow.state !== 'recalled')
        return undefined;

      return this.flow.recallNote;
    },
    rejectionNote() {
      if (!this.flow || this.flow.state !== 'rejected')
        return undefined;

      for (const step of this.flow._embedded.steps) {
        if (step.state === 'rejected')
          return step.rejectionNote;
      }

      return undefined;
    },
    expirationNote() {
      if (!this.flow || this.flow.state !== 'expired')
        return undefined;

      return this.$t('docDetail.metadata.expiredStep');
    },
    rejectionDialogVisible: {
      get() {
        return this.singleRejectionDialogVisible || this.batchRejectionDialogVisible;
      },
      set(value) {
        this.singleRejectionDialogVisible = value;
        this.batchRejectionDialogVisible = value;
      }
    },
    selectOptions() {
      return [
        { text: this.$t('workflow.pageSwitch.preview').toString(), icon: 'file' },
        { text: this.$t('workflow.pageSwitch.metadata').toString(), icon: 'fileMetadata' },
        { text: this.$t('workflow.pageSwitch.logs').toString(), icon: 'logs' },
      ]
    },
    signaturePhase() {
      return this.$store.getters['signature/signaturePhase'];
    },
    signaturePhaseForbidden() {
      return this.signaturePhase === SIGNATURE_PHASE.FORBIDDEN;
    },
    signaturePhaseInProgress() {
      return this.signaturePhase === SIGNATURE_PHASE.PROCESSING;
    },
    signaturePhaseNone() {
      return this.signaturePhase === SIGNATURE_PHASE.NONE;
    },
    signaturePhaseSuccess() {
      return this.signaturePhase === SIGNATURE_PHASE.SUCCESS;
    },
    authorizationPhase() {
      return this.signaturePhase === SIGNATURE_PHASE.AUTHORIZATION;
    },
    thirdPersonSignature() {
      if (!this.currentStep)
        return false;

      return this.currentStep.requestedSignatureLevel.thirdPerson;
    },
    dateInfo() {
      if (this.flow.state === 'draft')
        return {date: this.flow.createdAt, info: this.$t('docDetail.metadata.created')}
      if (this.flow.state === 'finished')
        return {date: this.flow.changedAt, info: this.$t('docDetail.metadata.finished')}
      if (['recalled', 'rejected', 'canceled', 'expired'].includes(this.flow.state))
        return {date: this.flow.changedAt, info: this.$t('docDetail.metadata.cancelled')}

      let date = this.flow.stepDueDate ? this.flow.stepDueDate :
          this.flow.dueDate ? this.flow.dueDate : this.flow.createdAt;
      return {date: date, info: this.$t('docDetail.metadata.signUntil')}

    },
    imageInputMethods() {
      return this.$store.getters["config/imageInputMethods"];
    },
    // Poznámka k paralelnímu podepisování -> spolehá se na to že aktivní uživatel bude první v tom kroku (Přihlášený uživatel)
    isNextStepMine() {
      const myStepIndex = this.flow?._embedded.steps.findIndex(step => step.stepId === this.flow?.myActiveStepId) ?? -1;
      if (myStepIndex < 0) return false;
      const currentStepUser = this.flow?._embedded?.steps[myStepIndex]?._embedded?.account?.accountName;
      const nextStepUser = this.flow?._embedded?.steps[myStepIndex + 1]?._embedded?.account?.accountName;
      return currentStepUser === nextStepUser
    },
    isExternalSigningAvailable() {
      return this.currentStep?._links["sef:external-signature"]?.href;
    }
  },

  watch: {
    batchPanelVisible(value) {
      this.batchPanelExpanded = value;
    },
    clientSigningInProgress(value) {
      if (!value) {
        /*
         * Zavření dialogového okna informujícího uživatele o probíhající komunikaci s klientským zařízením (aplikací
         * OBELISK Signing Portal Client). Zavření dialogového okna nezruší proces, který na pozadí kontroluje stav
         * zpracování podpisu na klientském zařízení.
         */
        window.clearTimeout(this.clientSigningInProgressTimeoutId);
        this.clientSigningInProgressTimeoutId = undefined;

        if (this.signaturePhase !== SIGNATURE_PHASE.SUCCESS) {
          this.clientSigningUnknown = true;
          this.$store.commit('signature/setSignaturePhase', {
            phase: SIGNATURE_PHASE.AUTHORIZATION,
            abandonAuthorizationLink: this.getReportRowForCurrentStep(
                this.batch)?._links['sef:abandon-authorization'].href,
            authorizationType: this.getReportRowForCurrentStep(this.batch)?.authorizationType,
            flow: this.flow,
            link: this.clientSigningUri,
            report: this.batch
          });
        }
      }
    },
    // Hotfix OBPK-1010
    currentStep() {
      if (this.signaturePhase !== SIGNATURE_PHASE.ERROR) {
        this.initSignatureProcess();
      }
    },
    flowProp() {
      this.reset();
      this.loadFlow();
      /*
       * V případě online podpisu (flowProp se používá pouze v online podpisu) se ručně nastavuje příznak načtení dat
       * PDF dokumentu, protože v případě online podpisu se pouze flow aktualizuje, tedy načítá se stále flow se stejným
       * flowId, a v takovém případě se nepřenačítají data PDF dokumentu.
       */
      this.documentLoaded = true;
    },
    flowUrl() {
      if (this.flow !== undefined && this.readyToBeSigned && !!this.$refs.documentActions) {
        this.$refs.documentActions.clear();
      }
      this.showNextStepIsMine = false;
      this.reset();
      this.loadFlow();
    },
    selectedOption() {
      // v pripade auditniho zaznamu nacteme flow doplnene o logy
      if (this.selectedOption === 'logs' && !this.flowLogsIncluded)
        this.loadFlow(true);
    }
  },

  created() {
    this.subscribe(async (mutation) => {
      if (mutation.type === 'signature/addSignatureField') {
        this.addSignatureField();
      }
      if (mutation.type === 'signature/cancelAuthorization') {
        await this.cancelAuthorization(mutation.payload.link);
      }
      if (mutation.type === 'signature/cardAuthorization') {
        window.clearTimeout(this.signatureInProgressTimeoutId);
        this.signatureInProgressTimeoutId = undefined;

        this.clientSigningUnknown = false;
        this.clientSigningUri = undefined;

        await this.authorizationCardHandler(mutation.payload.flow, mutation.payload.report, 1);
      }
      if (mutation.type === 'signature/validateProfileAndSign') {
        await this.sign();
      }
      if (mutation.type === 'workflow/clearDetail') {
        this.reset();
        this.flow = undefined;
      }
      if (mutation.type === 'workflow/updateDetail') {
        // TODO: Obsluha události pouze dočasně, detail dokumentu by měl reagovat na změny flow provedené skrze store - asi až v nové verzi detailu
        await this.loadFlow();
      }
      if (mutation.type === 'workflow/updateFlow') {
        await this.loadFlow();
      }
      if (mutation.type === 'workflow/setBatchMode'){
        if(mutation.payload.batchMode){
          this.selectedOption = 'file';
        }
      }
    })
  },

  async mounted() {
    await this.loadFlow();

    if (!this.initSignatureInProgress(this.currentStep)) {
      this.initSignatureProcess();
    }
  },

  methods: {
    formatDate,
    // Hotfix OBPK-815
    async setAuthorizationLink() {
      await this.loadFlow();

      if (!this.initSignatureInProgress(this.currentStep)) {
        this.initSignatureProcess();
      }
    },
    setExternalSigningMode(value){
      this.externalSigningSelected=value;
    },
    batchModeSwap() {
      this.animationShow = true;
      this.batchPanelExpanded = !this.batchPanelExpanded;
      setTimeout(() => {
        this.animationShow = false;
      }, 301);
    },
    async confirmRequiredRead() {
      const setReadFlagLink = this.currentStep?._links['sef:step-set-read-flag']?.href;
      try {
        if (!setReadFlagLink) {
          console.error(`The link to set the read flag is not available for current step ${this.currentStep?.stepId}`);
        }
        else {
          await this.axios.put(setReadFlagLink);
          this.documentRead = true;
          this.initSignatureProcess();
          await this.loadFlow();
        }
      }
      catch (e) {
        console.error(`An error occurred while setting read flag for current step ${this.currentStep?.stepId}`, e);
      }
    },
    goBack() {
      this.$emit('hide');
    },
    async loadFlow(includeLogs = false) {
      if (!this.flowUrl && this.flowProp) {
        if(includeLogs){
          // Flow url must be set to load logs in online signature
          this.flowUrl = this.flowProp._links.self.href;
        } else {
          this.flow = this.flowProp;
          this.onFlowLoaded();
        }
        return;
      }
      if (!this.flowUrl) return;
      this.loading = true;

      includeLogs = includeLogs || this.selectedOption === 'logs';
      try {
        const response = await this.axios.get(this.flowUrl + (includeLogs ? '?includeLogs=true' : ''));
        if (this.flowUrl && response.data._links.self.href !== this.flowUrl) return;
        this.flow = response.data;
        this.flowLogsIncluded = includeLogs;
        this.onFlowLoaded();
      }
      catch (ignore) {
        // Ignore
      }
      finally {
        this.loading = false;
      }
    },
    onDocumentDataError(event) {
      this.documentDataAvailable = false;

      if (event.status === 403) {
        this.$store.commit('signature/setSignaturePhase', { phase: SIGNATURE_PHASE.FORBIDDEN });
      }
    },
    onFlowLoaded() {
      this.documentDataAvailable = true;
      if (this.readyToBeSigned) {
        this.selectedProfile = this.currentStep._embedded.signingProfile;
        this.inputsRequired = this.currentStep._embedded.signingProfile.inputs.required;
        this.setSignaturePhaseInCurrentStep();
      }
      else {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.NONE});
      }
    },
    async onProfileSelected(profile) {
      if (!profile) return;
      try {
        const response = await this.axios.put(this.currentStep._links["sef:step-set-signing-profile"].href, profile);
        const docFieldsResponse = await this.axios.get('/workflows/' + this.flow.flowId + '/document-fields');

        const oldStep = this.currentStep;

        this.documentFields = docFieldsResponse.data.documentFields;
        this.selectedProfile = response.data._embedded.signingProfile;
        this.inputsRequired = response.data._embedded.signingProfile.inputs.required;
        this.currentStep = response.data;

        // If not set, remember the old batch
        if(!this.currentStep._embedded.batch){
          this.currentStep._embedded.batch = oldStep._embedded.batch;
        }

        // HOTFIX, backend by mel vracet step vc. stepOrder a mozna i dalsich
        this.currentStep.stepOrder = oldStep.stepOrder;
        this.currentStep.dueDate = oldStep.dueDate;
      }
          // eslint-disable-next-line no-empty
      catch (ignore) {
      }
    },
    onSuccessfulReject() {
      this.rejectionDialogVisible = false;
      this.loadFlow();
    },
    rejectBatch() {
      this.batchRejectionDialogVisible = true;
    },
    rejectSingle() {
      if (this.onlineSignature) {
        this.$emit('online-reject')
      }
      else {
        this.singleRejectionDialogVisible = true;
      }
    },
    reset() {
      this.batch = undefined;
      this.clientSigningUnknown = false;
      this.clientSigningUri = undefined;
      // TODO: Dostupnost dat dokumentu by se měla také resetovat, ale detail dokumentu na to není nyní připraven - v GUI problikává chybová hláška a náhled PDF
      // this.documentDataAvailable = false;
      this.flow = undefined;

      this.documentLoaded = false;
      this.documentRead = false;
      this.documentSigned = false;
      this.documentSignatureProcessing = false;
      this.externalSigningSelected = false;

      window.clearTimeout(this.signatureInProgressTimeoutId);
      this.signatureInProgressTimeoutId = undefined;
    },
    setSignaturePhaseInCurrentStep() {
      if (this.currentStep.state === 'processing') {
        this.$store.commit('signature/setSignaturePhase',
            {phase: SIGNATURE_PHASE.PROCESSING, batch: this.currentStep._embedded.batch});
      }
      else if (this.currentStep.state === 'error') {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.ERROR});
      }
      else if (this.currentStep.state === 'finished') {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.SUCCESS});
      }
      else {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.NONE});
      }
    },
    /*
     * Koncept
     */
    async deleteDraft() {
      if (!this.flow._links["sef:delete-flow-draft"].href) return;
      try {
        const draft = this.flow;
        await this.axios.delete(draft._links["sef:delete-flow-draft"].href);
        this.$store.commit("workflow/draftDeleted", {flow: draft});
        this.$store.commit("notification/showMessage",
            {content: this.$t('docDetail.actions.deleteSuccess'), type: 'success'});
        this.$store.commit('navigation/refreshCounters');
        this.$emit('hide');
      }
      catch (e) {
        this.$store.commit("notification/showMessage",
            {content: this.$t('docDetail.actions.deleteFailed'), type: 'error'})
      }
    },
    openDraft() {
      this.$store.commit('workflow/openDraft', {draft: this.flow});
    },
    /*
     * Podpisová pole
     */
    addSignatureField() {
      // TODO: Stejné podmínky jako v metodě "sign"
      if (this.noSuitableProfile) {
        this.showNoSignatureDialog('noSuitableProfile');
      }
      else if (this.noSelectedProfile) {
        this.showNoSignatureDialog('noSelectedProfile');
      }
      else {
        this.$refs.pdfViewer.addSignatureField();
      }
    },
    async onSignatureFieldAdded() {
      const docFieldsResponse = await this.axios.get('/workflows/' + this.flow.flowId + '/document-fields');
      this.documentFields = docFieldsResponse.data.documentFields;
      this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.SIGN});
    },
    async onSignatureFieldRemoved() {
      if ([VisualFieldUsage.REQUIRED_ANY, VisualFieldUsage.REQUIRED_SP].includes(this.currentStep?.visualFieldUsage) &&
          this.signaturePhase === SIGNATURE_PHASE.SIGN) {
        this.$store.commit('signature/setSignaturePhase', { phase: SIGNATURE_PHASE.SIGNATURE_FIELD });
      }
      // Hotfix OBPK-849
      else if ([VisualFieldUsage.REQUIRED_ANY, VisualFieldUsage.REQUIRED_SP].includes(this.currentStep?.visualFieldUsage) &&
          this.signaturePhase === SIGNATURE_PHASE.ERROR) {
        await this.loadFlow();
      }
    },
    /*
     * Podpis dokumentu
     */
    initSignatureProcess() {
      if (this.requiredRead && !this.documentRead) {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.REQUIRED_READ});
      }
      else if ([VisualFieldUsage.REQUIRED_ANY, VisualFieldUsage.REQUIRED_SP].includes(
              this.currentStep?.visualFieldUsage) &&
          !this.documentFieldAssigned && !this.documentFieldUsed) {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.SIGNATURE_FIELD});
        this.documentSignatureProcessing = false;
      }
      else {
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.SIGN});
      }
    },
    initSignatureInProgress(currentStep) {
      if (currentStep) {
        this.batch = currentStep._embedded.batch;
        if (currentStep.state === 'processing' && this.batch) {
          if (!this.batch.bulkMode) {
            // if card signature
            const reportRow = this.getReportRowForCurrentStep(this.batch);
            if (!reportRow) {
              console.warn('Failed to find reportrow for current step')
              return false;
            }
            if (reportRow.reportStateType === ReportStateType.AUTHORIZATION && reportRow.authorizationType ===
                AuthorizationType.CLIENT_DEVICE) {
              this.$store.commit('signature/setSignaturePhase', {
                phase: SIGNATURE_PHASE.AUTHORIZATION,
                abandonAuthorizationLink: reportRow._links['sef:abandon-authorization'].href,
                authorizationType: reportRow.authorizationType,
                flow: this.flow,
                report: this.batch
              });

              return true;
            }
          }
          else {
            this.$store.commit('signature/setSignaturePhase', { phase: SIGNATURE_PHASE.BULK_MODE });
            return true;
          }
        }
      }

      if (this.batch) {
        this.batchReportHandler(this.flow, this.batch, 1);
        return true;
      }

      return false;
    },
    async processSignature(signingTextName = undefined, signatureImage = undefined) {
      try {
        this.documentSignatureProcessing = true;
        const batch = await apiDocumentSign(this.currentStep.stepId, signatureImage, signingTextName);
        this.$store.commit('signature/setSignaturePhase', { phase: SIGNATURE_PHASE.PROCESSING, batch: batch });
        await this.batchReportHandler(this.flow, batch, 1);
      }
      catch (e) {
        console.error(`An error occurred while signing the document for current step ${this.currentStep?.stepId}`, e);
      }
    },
    showNoSignatureDialog(dialogType) {
      this.noSignatureDialogType = dialogType;
      this.noSignatureDialogVisible = true;
    },
    async sign() {
      // TODO: Stejné podmínky jako v metodě "addSignatureField"
      if (this.noSuitableProfile) {
        this.showNoSignatureDialog('noSuitableProfile');
      }
      else if (this.noSelectedProfile) {
        this.showNoSignatureDialog('noSelectedProfile');
      }
      else if (this.signaturePhase === SIGNATURE_PHASE.SIGN || this.signaturePhase === SIGNATURE_PHASE.ERROR) {
        if (this.thirdPersonSignature) {
          this.thirdPersonDialogVisible = true;
        }
        else if (this.inputsRequired !== undefined && this.inputsRequired.length > 0 && this.documentFields.length >
            0) {
          if (this.signaturePhase === SIGNATURE_PHASE.SIGN || this.signaturePhase === SIGNATURE_PHASE.ERROR) {
            this.adhocSigningDialogVisible = true;
          }
        }
        else {
          if (this.documentFields.find(field => field.stepId === this.currentStep.stepId) &&
              [VisualFieldUsage.REQUIRED_SP, VisualFieldUsage.REQUIRED_ANY].includes(
                  this.currentStep.visualFieldUsage)) {
            await this.processSignature()
          }
          else if ([VisualFieldUsage.NOT_SUPPORTED, VisualFieldUsage.OPTIONAL_ALL].includes(
              this.currentStep.visualFieldUsage)) {
            await this.processSignature();
          }
          else {
            this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.SIGNATURE_FIELD});
          }
        }
      }
    },
    async signByThirdPerson({ name, image }) {
      await this.processSignature(name, image);
    },
    async signByAdHoc({image}) {
      await this.processSignature(undefined, image);
    },
    /*
     * Zpracování reportu podpisu dokumentu
     */
    async batchReportHandler(flow, batch, requestNumber) {
      this.batch = batch;

      const reportRow = this.getReportRowForCurrentStep(batch);
      if (!reportRow) {
        console.warn('Failed to find reportrow for current step')
        return
      }
      const reportStateId = reportRow.reportStateId;
      const reportStateType = reportRow.reportStateType;

      if (reportStateType === ReportStateType.FINISHED) {
        await this.signatureSuccessHandler(flow);
      }
      else if (reportStateType === ReportStateType.AUTHORIZATION) {
        switch (reportRow.authorizationType) {
          case AuthorizationType.CLIENT_DEVICE:
            await this.authorizationCardHandler(flow, batch, requestNumber);
            break;
          case AuthorizationType.AUTHORIZATION_SERVER:
            await this.authorizationExternalHandler(flow, batch);
            break;
          case AuthorizationType.MOBILE_APP:
            this.authorizationICAHandler(flow, batch, requestNumber);
            break;
        }
      }
      else if (reportStateType === ReportStateType.IN_PROGRESS) {
        if (this.signaturePhase === SIGNATURE_PHASE.AUTHORIZATION) {
          this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.PROCESSING, batch: batch});
        }
        this.signatureInProgressHandler(flow, batch, requestNumber);
      }
      else if (reportStateType === ReportStateType.ERROR) {
        switch (reportStateId) {
          case ReportStateId.EXPIRED_FLOW:
          case ReportStateId.UNEXPECTED_STATE_CANCELLED:
          case ReportStateId.UNEXPECTED_STATE_REJECTED:
          case ReportStateId.MISSING_READ_FLAG:
          case ReportStateId.MISSING_VISUAL_FIELD:
          case ReportStateId.MISSING_PROFILE:
          case ReportStateId.PROFILE_INACTIVE:
          case ReportStateId.EXPIRED_CERTIFICATE:
            this.$store.commit('signature/setSignaturePhase', SIGNATURE_PHASE.SIGNATURE_FIELD);
            if(this.documentFields.length > 0) {
              await this.$refs.pdfViewer.removeSignatureField();
            }
            await this.errorResultHandle('error', reportRow.reportStateDescription, true, flow);
            break;

          case ReportStateId.UNEXPECTED_STATE_PROCESSING:
            await this.errorResultHandle('error', reportRow.reportStateDescription, false, flow);
            this.initSignatureInProgress(this.currentStep);
            break;

          case ReportStateId.UNEXPECTED_STATE_FINISHED:
          case ReportStateId.FLOW_LOCK:
            this.$store.commit('signature/setSignaturePhase', SIGNATURE_PHASE.SIGNATURE_FIELD);
            await this.errorResultHandle('info', reportRow.reportStateDescription, true, flow);
            break;

          default:
            if (reportRow?.reportStateDescription) {
              await this.errorResultHandle('error', reportRow.reportStateDescription, false, flow);
            }
        }
      }
    },
    signatureErrorHandler(flow, message) {
      if (flow.flowId === this.flow.flowId) {
        this.$store.commit('workflow/addErroredFlow', {flow: flow, message: message});
      }
    },
    async errorResultHandle(notificationType, reportDescription, reload, flow) {
      //this.$store.commit('notification/showMessage', {content: reportDescription, type: notificationType});
      this.signatureErrorHandler(flow, reportDescription);
      if (reload) {
        await this.loadFlow();
      }
    },
    signatureInProgressHandler(flow, batch, requestNumber) {
      let timeout = 1000;
      if (requestNumber > 10)
        timeout *= 2;
      if (requestNumber > 20)
        timeout *= 3;
      if (requestNumber > 30)
        timeout *= 4;

      this.signatureInProgressTimeoutId = window.setTimeout(async () => {
        try {
          const batchLink = batch._links.self.href;
          const response = await this.axios.get(batchLink);
          if (flow.flowId === this.flow.flowId) { // TODO: flow nebo this.flow může být undefined
            await this.batchReportHandler(flow, response.data, requestNumber + 1);
          }
        }
        catch (e) {
          console.error('An error occurred while reloading batch', e);
        }
      }, timeout);
    },
    async signatureSuccessHandler(flow) {
      if (flow.flowId === this.flow.flowId) {
        if (!this.isNextStepMine) {
          this.showNextStepIsMine = false;
          this.$store.commit('workflow/addDisabledFlow', {flow: flow});
        }
        this.$store.commit('workflow/removeErroredFlow', {flow: flow});
        this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.SUCCESS});
        this.clientSigningInProgress = false;

        this.documentSigned = true;
        this.documentSignatureProcessing = false;
        this.$refs.pdfViewer?.documentDataReload();

        this.$emit('successfully-signed');
        if (!this.onlineSignature) this.$store.commit('navigation/refreshCounters');

        if (this.isNextStepMine) {
          this.showNextStepIsMine = true;
          if (!this.onlineSignature) {
            this.reset();
            await this.loadFlow()
          }
        }
      }
    },
    /*
     * Obsluha jednotlivých způsobů autorizace: klientské zařízení, autorizační server, mobilní aplikace
     */
    async authorizationCardHandler(flow, batch, requestNumber) {
      const reportRow = this.getReportRowForCurrentStep(batch);
      if (!reportRow) {
        console.warn('Failed to find reportrow for current step')
        return
      }

      if (flow.flowId === this.flow.flowId) {
        if (this.signaturePhase !== SIGNATURE_PHASE.CLIENT_SIGNING && !this.clientSigningUnknown) {
          const clientSigningUriData = await this.getClientSigningUri(reportRow);
          this.clientSigningUri = clientSigningUriData.uri;
          redirectTo(this.clientSigningUri, false);
          this.clientSigningInProgress = true;
          /*
           * Timeout automatického zavření dialogového okna informujícího uživatele o probíhající komunikaci s
           * klientským zařízením (aplikací OBELISK Signing Portal Client).
           */
          this.clientSigningInProgressTimeoutId = window.setTimeout(() => {
            this.clientSigningInProgress = false;
          }, 30000);

          this.$store.commit('signature/setSignaturePhase', {phase: SIGNATURE_PHASE.CLIENT_SIGNING});
        }

        this.signatureInProgressHandler(flow, batch, requestNumber);
      }
    },
    async authorizationExternalHandler(flow, batch) {
      const reportRow = this.getReportRowForCurrentStep(batch);
      if (!reportRow) {
        console.warn('Failed to find reportrow for current step')
        return
      }
      const authorizationLink = reportRow._links['sef:external-authorization'].href;

      if (flow.flowId === this.flow.flowId) {
        if (reportRow.authorizationRedirectCount === undefined || reportRow.authorizationRedirectCount >= 1) {
          if (this.signaturePhase !== SIGNATURE_PHASE.AUTHORIZATION) {
            this.$store.commit('signature/setSignaturePhase', {
              phase: SIGNATURE_PHASE.AUTHORIZATION,
              link: authorizationLink,
              abandonAuthorizationLink: reportRow._links['sef:abandon-authorization'].href,
              authorizationType: reportRow.authorizationType
            });
          }
        }
        else {
          await this.authorizationRedirect(authorizationLink, this.onlineSignature);
        }
      }
    },
    authorizationICAHandler(flow, batch, requestNumber) {
      const reportRow = this.getReportRowForCurrentStep(batch);
      if (!reportRow) {
        console.warn('Failed to find reportrow for current step')
        return
      }
      if (flow.flowId === this.flow.flowId) {
        if (this.signaturePhase !== SIGNATURE_PHASE.AUTHORIZATION) {
          this.$store.commit('signature/setSignaturePhase', {
            phase: SIGNATURE_PHASE.AUTHORIZATION,
            link: undefined,
            abandonAuthorizationLink: reportRow._links['sef:abandon-authorization'].href,
            authorizationType: reportRow.authorizationType
          });
        }

        this.signatureInProgressHandler(flow, batch, requestNumber);
      }
    },
    async cancelAuthorization(link) {
      try {
        await this.abandonAuthorization(link);
        this.documentSignatureProcessing = false;
        this.$store.commit('workflow/addErroredFlow', {
          flow: this.flow,
          message: this.$t('docDetail.messages.authzAbandoned').toString()
        });
        this.$store.commit('signature/setSignaturePhase', { phase: SIGNATURE_PHASE.ERROR });
        this.$store.commit('navigation/refreshCounters');

        this.batch = undefined;
        this.clientSigningUnknown = false;
        this.clientSigningUri = undefined;

        await this.loadFlow(false);
        /*
         * V případě online podpisu a podpisu externím uživatelem se do této komponenty předává přímo objekt flow a tedy
         * aktualizaci flow je potřeba provést mimo tuto komponentu.
         */
        if (this.onlineSignature)
          this.$emit('flow-reload');
      }
      catch (e) {
        console.error('An error occurred while canceling authorization', e);
      }
    },
    async getClientSigningUri(reportRow) {
      try {
        const clientSigningUriResponse = await this.axios.get(reportRow._links['sef:get-client-signing-uri'].href);
        return clientSigningUriResponse.data;
      }
      catch (e) {
        console.error('An error occurred while loading data to sign on the client device', e);
        return undefined;
      }
    },
    async reasonSignSetReloadDocument() {
      if (this.onlineSignature) {
        this.$emit('flow-reload');
      }
      else {
        await this.loadFlow();
      }
    },
    getReportRowForCurrentStep(batch) {
      return batch?.reportRows?.find(
          row => !!row._embedded?.steps?.find(step => step.stepId === this.currentStep?.stepId))
    },
    externalSigningDialog() {
      this.externalSigningDialogVisible = true;
    },
    completedExternalSigning() {
      this.showNextStepIsMine = false;
      this.$store.commit('workflow/addDisabledFlow', {flow: this.flow});
      this.reset();
      this.loadFlow();
    },
  }
}
</script>

<style scoped>
.flow-detail-layout {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-defaultBackground-base);
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  /*noinspection CssUnresolvedCustomProperty*/
  scrollbar-color: var(--v-scrollbar-base) #fcfcfc;
  scrollbar-width: thin;
}

@media all and (max-width: 1263px) {
  .flow-detail-layout {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

.flow-detail-layout::-webkit-scrollbar {
  width: 6px;
}

.flow-detail-layout::-webkit-scrollbar-thumb {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: var(--v-scrollbar-base);
  border-radius: 20px;
  /*noinspection CssUnresolvedCustomProperty*/
  border-right: 2px solid var(--v-mainBackground-base);
  opacity: 0.12;
}

.flow-detail-layout::-webkit-scrollbar-track {
  background: transparent;
}

.flow-detail-layout.fullscreen {
  padding-top: 0;
}

.flow-detail {
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 100%;
}

.flow-detail__info {
  flex: 0 1 auto;
  padding: 4px;
  position: relative;
}

.flow-detail__note {
  overflow-wrap: break-word;
}

.flow-info__content {
  padding: 16px 16px 8px 16px;
}

.flow-info-actions {
  align-items: end;
  display: flex;
  max-width: 100%;
}

.mobile .flow-info-actions{
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flow-info-basic {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.flow-info-basic__title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.flow-info-error {
  margin-bottom: 8px;
}

/*noinspection CssUnusedSymbol*/
.flow-info-error >>> .v-badge__badge {
  border-radius: 6px;
  height: 12px;
  min-width: 12px;
}

.mobile .flow-info__content {
  padding: 0 12px 8px 12px;
}

.pdf-view-container {
  flex: 1 1 auto;
  overflow-y: hidden;
  display: flex;
  position: relative;
  /* this doesn't affect the layout but without it the offsetTop of nested elements returns wrong values  */
}

.empty {
  position: relative;
  top: 45%;
}

.empty > h1 {
  font-weight: 500;
}

.batch-icon-switch > div {
  overflow: hidden;
  width: 50px;
  height: 20px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--v-textSecondary-base);
}

.batch-icon-switch > div > button {
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--v-textSecondary-base);
  width: 50px;
  height: 35px;
  margin-top: -22px;
  padding-top: 8px;
  color: white;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.forbidden-card {
  border-radius: 20px;
  margin: 0 52px;
  padding: 8px;
}

/*noinspection CssUnusedSymbol*/
.forbidden-card >>> .v-card__title {
  word-break: break-word;
}

.mobile .forbidden-card {
  margin: 0 20px;
}

.page_switch {
  margin-left: auto;
  margin-right: auto;
}

.flow-detail-actions {
  flex-grow: 1;
  max-width: 100%;
}

.short-screen {
  min-height: 48rem;
}

.dueDate{
  display: flex;
  flex-direction: column;
  gap:0;
  text-align: end;
}

.page-switch__online--mobile {
  position: absolute;
  left: 72px;
  top: 12px;
}
</style>

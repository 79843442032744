
import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import Vue from "vue";

export default Vue.extend({
  name: 'SImg',
  props: {
    alt: String,
    base64: Boolean,
    contain: {
      type: Boolean,
      default: true
    },
    src: {
      type: String,
      required: true
    },
    height: String,
    maxHeight: {
      type: String,
      default: '100%'
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    width: {
      type: String,
      default: '100%'
    },
    position: String
  },
  data(){
    return {
      base64ImagePrefix: IMAGE_DATA_URI_PREFIX
    }
  },
  computed: {
    localSrc(): string {
      if(this.base64 && this.src){
        if(this.src.startsWith("data:image")){
          return this.src;
        } else {
          return IMAGE_DATA_URI_PREFIX + this.src;
        }
      } else {
        return this.src;
      }
    }
  }
})

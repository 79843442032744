
import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import pdfPageInfoMixin from "@/mixins/pdfPageInfoMixin";
import PDFWidget from "@/component/pdf/widgets/PDFWidget.vue";
import PDFWidgetToolbarBtn from "@/component/pdf/widgets/PDFWidgetToolbarBtn.vue";
import VisualisationSignature from "@/component/profile/visualisation/VisualisationSignature.vue";
import {
  PdfRectDims,
  PdfWidgetConfig,
  PdfWidgetRect,
  PdfWidgetRestrictionExpressions,
  PdfWidgetRestrictions,
  PdfWidgetRestrictionScope,
  PdfWidgetsAdjacent,
  Visualisation
} from "@/types";
import {calcWidgetRestrictions, computeWidgetStyle} from "@/utils/pdfViewerUtils";
import {SIGNATURE_PHASE} from "@/utils/constants";
import STextButton from "@/component/ui/buttons/STextButton.vue";

export default mixins(pdfPageInfoMixin).extend({
  name: 'PDFWidgetSignature',

  components: {STextButton, PDFWidget, PDFWidgetToolbarBtn, VisualisationSignature},

  props: {
    adjacent: Object as PropType<PdfWidgetsAdjacent>,
    readonly: Boolean,
    restrictionExpressions: Object as PropType<PdfWidgetRestrictionExpressions>,
    value: {
      type: Object as PropType<PdfWidgetConfig>,
      required: true
    },
    visualisation: Object as PropType<Visualisation>
  },
  data() {
    return {
      signatureWidth: 'auto',
      signatureHeight: 'auto',
    }
  },

  computed: {
    maxHeight(): number | undefined {
      return this.restrictions.maxHeight;
    },
    maxWidth(): number | undefined {
      return this.restrictions.maxWidth;
    },
    minHeight(): number | undefined {
      return this.restrictions.minHeight;
    },
    minWidth(): number | undefined {
      return this.restrictions.minWidth;
    },
    rect: {
      get(): PdfWidgetRect {
        return this.value.rect;
      },
      set(rect: PdfWidgetRect) {
        this.$emit('input', { ...this.value, rect });
      }
    },
    restrictions(): PdfWidgetRestrictions {
      /* Scope musí obsahovat originální hodnoty (bez měřítka) */
      const scope: PdfWidgetRestrictionScope = {
        FIELD_HEIGHT: this.rect.height,
        FIELD_WIDTH: this.rect.width,
        PAGE_HEIGHT: this.pageHeightRaw,
        PAGE_WIDTH: this.pageWidthRaw
      };

      return calcWidgetRestrictions(this.restrictionExpressions, scope);
    },
    signaturePhase(): SIGNATURE_PHASE {
      return this.$store.getters["signature/signaturePhase"];
    },
    inError(): boolean {
      return this.signaturePhase === SIGNATURE_PHASE.ERROR;
    },
    widgetBtnText(): string {
      if (this.inError) return this.$t('common.actions.tryAgain').toString();
      return this.$t('docDetail.actions.sign').toString();
    }
  },

  methods: {
    remove(): void {
      this.$emit('remove', this.value.id);
    },
    sign(): void {
      this.$emit('sign');
    },
    useNext(): void {
      this.$emit('use-next', this.adjacent?.next);
    },
    usePrev(): void {
      this.$emit('use-prev', this.adjacent?.prev);
    },
    widgetStyle(size: PdfRectDims): Record<string, string> {
      const computedStyle= computeWidgetStyle(size, this.pageInfo);
      this.signatureHeight=computedStyle.height;
      this.signatureWidth=computedStyle.width;
      return computedStyle;
    }
  }
})

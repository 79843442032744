
import Vue from 'vue';
import SDialog from "@/component/ui/SDialog.vue";
import STextField from "@/component/ui/form/STextField.vue";
import SSignatureSelector from "@/component/ui/form/SSignatureSelector.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";

export default Vue.extend({
  name: 'ThirdPersonSignatureDialog',
  components: {STextButton, SSignatureSelector, STextField, SDialog},
  props: {
    name: String,
    value: Boolean,
  },
  data(){
    return {
      localName: '',
      image: undefined as string|undefined
    }
  },
  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    valid(): boolean {
      return !!this.localName  && this.localName.length <= 200;
    }
  },
  methods: {
    clear(): void {
      this.localName = '';
      (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).clear();
    },
    async sign(): Promise<void> {
      let signatureValid = await (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).validate();
      if(!signatureValid) return;
      this.$emit('sign', {name: this.localName, image: this.image});
      this.localValue = false;
    }
  },
  watch: {
    localValue(value: boolean){
      if(value) {
        this.localName = this.name;
      } else {
        this.clear();
      }
    }
  }
})



import VisualisationSignature from "@/component/profile/visualisation/VisualisationSignature.vue";
import Vue, {PropType} from "vue";
import {Visualisation} from "@/types";

export default Vue.extend({
  name: 'VisualisationListItem',
  components: { VisualisationSignature },

  props: {
    visualisation: {
      type: Object as PropType<Visualisation>,
      required: true,
    }
  }
})

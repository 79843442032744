<template>
  <v-row no-gutters  class="metadata-row pill textSecondary--text text-small">

    <!-- Full View -->
    <template v-if="!mobileView">
      <div  class="ma-2"  style="width: 64px; display: flex; align-items: center">
          <STooltip bottom>
            <template #activator="{ on }">
              <v-avatar v-on="on" :color="rowContent.icon.background" :size="64" rounded >
                <v-icon v-text="'$' + rowContent.icon.text" size="40"  :color="rowContent.icon.color"/>
              </v-avatar>
            </template>
            <span>  {{rowContent.icon.tooltipText}}  </span>
          </STooltip>
      </div>
      <div  style="width: 100%" class="pa-2">
          <v-row no-gutters>
            <v-col  class="bold">
              {{ rowContent.upper.info }}
            </v-col>
            <v-col >
              <span class="textTernary--text">{{ rowContent.upper.key }}{{ rowContent.upper.key ? ': ' : "" }}</span>
              {{ rowContent.upper.value }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" >
              <div class="signer-name__row">
                <SAvatar :src="rowContent.avatar" :size="16" :fallback-icon="rowContent.avatarIcon ? rowContent.avatarIcon : '$user'"
                         :fallback-icon-color="iconColor" fallback-background-color="mutedContrast"/>
                <p class="signer-name__truncate">{{ rowContent.middle.info.primary }} </p>
                <p>{{ rowContent.middle.info.secondary }}</p>
              </div>
            </v-col>
            <v-col cols="6" >
              <span class="textTernary--text  ">{{ rowContent.middle.key }}{{ rowContent.middle.key ? ': ' : "" }}</span>
              {{ rowContent.middle.value }}
            </v-col>
          </v-row>
          <v-row no-gutters v-if="rowContent.lower" class="text-row" >
            <STooltip  bottom open-delay="500" :disabled="rowContent.lower.length < 120"
                      z-index="1000">
              <template #activator="{on}">
                <div v-on="on" class="lower-text">
                  {{ rowContent.lower }}
                </div>
              </template>
              <div> {{ rowContent.lower }}</div>
            </STooltip>
          </v-row>
          <v-row no-gutters v-if="rowContent.resendEmailUrl" style="align-items: center">
            <v-icon v-if="rowContent.upper.value" v-text="'$' + externEmailErrorIcon" size="16" class="mr-2"
                    :color="externEmailErrorIconColor"/>
            <span>{{ $t(externEmailErrorText) }}</span>
            <v-btn @click="resendEmail"
                   :loading="loading"
                   :ripple="false"
                   :disabled="buttonDisabled"
                   color="primary"
                   class="px-2 button-no-background"
                   text
                   >{{ $t('docDetail.metadata.resendEmail') }}
            </v-btn>
          </v-row>
      </div>
    </template>

    <!-- Mobile View -->
    <template v-else>
      <div  class="ma-2"  style="width: 56px; display: flex; align-items: center">
        <v-avatar :color="rowContent.icon.background" :size="56" rounded>
          <v-icon v-text="'$' + rowContent.icon.text" size="24" :color="rowContent.icon.color"/>
        </v-avatar>
      </div>
      <div style="width: 100%"  class="mx-1 my-2">
        <v-row no-gutters class="bold">{{ rowContent.upper.info }}</v-row>
        <v-row no-gutters class="d-flex flex-wrap" >
          <div class="signer-name__truncate" style="max-width: 224px !important;">{{ rowContent.middle.info.primary }}&nbsp;</div>
          <div class="secondaryText--text">{{ rowContent.middle.info.secondary }}</div>
        </v-row>
        <v-row no-gutters class="secondaryText--text">{{ rowContent.lower }}</v-row>
        <v-row no-gutters v-if="rowContent.resendEmailUrl" style="align-items: center">
          <span style="align-items: center">
            <v-icon v-if="rowContent.upper.value" v-text="'$' + externEmailErrorIcon" size="16" class="mr-1"
                    :color="externEmailErrorIconColor"/>
            {{ $t(externEmailErrorText) }}
            <v-btn @click="resendEmail"
                   :loading="loading"
                   :ripple="false"
                   :disabled="buttonDisabled"
                   color="primary"
                   class="px-2 button-no-background"
                   text
                   small>{{
                $t('docDetail.metadata.resendEmail')
              }}
          </v-btn>
          </span>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5">
            <div class="d-flex align-center">
              <v-icon v-if="rowContent.middle.value" v-text="'$' + mobileIcon(rowContent.middle)" size="16"
                      class="mr-2"/>
              <div class="text-no-wrap">{{ rowContent.middle.value }}</div>
            </div>
          </v-col>
          <v-col cols="7">
            <div class="d-flex align-center">
              <v-icon v-if="rowContent.upper.value" v-text="'$' + mobileIcon(rowContent.upper)" size="16" class="mr-2"/>
              <div>{{ rowContent.upper.value }}</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>



  </v-row>
</template>

<script>
import {IMAGE_DATA_URI_PREFIX} from "@/utils/constants";
import SAvatar from "@/component/ui/SAvatar";
import {ROW_TYPE} from "@/mixins/metadataMixin";
import {formatDateShort} from "@/utils/dateUtils";
import STooltip from "@/component/ui/STooltip.vue";

export default {
  name: 'MetadataRow',
  components: { STooltip, SAvatar},

  props: {

    /*  Info - text zarovnany vlevo
     *  Key - zarovnany vpravo
     *  Value - zarovnany vlevo
     *  Lower - posledni radek ma jen jeden sloupec s textem
     *  *MiddleInfo:
     *    - avatar - iba na desktope
     *    - primary - tucny text na zacatku (obvykle jmeno)
     *    - secondary - normalni text na konci (obvykle akce)
     *
     *   +---------+---------------+-------------+-----------------+
     *   |         |  UpperInfo... |  ...UpperKey | UpperValue...  |
     *   |         +---------------+--------------+----------------+
     *   |  Status | MiddleInfo*.. | ...MiddleKey | MiddleValue... |
     *   |   Icon  +---------------+--------------+----------------+
     *   |         | Lower / resendEmailUrl ...................... |
     *   +---------+-----------------------------------------------+
     */
    rowContent: {
      avatar: String,
      avatarIcon: String,
      upper: {
        info: String,
        key: String,
        value: String
      },
      middle: {
        info: {
          primary: String,
          secondary: String
        },
        key: String,
        value: String
      },
      lower: String,
      lowerSecondaryText: Boolean,
      icon: {
        text: String,
        background: String,
        color: String,
        tooltipText: String
      },
      resendEmailUrl:String | undefined,
      mailState:String | undefined,
      mailRecentlySent:Boolean,
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    mobileView: Boolean
  },

  data() {
    return {
      imagePrefix: IMAGE_DATA_URI_PREFIX,
      loading:false,
      stateChanged:undefined,
      disabled:undefined
    }
  },

  computed: {
    buttonDisabled() {
      return this.disabled ? this.disabled : this.rowContent.mailRecentlySent;
    },
    externEmailState(){
      return this.stateChanged ? this.stateChanged : this.rowContent.mailState;
    },
    externEmailError() {
      return !(this.externEmailState === 'INITIATED' || this.externEmailState === 'SENT');
    },

    actionToDisplay: function () {
      if (!this.step) {
        return this.flowState === 'draft' ?
            this.$t('docDetail.metadata.ownerDrafted')
            : this.$t('docDetail.metadata.ownerSent');
      }

      let action = '';

      switch (this.step.state.toLowerCase()) {
        case 'new':
          action = this.flowState === 'draft' ? '' : this.$t('docDetail.metadata.userWaiting');
          break;
        case 'active':
        case 'processing':
        case 'error':
          action = this.$t('docDetail.metadata.usersTurn');
          break;
        case 'finished':
          action = this.$t('docDetail.metadata.userSigned');
          break;
        case 'rejected':
          action = this.$t('docDetail.metadata.userRejected');
          break;
        case 'inactive':
          action = '';
          break;
      }

      return action;
    },


    actionDateToDisplay() {
      if (this.step)
        if (this.step.state.toLowerCase() === 'finished' || this.step.state.toLowerCase() === 'rejected')
          return formatDateShort(this.step.changedAt);
        else if (this.step.dueDate)
          return this.$t('docDetail.metadata.until') + ' ' + formatDateShort(this.step.dueDate);
        else
          return this.$t('docDetail.metadata.noDueDate');
      else
        return formatDateShort(this.flowCreationDate);
    },

    dueDateToDisplay() {
      return this.step ? formatDateShort(this.step.dueDate) : this.flowDueDate;
    },

    methodUsedToDisplay() {
      return (this.step && this.step.state.toLowerCase() === 'finished')
          ? this.step.signingMethodName : '';
    },

    backgroundColor() {
      return this.darkMode ? 'secondaryText' : 'transparent';
    },

    avatarColor() {
      return this.darkMode ? 'mainBackground' : 'mutedText';
    },

    iconColor() {
      return this.darkMode ? 'black' : 'white';
    },
    externEmailErrorIcon() {
      return this.externEmailError ? 'stateError' : 'stateInfo';
    },
    externEmailErrorIconColor() {
      return this.externEmailError ? 'error' : 'info';
    },
    externEmailErrorText() {
      let message;
      switch (this.externEmailState){
        case 'INITIATED':
          message = 'docDetail.metadata.externEmailInitiated';
          break;
        case 'SENT':
          message = 'docDetail.metadata.externEmailSent';
          break;
        case 'NOT_SENDING':
          message = 'docDetail.metadata.externEmailNotSending';
          break;
        case 'ERROR':
          message = 'docDetail.metadata.externEmailError';
          break;
      }
      return message;
    }

  },

  methods: {
    mobileIcon(row) {
      switch (row.type) {
        case ROW_TYPE.TIME:
          return 'calendar';
        case ROW_TYPE.SIGNATURE_LEVEL:
          return 'stepCurrent';
        case ROW_TYPE.PEOPLE:
          return 'users'
        default:
          return undefined;
      }
    },
    async resendEmail() {
      try {
        this.loading = true;
        let response = await this.axios.post(this.rowContent.resendEmailUrl);
        this.stateChanged = response.data.mailState;
        this.disabled = response.data.mailRecentlySent;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.metadata-row {
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--v-defaultBackground-base);
  /*noinspection CssUnresolvedCustomProperty*/
  color:var(--v-textSecondary-base);
  border-radius: 4px;
  flex-wrap: nowrap !important;
}


.lower-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button-no-background::before {
  background-color: transparent !important; /* Set to any color you want */
}

.signer-name__row{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center

}
.signer-name__row  p {
  -webkit-line-clamp: 1;
  margin: 0;
}
.signer-name__truncate{
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
 //max-width: 60%;
  max-width: calc(100% - 120px);
}

</style>


import Vue, {PropType} from "vue";
import {UserAccount} from "@/types";
import {ACCOUNT_TYPE} from "@/utils/constants";
import {nameToDisplay} from "@/utils/accountUtils";

export default Vue.extend({
  name: 'SignerName',
  props: {
    account: {
      type: Object as PropType<UserAccount>,
      required: true
    },
    myself: Boolean
  },
  computed: {
    external(): boolean {
      return this.account.accountType === ACCOUNT_TYPE.EXTERNAL;
    },
    name(): string {
      return nameToDisplay(this.account);
    }
  }
})

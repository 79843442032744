
import Vue from "vue";
import VueDraggableResizable from "vue-draggable-resizable";

export interface DragEvent {
  x: number;
  y: number;
}

export interface ResizeEvent {
  x: number;
  y: number;
  height: number;
  width: number;
}

/**
 * Wrapper komponenty VueDraggableResizable 2.
 *
 * @see {@link https://github.com/mauricius/vue-draggable-resizable | VueDraggableResizable 2}
 * @see {@link https://mauricius.github.io/vue-draggable-resizable/?path=/story/basic--basic-component | VueDraggableResizable 2 examples}
 */
export default Vue.extend({
  name: 'SDragResize',

  components: {VueDraggableResizable},

  props: {
    active: Boolean,
    aspectRatio: Boolean,
    className: String,
    dragCancel: String,
    draggable: Boolean,
    resizable: Boolean,
    x: Number,
    y: Number,
    z: [Number, String],
    height: [Number, String],
    width: [Number, String],
    maxHeight: Number,
    maxWidth: Number,
    minHeight: Number,
    minWidth: Number,
    parentLimitation: Boolean
  },

  methods: {
    onDragging(x: number, y: number): void {
      const event: DragEvent = { x, y };
      this.$emit('dragging', event);
    },
    onDragStop(x: number, y: number): void {
      const event: DragEvent = { x, y };
      this.$emit('drag-stop', event);
    },
    onResizing(x: number, y: number, width: number, height: number): void {
      const event: ResizeEvent = {
        x,
        y,
        height,
        width
      };
      this.$emit('resizing', event);
    },
    onResizeStop(x: number, y: number, width: number, height: number): void {
      const event: ResizeEvent = {
        x,
        y,
        height,
        width
      };
      this.$emit('resize-stop', event);
    }
  }
})

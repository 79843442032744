
import SAvatar from "@/component/ui/SAvatar.vue";
import avatarMixin from "@/mixins/avatarMixin";
import {nameToDisplay} from "@/utils/accountUtils";
import mixins from "vue-typed-mixins";
import {PropType} from "vue";
import {FlowState, FlowStep, UserAccount} from "@/types";

enum StepState {
  COMPLETED = "COMPLETED",
  CURRENT = "CURRENT",
  REJECTED = "REJECTED",
  WAITING = "WAITING",
  SKIPPED = "SKIPPED",
  IN_ERROR = "IN_ERROR"
}

export default mixins(avatarMixin).extend({
  name: 'FlowStepVisualisationAvatar',
  components: {SAvatar},

  props: {
    currentStep: {
      type: Number,
      required: true
    },
    flowState: {
      type: String as PropType<FlowState>,
      required: true
    },
    step: {
      type: Object as PropType<FlowStep>,
      required: true
    }
  },

  data() {
    return {
      userAvatar: undefined as string | undefined
    }
  },

  computed: {
    color(): string {
      switch (this.currentStatus) {
        case StepState.COMPLETED:
          return 'secondaryContrast';
        case StepState.CURRENT:
          return 'mutedContrast';
        case StepState.REJECTED:
        case StepState.IN_ERROR:
          return 'mainContrast';
        case StepState.WAITING:
        default:
          return 'mutedText';
      }
    },

    currentStatus(): StepState {
      if (this.step.state === 'new') return StepState.WAITING;
      if (this.step.state === 'finished') return StepState.COMPLETED;
      if (this.step.state === 'rejected' || this.step.state === 'expired') return StepState.REJECTED;
      if (this.step.state === 'inactive' &&
          (this.flowState === 'recalled' || this.flowState === 'rejected')) return StepState.SKIPPED;
      if (this.step.state === 'error' && this.step.stepType == 'flow_restamp') return StepState.IN_ERROR;

      if (this.step.stepOrder < this.currentStep)
        return StepState.COMPLETED;
      else if (this.step.stepOrder > this.currentStep)
        return StepState.WAITING;
      else
        return StepState.CURRENT;
    },

    skipped(): boolean {
      return this.currentStatus === StepState.SKIPPED;
    },

    icon(): string {
      switch (this.currentStatus) {
        case StepState.COMPLETED:
          return '$stepCompleted';
        case StepState.CURRENT:
          return '$stepCurrent';
        case StepState.REJECTED:
          return '$stepRejected';
        case StepState.IN_ERROR:
          return '$error';
        case StepState.WAITING:
        default:
          return '$stepWaiting';
      }
    },

    isSystemPreprocessing(): boolean {
      return this.step.stepType === "flow_activation";
    },

    isSystemProcessing(): boolean {
      return this.step.stepType === "final_processing";
    },

    isFlowRestamp(): boolean {
      return this.step.stepType === "flow_restamp";
    },

    tooltipText(): string {
      if (this.isSystemProcessing || this.isSystemPreprocessing)
        return this.$t('workflow.steps.systemProcessing').toString();
      else if (this.isFlowRestamp)
        return this.$t('workflow.steps.flowRestamp').toString();
      else
        return nameToDisplay(this.userAccount);
    },

    userAccount(): UserAccount {
      // Here we are guaranteed that all parts are defined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.step._embedded!.account!;
    },

    userAvatarLink(): string | undefined {
      return this.userAccount._links.userAvatar?.href;
    },

    userInitials(): string {
      if (this.userAccount.userLastName) {
        if (this.userAccount.userFirstName)
          return this.userAccount.userFirstName.charAt(0).toUpperCase() +
              this.userAccount.userLastName.charAt(0).toUpperCase();
        else
          return this.userAccount.userLastName.charAt(0).toUpperCase();
      }
      else if (this.userAccount.accountName) {
        return this.userAccount.accountName.charAt(0).toUpperCase();
      }
      else {
        return "_"
      }
    }
  },
  methods: {
    async loadAvatar(): Promise<void> {
      if (this.userAvatarLink) {
        this.userAvatar = await this.getUserAvatar(this.userAvatarLink);
      }
    }
  },
  watch: {
    userAvatarLink() {
      this.loadAvatar();
    }
  },
  created() {
    this.loadAvatar();
  }
})

import { render, staticRenderFns } from "./CenteredPanelLayout.vue?vue&type=template&id=477181ae&scoped=true&"
import script from "./CenteredPanelLayout.vue?vue&type=script&lang=ts&"
export * from "./CenteredPanelLayout.vue?vue&type=script&lang=ts&"
import style0 from "./CenteredPanelLayout.vue?vue&type=style&index=0&id=477181ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477181ae",
  null
  
)

export default component.exports

import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import AccountInfo from "@/component/AccountInfo.vue";
import SignatureContentLogo from "@/component/signature/SignatureContentLogo.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";

export default mixins(displayMixin).extend({
  name: 'SignatureContentLayout',

  components: {AccountInfo, SIconButton, SignatureContentLogo},

  props: {
    title: String
  }
})

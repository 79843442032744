

import Vue, {PropType} from "vue";

export default Vue.extend({
  name: 'SProgressStacked',

  props: {
    actual: Array as PropType<Array<number>>,
    colors: {
      type: Array,
      default: () => ['mutedText', 'mutedContrast', 'secondaryContrast', 'mainContrast']
    },
    numbers: {
      type: Boolean,
      default: false
    },
    title: String,
    total: Number
  },

  computed:{
    first(): number|undefined{
      for (let i = 0; i < this.actual.length; i++) {
        if(this.actual[i] > 0) return i;
      }
      return undefined;
    },
    last(): number|undefined{
      for (let i = this.actual.length - 1; i >= 0; i--) {
        if(this.actual[i] > 0) return i;
      }
      return undefined;
    }
  },

  methods: {
    getLineWidth(value: number): number {
      return (value / this.total) * 100;
    }
  }
})


import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import {UploadWizardActionsConfig, UploadWizardConfig, WorkflowUploadWizardAction} from "@/types";
import {PropType} from "vue";

export default mixins(displayMixin).extend({
  name: "UploadWizardButtons",
  components: {STextButton},
  props: {
    config: {
      type: Object as PropType<UploadWizardConfig>
    }
  },
  data(){
    return {
      actions: [
        {type: "back", title: 'uploadWizard.actions.back', textOnly:true, variant: 'primary-muted-accent', dataTestid: 'uploadWizardActions-back', onClick: () => this.$emit('prev')},
        {type: "enclosures" , title: 'uploadWizard.actions.enclosures', variant: 'secondary-muted', dataTestid: 'uploadWizardActions-next', onClick: ()=> this.$emit('next')},
        {type: "next", title: 'uploadWizard.actions.continue', variant: 'primary-success', dataTestid: 'uploadWizardActions-next', onClick: ()=> this.$emit('next')},
        {type: "submit", title: 'uploadWizard.actions.submit', variant: 'primary-success', dataTestid: 'uploadWizardActions-submit', onClick: ()=> this.$emit('submit')}
      ] as UploadWizardActionsConfig[],
    }
  },
  methods: {
    isActionVisible(type: WorkflowUploadWizardAction): boolean{
      return this.config?.actions.includes(type);
    }
  }
})


import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import SSwitch from "@/component/ui/form/SSwitch.vue";
import {PropType} from "vue";
import {
  DocumentField,
  DocumentFieldType,
  InputFlow,
  InputFlowStep,
  PdfFieldPlaceEvent,
  PdfFieldUpdateEvent,
  PdfPlaceWidgetState,
  PdfSignatureAnnotations
} from "@/types";
import SFloatingButton from "@/component/ui/buttons/SFloatingButton.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import PDFViewer from "@/component/pdf/PDFViewer.vue";

export default mixins(displayMixin).extend({
  name: "StepSignersField",
  components: {PDFViewer, STextButton, SFloatingButton, SSwitch,},
  data(){
    return {
      openSigners: false,
      pdfToolbar: false,
      signatureAnnotations: PdfSignatureAnnotations.SIGN_HERE,
      userSignFieldMap: {} as {[key: string | number]: { checked: boolean | undefined, field: PdfFieldUpdateEvent | undefined}},
      user: undefined as number | undefined,
      documentFieldsArray: [] as Array<DocumentField | undefined>,
      field: undefined as PdfFieldUpdateEvent | undefined,
      disableMap: {} as {[key: number]: boolean}
    }
  },
  props: {
    value: {
      type: Object as PropType<InputFlow>,
      required: true
    },
    active: Boolean,
  },
  computed: {
    valid(): boolean {
      return !!this.value;
    },
    getArrayOfFields(): Array<DocumentField | undefined> {
      return this.formatArray();
    }
  },
  methods: {
    formatArray(): Array<DocumentField | undefined> {
      let oldFields = [] as Array<DocumentField | undefined>;
      let newFields = [] as Array<DocumentField | undefined>;
      //Načtení již vytvořených polí z dokumentu
      if (this.value._embedded.documentFields.length > 0) {
        oldFields = this.value._embedded.documentFields;
      }

      //část kódu, která kontroluje nově vytvořené pole a prochází array již vytvořených polí v dokumentu,
      // pokud pole má stejný fieldID tak se nahradí tím nově vytvořeným
      Object.keys(this.userSignFieldMap).map(key => {
        if (this.userSignFieldMap[key].field?.documentField.fieldId) {
          const index = oldFields.findIndex(
              (field) => field?.fieldId === this.userSignFieldMap[key].field?.documentField.fieldId);
          if (index !== -1) {
            oldFields[index] = this.userSignFieldMap[key].field?.documentField;
          }
        }
      });

      //Vytovřené pole, které je filtrováno bez mezer
      newFields = Object.keys(this.userSignFieldMap).map(key => this.userSignFieldMap[key].field?.documentField).filter(Boolean);
      //Pokud nové pole obsahuje FieldID tak se s pole smaže
      newFields = newFields.filter((field) => !field?.fieldId);
      this.documentFieldsArray = oldFields.concat(newFields);
      return this.documentFieldsArray.filter(Boolean);
    },
    async validate(): Promise<boolean> {
      this.$emit('getUserFields', this.getArrayOfFields);
      return true;
    },
    async createSignField(user: InputFlowStep, index: number): Promise<void> {
      const DocumentSign = {
        setBySigner: false,
        stepInputNumber: index + 1,
        page: 1,
        uiName: user._embedded.account.accountName,
        type: DocumentFieldType.SP,
        _links: undefined
      } as DocumentField;
      if (this.$refs.pdfViewer) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await this.$refs.pdfViewer.addSignHereField(DocumentSign, true);
      }
    },
    async removeSignField(index: number): Promise<void> {
      if (this.$refs.pdfViewer && this.user != undefined) {
        const field = this.userSignFieldMap[index].field
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await this.$refs.pdfViewer.removeSignHereField(field.widgetId);
      }
    },
    userAddSignField(user: InputFlowStep, index: number) {
      this.user = index;
      if (this.userSignFieldMap[index].checked) {
        this.disableSwitch(index, true);
        this.$set(this.userSignFieldMap, this.user, {checked: true});
        this.createSignField(user, index);
      }
      else {
        this.removeSignField(index);
        this.$set(this.userSignFieldMap, this.user, {checked: false});
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.pdfViewer.cancelPlaceWidget();
      }
    },
    onSignHereFieldAdded(payload: PdfFieldUpdateEvent): void {
      this.field = payload;
      this.disableSwitch(this.user, true);
      Object.keys(this.userSignFieldMap).map(key => {
        if (this.userSignFieldMap[key].field?.documentField.stepInputNumber === payload.documentField.stepInputNumber) {
          this.$set(this.userSignFieldMap, key, {checked: true, field: payload});
        }
        else {
          if (this.user !== undefined) {
            this.$set(this.userSignFieldMap, this.user, {checked: true, field: payload});
          }
        }
      })
      this.disableSwitch(this.user, false);
    },
    onSignHereFieldRemoved(id: number): void {
      this.disableSwitch(this.user, false);
      Object.keys(this.userSignFieldMap).map(key => {
        if (this.userSignFieldMap[key].field?.widgetId === id) {
          this.$set(this.userSignFieldMap, key, {checked: false});
        }
      })
    },
    onSignHereFieldUpdated(payload: PdfFieldUpdateEvent): void {
      let field;
      Object.keys(this.userSignFieldMap).find(key => {
        if (this.userSignFieldMap[key].field?.widgetId === payload.widgetId) {
          field = this.userSignFieldMap[key].field;
        }
      });
      if (field) {
        this.$set(field, 'documentField', payload.documentField);
      }
    },
    cancelSignatureField(event: PdfFieldPlaceEvent): void {
      if (this.user != undefined && event.state === PdfPlaceWidgetState.CANCEL) {
        this.disableSwitch(this.user, false);
        this.$set(this.userSignFieldMap, this.user, {checked: false});
      }
    },
    clear(): void {
      this.disableMap = {};
      this.userSignFieldMap = {};
      this.user = undefined;
      this.documentFieldsArray = [];
    },
    displayPlacedFields(): void {
      this.user = undefined;
      Object.keys(this.userSignFieldMap).map(key => {
        if(this.userSignFieldMap[key].field === undefined){
          this.$set(this.userSignFieldMap, key, {checked: false, field: undefined})
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.pdfViewer.addSignHereField(this.userSignFieldMap[key].field?.documentField, false);
      });
    },
    disableSwitch(index: number | undefined, click: boolean): void {
      Object.keys(this.disableMap).map(key => {
        if (click) {
          if (index !== parseInt(key)) {
            this.$set(this.disableMap, key, true);
          }
        }
        else {
          this.$set(this.disableMap, key, false);
        }
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.userSignFieldMap = {};
        for (let index = 0; index < this.value._embedded.steps.length; index++) {
          this.$set(this.userSignFieldMap, index, {checked: false, field: undefined});
          this.$set(this.disableMap, index, false);
        }
      },
      deep: true
    },
    valid: {
      handler(value) {
        this.$emit('valid', value);
      },
      immediate: true
    }
  },
  created() {
    //Před načtením stránky nastavit mapu uživatelů -> switch na false a field na undefined
    for (let index = 0; index < this.value._embedded.steps.length; index++) {
      this.$set(this.userSignFieldMap, index, {checked: false, field: undefined});
      this.$set(this.disableMap, index, false);
    }
  }
})

import {KeycloakTokenParsed} from "keycloak-js";
import {HalResource, Link} from "@/types/hal";
import {Category} from "@/types/navigation";

export enum DisplayVariant {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet'
}

export enum GuiVariant {
  EXTERNAL_VARIANT = 'external_user',
  FULL_VARIANT = 'full',
  ONLINE_VARIANT = 'online_signature'
}

export enum NoteInputMode {
  NOT_ALLOWED = 'not_allowed',
  OPTIONAL = 'optional',
  REQUIRED = 'required'
}

export interface AccessTokenPayload extends KeycloakTokenParsed {
  'magiclink-data'?: string;
}

export interface Bank {
  id: string;
  title: string;
  description?: string;
  available_logo_images: Array<BankLogo>;
  available_services: Array<string>;
}

export interface BankLogo {
  id: string;
  url: string;
  width: number;
  height: number;
}

export interface BusinessConfig extends HalResource<BusinessConfigEmbedded, unknown> {
  activeLocales: Array<Locale>;
  autoredirect?: boolean;
  autoredirectDelay?: number;
  clientConfig: { [key: string]: { clientDownloadUrl: string } };
  documentValidation?: boolean;
  externalUsers?: boolean;
  guiVariantsSettings: { [key in GuiVariant]: VariantMode };
  homeUrl?: string;
  manualFlowCreation?: boolean;
  organisationLogo?: string;
  presetLoggedUserInCreatedFlow?: boolean;
  quickActionsEnabled?: boolean;
  supportEmail?: string;
  supportEnabled: boolean;
}

export interface VariantMode {
  recall: CancelType;
  rejection: CancelType;
}

export interface CancelType {
  instructions?: string,
  noteInputMode?: NoteInputMode,
  placeholder?: string
}

export interface VariantMode {
  variantMode: VariantMode;
}

export interface BusinessConfigEmbedded {
  categories?: Array<Category>;
}

export interface BusinessLevel {
  businessLevelId: number;
  description: string;
  thirdPerson: boolean;
}

export interface Locale {
  languageCode: string;
}

export interface ObeliskConfigJs {
  guiUrl: string;
  apiUrl: string;
  keyCloakUrl: string;
  keyCloakRealm: string;
}

export interface ObeliskConfiguration {
  guiUrl: string;
  apiUrl: string;
  authUrl: string;
  authRealm: string;
  clientId: string;
}
export interface UploadWizardConfig {
  title: string;
  dataTestid: string;
  showIf: ()=> boolean;
  component: {
    name: string;
    ref: string;
    props?: { [key: string]: any };
    events?: { [key: string]: any };
  },
  actions: Array<WorkflowUploadWizardAction>;
  postValidate?: (valid: boolean) => void;
}

export interface UploadWizardActionsConfig {
  type: WorkflowUploadWizardAction;
  title: string;
  textOnly?:boolean,
  variant: ButtonVariant;
  dataTestid: string;
  onClick: () => void;
  showIf?: () => boolean;
}

export type WorkflowUploadWizardAction = "back" | "cancel" | "next" | "enclosures" | "submit";

export type ButtonVariant =
    "primary-danger"
    | "primary-danger-accent"
    | "primary-muted"
    | "primary-muted-accent"
    | "primary-success"
    | "primary-success-green"
    | "primary-background"
    | "secondary-danger"
    | "secondary-muted"
    | "secondary-success-accent"
    | "secondary-background"
    | "secondary-background-tinted"

export interface IconSelectItem {
  text: string;
  icon: string;
}

export type QuickActions = HalResource<QuickActionEmbedded, QuickActionLinks>;

export interface QuickActionEmbedded {
  actions: Array<QuickActionsTypeAction>;
}

export interface QuickActionsTypeAction {
  actionType: string;
  localizedNameCode: string;
}

export interface QuickActionLinks {
  self: Link;
  "sef:perform-action": Link;
}

export interface SignatureDetailsSettings {
  contactInfoOptions: Array<VisualReasonTypes>,
  locationOptions: Array<VisualReasonTypes>,
  reasonOptions: Array<VisualReasonTypes>
}

export interface VisualReasonTypes {
  usageMethod: string | undefined;
  textValue?: string | undefined;
  label?: string | undefined;
}

export interface VisualReasonBulkPayload {
  reason?: VisualReasonTypes;
  location?: VisualReasonTypes;
  contactInfo?: VisualReasonTypes;
  stepIds: []
}

import { render, staticRenderFns } from "./SignerEmail.vue?vue&type=template&id=323cd00c&scoped=true&"
import script from "./SignerEmail.vue?vue&type=script&lang=ts&"
export * from "./SignerEmail.vue?vue&type=script&lang=ts&"
import style0 from "./SignerEmail.vue?vue&type=style&index=0&id=323cd00c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323cd00c",
  null
  
)

export default component.exports
import {GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";
import {SignatureOnlinePhase} from "@/types";

interface OnlineSignatureState {
  onlineSignaturePhase: SignatureOnlinePhase | undefined
  redirectUri: string | undefined
  flowId: number | undefined
  flowStepId: number | undefined
}

const state = (): OnlineSignatureState => ({
  onlineSignaturePhase: undefined,
  redirectUri: undefined,
  flowId: undefined,
  flowStepId: undefined
})

const mutations: MutationTree<OnlineSignatureState> = {
  setOnlineSignaturePhase(state, payload: SignatureOnlinePhase | undefined) {
    state.onlineSignaturePhase = payload;
  },
  setOnlineRedirectUri(state, payload: string | undefined) {
    state.redirectUri = payload;
  },
  setFlowId(state, payload: number | undefined) {
    state.flowId = payload
  },
  setFlowStepId(state, payload: number | undefined) {
    state.flowStepId = payload
  }
}

const getters: GetterTree<OnlineSignatureState, RootState> = {
  onlineSignaturePhase: (state): SignatureOnlinePhase | undefined => {
    return state.onlineSignaturePhase;
  },
  onlineRedirectUri: (state): string | undefined => {
    return state.redirectUri;
  },
  flowId: (state): number | undefined => {
    return state.flowId;
  },
  flowStepId: (state): number | undefined => {
    return state.flowStepId;
  }
}

export const onlineSignature: Module<OnlineSignatureState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters
};
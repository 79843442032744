
import STextField from "@/component/ui/form/STextField.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import Vue from "vue";
import {PHONE_NUMBER} from "@/utils/urls";
import {RedirectResponse} from "@/types/dto";
import {redirectTo} from "@/utils/utils";
import {ValidationObserver} from "vee-validate";

export default Vue.extend({
  name: 'PhoneNumberSelector',
  components: {STextButton, STextField,ValidationObserver},
  data() {
    return {
      dialog: false,
      loading: false,
      phoneNumber:undefined
    };
  },
  created() {
    let phone = this.$store.getters["profile/number"];
    if (phone) this.phoneNumber = phone;

  },
  methods: {
    async submit(): Promise<void> {
      if (!this.phoneNumber) return;
      try {
        this.loading = true;
        let response = (await this.axios.put(PHONE_NUMBER, {
          phoneNumber: this.phoneNumber,
          redirectUri: window.location.href
        })).data as RedirectResponse;
        redirectTo(response.redirectUri, false);
      } catch (e) {
        console.log('error occurred when submitting phone number:', e)
      } finally {
        this.loading = false;
      }
    }
  }

})

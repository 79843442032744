
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import mutationMixin from "@/mixins/mutationMixin";
import ClientSigningDialog from "@/component/dialog/ClientSigningDialog.vue";
import NavigationSidebar from "@/component/nav/NavigationSidebar.vue";
import ThemeProvider from "@/component/ui/theme/ThemeProvider.vue";
import GlobalNotification from "@/component/GlobalNotification.vue";
import {CssCustomization, DisplayVariant, NavigationMode} from "@/types";
import NavigationSidebarOnline from "@/component/nav/NavigationSidebarOnline.vue";

interface DisplayConfig {
  permanent: boolean;
  width: number | string | undefined;
}

type NavConfig = {
  [key in DisplayVariant]: DisplayConfig;
}

export default mixins(displayMixin, mutationMixin).extend({
  name: 'App',
  components: {NavigationSidebarOnline, ThemeProvider, ClientSigningDialog, GlobalNotification, NavigationSidebar},
  data() {
    return {
      navConfig: {
        mobile: {
          permanent: false,
          width: undefined
        },
        tablet: {
          permanent: false,
          width: undefined
        },
        desktop: {
          permanent: true,
          width: undefined
        }
      } as NavConfig,
      navVisible: false,
      personalInfoLoaded: false,
    }
  },
  computed: {
    clientSigningDialogVisible: {
      get(): boolean {
        return this.$store.getters['notification/clientSigningInProgress'];
      },
      set(value: boolean): void {
        this.$store.commit('notification/setClientSigningInProgress', value);
      }
    },
    cssCustomization(): CssCustomization | undefined {
      return this.$store.getters['theme/cssCustomization'];
    },
    navigationMode(): NavigationMode {
      return this.$store.getters["navigation/mode"];
    },
    navFixed(): boolean {
      return this.navConfig[this.displayActive].permanent;
    },
    navWidth(): number | string | undefined {
      return this.navConfig[this.displayActive].width;
    },
    pageTitle(): string | undefined {
      return this.$store.getters['theme/pageTitle'];
    },
    isOnlineSignature(): boolean {
      return this.navigationMode === NavigationMode.ONLINE_SIGNATURE;
    },
    checkLoadQuickAction(): boolean {
      return this.$store.getters["config/checkLoadQuickAction"];
    }
  },
  watch: {
    $route(): void {
      this.initNavMode();
    }
  },
  async created(): Promise<void> {
    this.initNavMode();

    // Add window listeners on specific events to determine if user is using mouse or touch
    window.addEventListener("click", () => this.$store.commit("config/setTouchScreen", false));
    window.addEventListener("touchstart", () => this.$store.commit("config/setTouchScreen", true));

    // Needs to be loaded on application start
    await this.$store.dispatch('theme/loadTheme');
    await this.$store.dispatch('locale/loadLocale');
    await this.$store.dispatch('config/loadConfig');
    await this.$store.dispatch('profile/loadPersonalInfo');

    // Nastavení konfigurovatelného názvu stránky
    if (this.pageTitle)
      document.title = this.pageTitle;

    if (this.checkLoadQuickAction) {
      try {
        await this.$store.dispatch('config/loadQuickAction');
      }
      catch (e) {
        this.$store.commit('notification/showMessage',
            {content: this.$t('quickActions.notification.quickActionError'), type: "error"});
      }
    }
    this.personalInfoLoaded = true;
  },
  methods: {
    initNavMode(): void {
      let navMode: NavigationMode = NavigationMode.NAV;
      if (this.$route.path.startsWith("/profile")) {
        navMode = NavigationMode.PROFILE;
      }
      else if (this.$route.path.startsWith("/notifications")) {
        navMode = NavigationMode.NOTIFICATIONS;
      }
      else if (this.$route.path.startsWith("/online-signature")) {
        navMode = NavigationMode.ONLINE_SIGNATURE;
      }

      if (this.navigationMode !== navMode) {
        this.$store.commit('navigation/setNavMode', {mode: navMode});
      }
    },
    changeContent(newContent: NavigationMode): void {
      this.$store.commit('navigation/setNavMode', {mode: newContent});
    },
    navOpen(): void {
      this.navVisible = true;
    },
  }
})

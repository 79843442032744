
import {PropType} from "vue";
import {MutationPayload} from "vuex";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import mutationMixin from "@/mixins/mutationMixin";
import LocaleSwitch from "@/component/locale/LocaleSwitch.vue";
import FoldersMenu from "@/component/nav/FoldersMenu.vue";
import NavigationBottomToolbar from "@/component/nav/NavigationBottomToolbar.vue";
import NavigationHeader from "@/component/nav/NavigationHeader.vue";
import NavigationSignActions from "@/component/nav/NavigationSignActions.vue";
import NotificationsMenu from "@/component/nav/NotificationsMenu.vue";
import ProfileMenu from "@/component/nav/ProfileMenu.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SImg from "@/component/ui/SImg.vue";
import {organisationLogoURL} from "@/services/api/appDetailsApi";
import {Category, NavigationMode} from "@/types";
import {ACCOUNT_TYPE} from "@/utils/constants";

export default mixins(mutationMixin, displayMixin).extend({
  name: 'NavigationSidebar',
  components: {
    NotificationsMenu,
    ProfileMenu,
    NavigationSignActions,
    SIconButton,
    STextButton, LocaleSwitch, SImg, FoldersMenu, NavigationBottomToolbar, NavigationHeader
  },
  props: {
    navigationMode: String as PropType<NavigationMode>,
    permanent: {
      type: Boolean,
      default: false
    },
    value: Boolean,
    width: [Number, String]
  },
  data() {
    return {
      showQuickAction: false,
      mobile: false as boolean
    }
  },
  computed: {
    localNavigationMode: {
      get(): NavigationMode {
        return this.navigationMode;
      },
      set(value: NavigationMode) {
        this.$emit('change-content', value);
      }
    },
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    batchMode: {
      get(): boolean {
        return this.$store.getters["workflow/batchMode"];
      },
      set(value: boolean) {
        this.$store.commit('workflow/setBatchMode', {batchMode: value});
      }
    },
    externalUser(): boolean {
      return this.$store.getters["profile/accountType"] === ACCOUNT_TYPE.EXTERNAL;
    },
    manualFlowCreationEnabled(): boolean {
      return this.$store.getters["config/manualFlowCreationEnabled"];
    },
    organisationLogoURL(): string {
      return organisationLogoURL();
    },
    homeUrl(): string | undefined {
      return this.$store.getters["config/homeUrl"];
    },
    menuRootUrl(): string {
      switch (this.localNavigationMode) {
        case NavigationMode.PROFILE:
          return '/profile';
        case NavigationMode.NOTIFICATIONS:
          return '/notifications';
        default:
          return '/overview';
      }
    },
    menuDense(): boolean {
      return this.localNavigationMode === NavigationMode.PROFILE || this.localNavigationMode ===
          NavigationMode.NOTIFICATIONS;
    },
    profileNavigationMode(): boolean {
      return this.localNavigationMode === NavigationMode.PROFILE;
    },
    foldersNavigationMode(): boolean {
      return this.localNavigationMode === NavigationMode.NAV;
    },
    notificationsNavigationMode(): boolean {
      return this.localNavigationMode === NavigationMode.NOTIFICATIONS;
    },
    categories(): Array<Category> {
      return this.$store.getters['config/categories'];
    },
    showSignatureOnlineRedirect(): boolean {
      return !!this.$route.query['online-signature-redirect-uri'];
    },
    signatureOnlineRedirectUri(): string | null {
      const uri = this.$route.query['online-signature-redirect-uri'];
      return Array.isArray(uri) ? uri[0] : uri;
    },
    applicationVersion(): string {
      return this.$store.getters["appDetails/applicationVersion"];
    },
    mobileDevice(): boolean {
      return this.displayMobile && this.forcedDisplay === undefined;
    }
  },
  methods: {
    navClose(): void {
      this.showQuickAction = false;
      this.localValue = false;
    },
    isShowQuickAction(): void {
      this.showQuickAction = !this.showQuickAction;
    },
    onMenuSelect() {
      // When we select anything, we want to close the drawer. When different folder is selected, the drawer is closed
      // automatically because it is connected with router. However in the case, when same folder is selected, nothing
      // happens and we have to close the drawer manually.
      this.navClose();
    },
    goHome() {
      if(!this.externalUser){
        if (this.homeUrl) {
          this.showQuickAction = false;
          window.location.href = this.homeUrl;
        }
        else {
          this.showQuickAction = false;
          if (this.localNavigationMode !== NavigationMode.NAV) this.$router.push("/overview");
          this.$store.commit("navigation/goToDefaultCategory");
          this.localNavigationMode = NavigationMode.NAV;
        }
      }
    },
    createWorkflow() {
      this.$store.commit("workflow/openWorkflowWizard")
    },
    goToProfile(): void {
      this.showQuickAction = false;
      this.localNavigationMode = NavigationMode.PROFILE;
    },
    goToOverview(): void {
      this.showQuickAction = false;
      this.localNavigationMode = NavigationMode.NAV;
    },
    goToNotifications(): void {
      this.showQuickAction = false;
      this.localNavigationMode = NavigationMode.NOTIFICATIONS;
    },
    signatureOnlineRedirect(): void {
      if (this.signatureOnlineRedirectUri)
        this.$router.push(this.signatureOnlineRedirectUri).catch(() => {/**/});
    }
  },
  watch: {
    mobile(value: boolean) {
      this.displayMobile = value;
    }
  },
  created() {
    this.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'navigation/setNavMode') {
        if (mutation.payload.mode === NavigationMode.NAV) {
          this.$router.push({path: '/overview'}).catch((e: unknown) => {console.log(e)})
        }
        else if (mutation.payload.mode === NavigationMode.PROFILE) {
          if (!this.$route.path.startsWith("/profile/")) {
            this.$router.push({path: '/profile/info'}).catch(() => {/**/})
          }
        }
        else if (mutation.payload.mode === NavigationMode.NOTIFICATIONS) {
          if (!this.$route.path.startsWith("/notifications")) {
            this.$router.push({path: '/notifications'}).catch((e: unknown) => {console.log(e)});
          }
        }
      }
    });

    this.$store.dispatch("appDetails/loadAppDetails");
  }
})


import mixins from "vue-typed-mixins";
import localeMixin from "@/mixins/localeMixin";
import SImg from "@/component/ui/SImg.vue";
import SSelect from "@/component/ui/form/SSelect.vue";
import {Locale} from "@/types";

export default mixins(localeMixin).extend({
  name: 'LocaleSwitch',
  components: {SImg, SSelect},
  computed: {
    locale: {
      get(): Locale | undefined {
        return this.locales.find(l => l.languageCode === this.activeLocale);
      },
      set(value: string) {
        this.setLocaleCode(value);
      }
    }
  }
})

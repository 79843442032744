
import Vue from "vue";

export default Vue.extend({
  name: 'SAspectRatio',
  props: {
    aspectRatio: {
      type: String,
      required: true,
      validator: (value: string) => {
        const [w, h] = value.split(":").map(v => parseInt(v))
        return !Number.isNaN(w) && !Number.isNaN(h)
      }
    },
    center: Boolean,
    maxHeight: Number,
    maxWidth: Number,
  },
  data(){
    return {
      parentWidth: undefined as number|undefined,
      parentHeight: undefined as number|undefined,
      resizeObserver: undefined as ResizeObserver|undefined
    }
  },
  computed: {
    ratioComputed(): number {
      const [w, h] = this.aspectRatio.split(":").map(v => parseInt(v))
      return w / h;
    },
    aspectStyle() : object {
      if(!this.parentWidth || !this.parentHeight) return {};

      // Maximum possible values
      const maxWidth = this.maxWidth && this.maxWidth < this.parentWidth ? this.maxWidth : this.parentWidth;
      const maxHeight = this.maxHeight && this.maxHeight < this.parentHeight ? this.maxHeight : this.parentHeight;

      // Count actual size with respect to aspect ratio
      let width: number, height : number;
      if(maxWidth < maxHeight){
        width = maxWidth;
        height = width / this.ratioComputed;
        if(height > maxHeight){
          height = maxHeight;
          width = height * this.ratioComputed;
        }
      } else {
        height = maxHeight;
        width = height * this.ratioComputed;
        if(width > maxWidth){
          width = maxWidth;
          height = width / this.ratioComputed;
        }
      }

      return {
        width: width + 'px',
        height: height + 'px'
      }
    }
  },
  methods: {
    updateElementSize(): void {
      this.parentWidth = this.$el.clientWidth;
      this.parentHeight = this.$el.clientHeight;
    }
  },
  mounted() {
    this.updateElementSize();
    this.resizeObserver = new ResizeObserver(() => this.updateElementSize());
    this.resizeObserver.observe(this.$el);
  },
  beforeDestroy() {
    if(this.resizeObserver){
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
  }
})

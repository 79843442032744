import { render, staticRenderFns } from "./SignatureExternalView.vue?vue&type=template&id=49e413d5&scoped=true&"
import script from "./SignatureExternalView.vue?vue&type=script&lang=ts&"
export * from "./SignatureExternalView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e413d5",
  null
  
)

export default component.exports
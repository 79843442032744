import {BATCHES_AUTHORIZATION, BATCHES_FAILED, BATCHES_IN_PROGRESS} from "@/utils/urls";
import Vue from "vue";
import {NotificationFolderType} from "@/types";
import {BatchListResponse} from "@/types/dto";

export default Vue.extend({
  methods: {
    async apiGetBatchesAuthorization(): Promise<BatchListResponse> {
      const response = await this.axios.get(BATCHES_AUTHORIZATION);
      return response.data as BatchListResponse;
    },

    async apiGetBatchesError(): Promise<BatchListResponse> {
      const response = await this.axios.get(BATCHES_FAILED);
      return response.data as BatchListResponse;
    },

    async apiGetBatchesProcessing(): Promise<BatchListResponse> {
      const response = await this.axios.get(BATCHES_IN_PROGRESS);
      return response.data as BatchListResponse;
    },

    async apiGetBatchesRecent(maxResults = 3): Promise<BatchListResponse> {
      const response = await this.axios.get("/batches", {params: {max: maxResults}});
      return response.data as BatchListResponse;
    },

    async apiGetBatchesInState(state: NotificationFolderType): Promise<BatchListResponse> {
      let data = undefined;
      switch (state) {
        case 'authorization':
          data = await this.apiGetBatchesAuthorization();
          break;
        case 'error':
          data = await this.apiGetBatchesError();
          break;
        case 'processing':
          data = await this.apiGetBatchesProcessing();
          break;
        case 'notifications':
          data = await this.apiGetBatchesRecent();
          break;
      }
      return data;
    }
  }
})

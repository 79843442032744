
import math, {compile} from "mathjs";
import mixins from "vue-typed-mixins";
import pdfPageInfoMixin from "@/mixins/pdfPageInfoMixin";
import PDFWidgetSignature from "@/component/pdf/widgets/PDFWidgetSignature.vue";
import PDFWidgetSignHere from "@/component/pdf/widgets/PDFWidgetSignHere.vue";
import {createPayload} from "@/store/modules/pdfViewer";
import {
  FlowStep,
  PdfSignatureAnnotations,
  PdfWidgetConfig,
  PdfWidgetRect,
  PdfWidgetRestrictionExpressions,
  PdfWidgetsAdjacent,
  PdfWidgetType,
  SignatureProfile,
  SigningProfileFieldRestrictions,
  Visualisation,
} from "@/types";
import {
  compareWidgets,
  defaultFieldRestrictions,
  getRectDims,
  PDF_WIDGET_HEIGHT_DEFAULT,
  PDF_WIDGET_HEIGHT_MIN,
  PDF_WIDGET_WIDTH_DEFAULT,
  PDF_WIDGET_WIDTH_MIN,
  roundDimension
} from "@/utils/pdfViewerUtils";

export default mixins(pdfPageInfoMixin).extend({
  name: 'PDFPageLayerSignature',

  components: {PDFWidgetSignature, PDFWidgetSignHere},

  props: {
    pageNumber: {
      type: Number,
      required: true
    },
    pdfViewerId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      signatureLayerMounted: false,
      focusedSignHereWidgetId: undefined as number | undefined
    }
  },

  computed: {
    currentStep(): FlowStep | undefined {
      return this.$store.getters['pdfViewer/currentStep'](this.pdfViewerId);
    },
    placeWidget(): PdfWidgetConfig | undefined {
      return this.$store.getters['pdfViewer/placeWidget'](this.pdfViewerId);
    },
    signatureAnnotations(): PdfSignatureAnnotations {
      return this.$store.getters['pdfViewer/signatureAnnotations'](this.pdfViewerId);
    },
    signatureLayerEnabled(): boolean {
      return this.signatureAnnotations !== PdfSignatureAnnotations.NONE;
    },
    signatureLayerEventHandlers(): { click?: (event: PointerEvent) => Promise<void> } {
      let eventHandlers = {};
      if (this.signatureLayerEnabled && !this.signatureLayerReadonly) {
        eventHandlers = {
          ...eventHandlers,
          click: async (event: PointerEvent) => {
            await this.placeWidgetHandler(event);
          }
        }
      }
      return eventHandlers;
    },
    signatureLayerReadonly(): boolean {
      return this.$store.getters['pdfViewer/signatureLayerReadonly'](this.pdfViewerId);
    },
    signatureLayerStyle(): Record<string, number | string> {
      if (!this.signatureLayerEnabled)
        return {
          display: 'none'
        };
      else
        return {
          height: `${this.pageHeight}px`,
          width: `${this.pageWidth}px`
        };
    },
    signatureVisualisation(): Visualisation | undefined {
      const thirdPersonSignature = !!this.currentStep?.requestedSignatureLevel?.thirdPerson;

      if (this.signingProfile?._embedded?.visual)
        return this.signingProfile._embedded.visual;
      else if (thirdPersonSignature)
        return {
          _links: {},
          name: this.currentStep?.thirdPersonName
        };
      else
        return undefined;
    },
    signatureWidgets(): Array<PdfWidgetConfig> {
      return this.$store.getters['pdfViewer/signatureWidgets'](this.pdfViewerId, this.pageNumber);
    },
    signatureWidgetsEnabled(): boolean {
      return this.signatureAnnotations === PdfSignatureAnnotations.ALL;
    },
    signHereWidgets(): Array<PdfWidgetConfig> {
      return this.$store.getters['pdfViewer/signHereWidgets'](this.pdfViewerId, this.pageNumber);
    },
    signHereWidgetsEnabled(): boolean {
      return this.signatureAnnotations === PdfSignatureAnnotations.ALL || this.signatureAnnotations ===
          PdfSignatureAnnotations.SIGN_HERE;
    },
    signHereWidgetsAdjacent(): PdfWidgetsAdjacent | undefined {
      const signatureWidgets: Array<PdfWidgetConfig> = this.$store.getters['pdfViewer/signatureWidgets'](
          this.pdfViewerId, null);
      if (signatureWidgets.length === 0)
        return undefined;

      const signatureWidget = signatureWidgets[0]; // signature widget by měl existovat maximálně jeden
      if (!signatureWidget.params.transformed)
        return undefined;

      const widgets: Array<PdfWidgetConfig> = this.$store.getters['pdfViewer/widgets'](this.pdfViewerId);
      const widgetsSorted = [...widgets].sort(compareWidgets);
      const signatureWidgetIndex = widgetsSorted.findIndex(widget => widget.id === signatureWidget.id);
      if (widgetsSorted.length <= 1 || signatureWidgetIndex < 0) {
        return undefined;
      }
      else {
        return {
          next: signatureWidgetIndex + 1 < widgetsSorted.length ? widgetsSorted[signatureWidgetIndex + 1].id : undefined,
          prev: signatureWidgetIndex - 1 >= 0 ? widgetsSorted[signatureWidgetIndex - 1].id : undefined
        };
      }
    },
    signHereWidgetUsable(): boolean {
      return this.signatureAnnotations === PdfSignatureAnnotations.ALL ||
          (this.signatureAnnotations === PdfSignatureAnnotations.SIGN_HERE && !!this.placeWidget);
    },
    signingProfile(): SignatureProfile | undefined {
      return this.currentStep?._embedded?.signingProfile;
    },
    //Restrikce pro podpisová pole, momentálně pouze implementováno na nově vytvořená pole

    newFieldRestrictions(): SigningProfileFieldRestrictions {
      return this.signingProfile?.fieldRestrictionsNewField ?? defaultFieldRestrictions;
    },
    pdfFieldRestrictions(): SigningProfileFieldRestrictions {
      return this.signingProfile?.fieldRestrictionsPdfField ?? defaultFieldRestrictions;
    },
    newWidgetRestrictionExpressions(): PdfWidgetRestrictionExpressions {
      const { maxHeight, maxWidth, minHeight, minWidth } = this.newFieldRestrictions;
      const calcMinHeight = minHeight.length > 0 ? minHeight : [`${PDF_WIDGET_HEIGHT_MIN}`];
      const calcMinWidth = minWidth.length > 0 ? minWidth : [`${PDF_WIDGET_WIDTH_MIN}`];

      /*
       * Manuální přiřazení typu, aby compiler správně vyhodnotil volanou funkci. Funkce "compile" je nejednoznačně
       * otypovaná. Příklad nejednoznačné situace pro string[]:
       * string[] = MathExpression[] = MathExpression
       */
      return {
        maxHeight: compile(maxHeight as Array<math.MathExpression>),
        maxWidth: compile(maxWidth as Array<math.MathExpression>),
        minHeight: compile(calcMinHeight as Array<math.MathExpression>),
        minWidth: compile(calcMinWidth as Array<math.MathExpression>)
      };
    },
  },

  mounted() {
    this.signatureLayerMounted = true;
  },

  destroyed() {
    /* Odstranění HTML elementu komponenty z DOM */
    this.$el.remove();
  },

  methods: {
    origPlaceWidgetRect(event: PointerEvent): PdfWidgetRect {
      const layerRect = (this.$refs.signatureLayer as HTMLElement).getBoundingClientRect();
      const pageX = event.clientX - layerRect.left,
            pageY = layerRect.bottom - event.clientY;
      /* Rozměry widgetu respektující měřítko a rotaci */
      const heightOrig = PDF_WIDGET_HEIGHT_DEFAULT,
            widthOrig = PDF_WIDGET_WIDTH_DEFAULT;
      const {height, width} = getRectDims(heightOrig * this.pageScale, widthOrig * this.pageScale, this.pageRotation);
      /* Výpočet levé a spodní souřadnice, aby kurzor byl ve středu widgetu */
      const left = pageX - (width / 2),
            bottom = pageY - (height / 2);
      /* Přizpůsobení umístění widgetu stránce a výpočet chybějících souřadnic */
      const calcLeft = Math.min(Math.max(left, 0), this.pageWidth - width),
            calcBottom = Math.min(Math.max(bottom, 0), this.pageHeight - height),
            calcRight = this.pageWidth - (width + calcLeft),
            calcTop = this.pageHeight - (height + calcBottom);
      /* Odstranění měřítka a rotace souřadnic do výchozího nastavení (pageRotation = 0) */
      const calcPosition = [calcLeft, calcTop, calcRight, calcBottom].map(value => value / this.pageScale);
      for (let i = 0; i < this.pageRotation / 90; i++)
        calcPosition.push(calcPosition.shift() ?? 0);

      return {
        left: roundDimension(calcPosition[0]),
        bottom: roundDimension(calcPosition[3]),
        height: roundDimension(heightOrig),
        width: roundDimension(widthOrig)
      };
    },
    async placeWidgetHandler(event: PointerEvent): Promise<void> {
      if (this.placeWidget) {
        const widget: PdfWidgetConfig = {
          ...this.placeWidget,
          pageNumber: this.pageNumber,
          rect: this.origPlaceWidgetRect(event)
        };

        if (this.placeWidget.type === PdfWidgetType.SIGNATURE) {
          await this.$store.dispatch('pdfViewer/addSignatureWidget', createPayload(this.pdfViewerId, widget));
        }
        else if (this.placeWidget.type === PdfWidgetType.SIGN_HERE) {
          await this.$store.dispatch('pdfViewer/addSignHereWidget', createPayload(this.pdfViewerId, widget));
        }
      }
    },
    async removeSignatureWidget(id: number): Promise<void> {
      await this.$store.dispatch('pdfViewer/removeSignatureWidget', createPayload(this.pdfViewerId, id));
    },
    async removeSignHereWidget(id: number): Promise<void> {
      await this.$store.dispatch('pdfViewer/removeSignHereWidget', createPayload(this.pdfViewerId, id));
    },
    sign(): void {
      this.$store.commit('pdfViewer/sign', createPayload(this.pdfViewerId, undefined));
    },
    async updateSignatureWidget(widget: PdfWidgetConfig): Promise<void> {
      await this.$store.dispatch('pdfViewer/updateSignatureWidget', createPayload(this.pdfViewerId, widget));
    },
    async updateSignHereWidget(widget: PdfWidgetConfig): Promise<void> {
      await this.$store.dispatch('pdfViewer/updateSignHereWidget', createPayload(this.pdfViewerId, widget));
    },
    async useSignHereWidget(id: number): Promise<void> {
      switch (this.signatureAnnotations) {
        case PdfSignatureAnnotations.ALL:
          // TODO: Když nejsou potřebné věci - podpisové profily, tak zobrazit informaci (dialog)
          await this.$store.dispatch('pdfViewer/useSignHereWidget', createPayload(this.pdfViewerId, id));
          break;
        case PdfSignatureAnnotations.SIGN_HERE:
          await this.$store.dispatch('pdfViewer/attachSignHereWidget', createPayload(this.pdfViewerId, {
            targetWidgetId: id,
            widget: this.placeWidget
          }));
          break;
      }
    }
  }
})


import SAvatar from "@/component/ui/SAvatar.vue";
import NavigationUserPopover from "@/component/nav/NavigationUserPopover.vue";
import flowStepsCountsMixin from "@/mixins/flowStepsCountsMixin"
import mixins from "vue-typed-mixins";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import {ACCOUNT_TYPE} from "@/utils/constants";

export default mixins(flowStepsCountsMixin).extend({
  name: 'NavigationHeader',
  components: {SIconButton, NavigationUserPopover, SAvatar},
  props: {
    permanent: Boolean
  },
  data() {
    return {
      popoverVisible: false
    }
  },
  computed: {
    avatar(): string|undefined {
      return this.$store.getters['profile/avatar'];
    },
    name(): string {
      return this.$store.getters['profile/name'];
    },
    email(): string {
      return this.$store.getters['profile/email'];
    },
    externalUser(): boolean {
      return this.$store.getters["profile/accountType"] === ACCOUNT_TYPE.EXTERNAL;
    },
    arrowIcon(): string {
      return this.popoverVisible ? '$arrowUp' : '$arrowDown';
    },
    notificationCounter(): number|string {
      let value = this.$store.getters["interactionCenter/authorizationCount"] + this.$store.getters["interactionCenter/errorCount"];
      if(value >= 100) value = '99+';
      return value;
    }
  },
  methods: {
    navClose(): void {
      this.$emit('nav-close');
    }
  }
})

import Vue from "vue";
import {apiFlowStepsCounts} from "@/services/api/flowStepsApi";

export default Vue.extend({
  data() {
    return {
      flowStepsCountsTimeoutId: undefined as number | undefined,
    }
  },

  async mounted() {
    /* Periodické požadavky se začnou odesílat, pokud je stránka aktivní */
    await this.visibilityChangeHandler();

    document.addEventListener('visibilitychange', this.visibilityChangeHandler);
  },

  beforeDestroy() {
    if (this.flowStepsCountsTimeoutId) {
      window.clearTimeout(this.flowStepsCountsTimeoutId);
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
    }
  },

  methods: {
    async loadFlowStepsCounts(): Promise<void> {
      try {
        const data = await apiFlowStepsCounts();
        this.$store.commit('interactionCenter/setCounts', {
          authorizationCount: data?.authorizationCount || 0,
          errorCount: data?.errorCount || 0,
          processingCount: data?.processingCount || 0
        });
      }
      catch (e) {
        console.error('An error occurred while loading flow steps counts', e);
      }
      finally {
        this.flowStepsCountsTimeoutId = window.setTimeout(this.loadFlowStepsCounts, 2000);
      }
    },
    async visibilityChangeHandler() {
      if (document.visibilityState === 'hidden') {
        /* Vypnutí odesílání periodických požadavků, pokud stránka není aktivní */
        if (this.flowStepsCountsTimeoutId)
          window.clearTimeout(this.flowStepsCountsTimeoutId);
      }
      else {
        await this.loadFlowStepsCounts();
      }
    }
  }
})


import {PERSONAL_INFO} from "@/utils/urls";
import SForm from "@/component/ui/form/SForm.vue";
import SRemoveBadge from "@/component/ui/SRemoveBadge.vue";
import STextField from "@/component/ui/form/STextField.vue";
import {resizeImageAndConvertToBase64} from "@/utils/utils";
import SAvatar from "@/component/ui/SAvatar.vue";
import cloneDeep from 'lodash/cloneDeep'
import SFileSelector from "@/component/ui/form/SFileSelector.vue";
import {UserAccount} from "@/types";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";

export default mixins(displayMixin).extend({
  name: 'PersonalInfo',
  components: {STextButton, SFileSelector, SAvatar, SForm, SRemoveBadge, STextField},

  data() {
    return {
      avatarFile: undefined as File | undefined,
      waitingForResponse: false,
    }
  },

  computed: {
    info(): UserAccount | undefined {
      let info = this.$store.getters['profile/info'];
      if (!info) return undefined;
      return cloneDeep(info);
    },

    isEditable(): boolean {
      return this.info ? this.info.editable : false;
    },

    removeBadgeDisabled(): boolean {
      if(!this.info) return true;
      return !this.info.userAvatar && !this?.info._links.userAvatar
    }

  },

  watch: {
    avatarFile(value: File|undefined) {
      if (value) {
        this.setAvatarFile(value);
      } else if (this.info) {
        this.info.userAvatar = undefined;
        this.$forceUpdate();
      }
    }
  },

  methods: {
    avatarForDisplay(): string {
      if(!this.info) return "";
      if(this.info.userAvatar) return this.info.userAvatar;
      return this.info._links?.userAvatar?.href ? this.info._links.userAvatar.href : "";
    },

    async setAvatarFile(avatar: File): Promise<void> {
      if(!this.info) return;
      this.info.userAvatar = await resizeImageAndConvertToBase64(avatar, 400, 400);
      this.$forceUpdate();
    },

    onAvatarRemoved(): void {
      if (this.avatarFile) {
        this.avatarFile = undefined;
      } else if(this.info) {
        this.info._links.userAvatar = undefined;
        this.$forceUpdate();
      }
    },

    onCancel(): void {
      this.$store.dispatch('profile/loadPersonalInfo').then(
          () => this.info = {...this.$store.getters['profile/info']});
      (this.$refs.avatarSelector as InstanceType<typeof SFileSelector>).removeFile();
    },

    onSubmit() {
      if(this.info?.userAvatar){
        this.info.userAvatar = this.info.userAvatar.split(',')[1];
      } else {
        const avatarImageRef = this.$refs.avatarImage as InstanceType<typeof SAvatar>;
        if(avatarImageRef.localSrc && this.info){
          this.info.userAvatar = avatarImageRef.localSrc.split(',')[1];
        }
      }
      try {
        this.waitingForResponse = true;
        this.$storage.remove(this.$store.getters['profile/avatar']);
        this.axios.put(PERSONAL_INFO, this.info)
            .then(() => this.$store.dispatch('auth/updateToken', 999999))
            .then(() => this.$store.dispatch('profile/loadPersonalInfo'))
            .then(() => this.waitingForResponse = false);
      }
      // eslint-disable-next-line no-empty
      catch (ignore) {
      }
    },
  }
})

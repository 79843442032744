
import {Flow} from "@/types";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import BatchSignatureChangeDialog from "@/component/workflow/batch/BatchSignatureChangeDialog.vue";
import BatchSignDialog from "@/component/workflow/batch/BatchSignDialog.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import BatchRejectionDialog from "@/component/workflow/batch/BatchRejectionDialog.vue";
import STextButton from "@/component/ui/buttons/STextButton.vue";

export default mixins(displayMixin).extend({
  name: 'OverviewBatchActions',
  components: {STextButton, BatchRejectionDialog, BatchSignDialog, BatchSignatureChangeDialog, SIconButton},
  data() {
    return {
      changeDialog: false,
      rejectDialog: false,
      signDialog: false
    }
  },
  computed: {
    selectedFlows(): Array<Flow> {
      return this.$store.getters['workflow/flowSelected'];
    },
  },
  methods: {
    cancelSelection(): void {
      this.$store.commit('workflow/flowCancelSelection');
    }
  }
})

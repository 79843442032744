import Vue from "vue"
import Vuex from "vuex"
import {appDetails} from "@/store/modules/appDetails";
import {auth} from "@/store/modules/auth";
import {batches} from "@/store/modules/batches";
import {config} from "@/store/modules/config";
import {display} from "@/store/modules/display";
import {interactionCenter} from "@/store/modules/interactionCenter";
import {locale} from "@/store/modules/locale";
import {navigation} from "@/store/modules/navigation";
import {notification} from "@/store/modules/notification";
import {onlineSignature} from "@/store/modules/onlineSignature";
import {pdfViewer} from "@/store/modules/pdfViewer";
import {profile} from "@/store/modules/profile";
import {theme} from "@/store/modules/theme";
import {signature} from "@/store/modules/signature";
import {workflow} from "@/store/modules/workflow";

Vue.use(Vuex);

export interface RootState {
  appName: string
}

export default new Vuex.Store<RootState>({
  state: {
    appName: "OBELISK Signing Portal"
  },
  modules: {
    appDetails,
    auth,
    batches,
    config,
    display,
    interactionCenter,
    locale,
    navigation,
    notification,
    onlineSignature,
    pdfViewer,
    profile,
    signature,
    theme,
    workflow
  }
})


import Vue, {PropType} from 'vue';
import {SignatureProfile} from "@/types";
import VueI18n from "vue-i18n";

export default Vue.extend({
  name: 'SignatureProfileCard',

  props: {
    profile: {
      type: Object as PropType<SignatureProfile>,
      required: true
    },
    methodCode: {
      type: String,
      required: true
    },
    busy: {
      type: Boolean,
      required: true
    },
    draggable: Boolean,
  },

  data() {
    return {
      elemWidth: 0 as number,
    }
  },

  computed: {

    switchLabel(): VueI18n.TranslateResult {
      if (this.profile.isEnabled) {
        return this.$t('profile.visualisation.active');
      } else {
        return this.$t('profile.visualisation.inactive')
      }
    },

    narrowView(): boolean {
      return this.elemWidth <= 400;
    },

  },

  methods: {

    onResize(): void {
      this.elemWidth = this.$el.clientWidth;
    },

    onSwitchChange(): void {
      this.profile.isEnabled ? this.activate() : this.deactivate();
    },

    async activate(): Promise<void> {
      if(!this.profile._links['sef:activate-signing-profile']) return;
      try {
        await this.axios.delete(this.profile._links['sef:activate-signing-profile'].href);
        this.$store.commit('profile/setSignatureProfileActivity',
            {code: this.methodCode, profileId: this.profile.profileId, enabled: true})
      } catch (e) {
        this.profile.isEnabled = false;
      }
    },

    async deactivate(): Promise<void> {
      if(!this.profile._links['sef:deactivate-signing-profile']) return;
      try {
        await this.axios.put(this.profile._links['sef:deactivate-signing-profile'].href,
            {profileId: this.profile.profileId});
        this.$store.commit('profile/setSignatureProfileActivity',
            {code: this.methodCode, profileId: this.profile.profileId, enabled: false})
      } catch (e) {
        this.profile.isEnabled = true;
      }
    },

    isActivationSwitchEnabled(profile: SignatureProfile): boolean {
      if (this.busy) {
        return false
      }
      // tohle tu z nejakeho duvodu musi byt, jinak zustane switch zamceny???
      profile._links['sef:activate-signing-profile'];
      profile._links['sef:deactivate-signing-profile'];

      //     profil je deaktivovany a lze aktivovat
      return (!profile.isEnabled && profile._links['sef:activate-signing-profile'] !== undefined)
          // profil je aktivovany a lze deaktivovat
          || (profile.isEnabled && profile._links['sef:deactivate-signing-profile'] !== undefined);
    },


  },

  mounted(): void {
    this.elemWidth = this.$el.clientWidth;
  },

})


import Vue from "vue";
import VueSignaturePad from "vue-signature-pad";
import SAspectRatio from "@/component/ui/SAspectRatio.vue";
import STextField from "@/component/ui/form/STextField.vue";

export default Vue.extend({
  name: 'SSignaturePad',
  components: {STextField, SAspectRatio},
  props: {
    value: String,
    textBased: Boolean
  },
  data() {
    return {
      name: "",
      fontStyle: 'Dancing Script',
      resizeObserver: undefined as ResizeObserver | undefined
    }
  },
  computed: {
    localValue: {
      get(): string|undefined {
        return this.value;
      },
      set(value: string|undefined) {
        this.$emit('input', value);
      }
    },
    signaturePadOptions(): object {
      return {
        onEnd: this.onEnd
      }
    }
  },
  methods: {
    undo() {
      (this.$refs.signaturePad as VueSignaturePad).undoSignature();
    },

    onResize(): void {
      const signaturePad: VueSignaturePad = this.$refs.signaturePad as VueSignaturePad;
      const canvasWrapper: HTMLElement = this.$el.querySelector(".s-aspect-ratio-content") as HTMLElement;
      const canvas: HTMLCanvasElement = signaturePad.$refs.signaturePadCanvas as HTMLCanvasElement;
      const data = signaturePad.toData();
      canvas.width = canvasWrapper.clientWidth;
      canvas.height = canvasWrapper.clientHeight;
      (this.$refs.signatureData as object) = signaturePad.TRANSPARENT_PNG;
      signaturePad.fromData(data);
    },

    clear(): void {
      (this.$refs.signaturePad as VueSignaturePad).clearSignature();
      this.localValue = undefined;
      this.name = "";
    },

    drawName(size = 60): void {
      let canvas: HTMLCanvasElement = this.$el.querySelector("canvas") as HTMLCanvasElement;
      let ctx = canvas.getContext("2d");
      if (!ctx) return;
      ctx.font = size + 'px ' + this.fontStyle;

      let textWidth = ctx.measureText(this.name).width;
      if (textWidth > canvas.width) {
        if (size - 3 > 0) this.drawName(size - 3);
        return;
      }

      ctx.fillText(this.name, (canvas.width / 2) - (textWidth / 2), canvas.height / 2);

      const data = canvas.toDataURL();
      this.localValue = this.name ? data : undefined;
    },

    onEnd() {
      const {isEmpty, data} = (this.$refs.signaturePad as VueSignaturePad).saveSignature();
      this.localValue = isEmpty ? undefined : data;
    }
  },

  watch: {
    name: function () {
      (this.$refs.signaturePad as VueSignaturePad).clearSignature();
      this.localValue = undefined;
      this.drawName();
    }
  },

  mounted() {
    this.resizeObserver = new ResizeObserver(() => this.onResize());
    const resizeDiv = this.$el.querySelector('.s-aspect-ratio-content') as HTMLElement;
    this.resizeObserver.observe(resizeDiv);

    if(this.textBased){
      (this.$refs.signaturePad as VueSignaturePad).lockSignaturePad();
    }
  },

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
  }
})

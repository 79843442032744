
import {FederatedIdentity, IdentityProvider, IdentityProviderCode} from "@/types";
import {FONT_AWESOME_ICONS} from '@/plugin/fontAwesome';
import mixins from "vue-typed-mixins";
import {IdentityProvidersResponse} from "@/types/dto";
import identityProvidersMixin from "@/mixins/identityProvidersMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import STooltip from "@/component/ui/STooltip.vue";

const defaultProviderNames: Readonly<Record<IdentityProviderCode, string>> = {
  google: 'Google',
  linkedin: 'LinkedIn',
  microsoft: 'Microsoft',
  facebook: 'Facebook',
  openid: 'OpenID',
  github: 'GitHub',
  instagram: 'Instagram',
  bitbucket: 'BitBucket',
  twitter: 'Twitter',
  gitlab: 'GitLab',
  paypal: 'PayPal',
  stackoverflow: 'StackOverflow'
};

export default mixins(identityProvidersMixin).extend({
  name: 'IdentityProviders',
  components: {STooltip, STextButton},

  data() {
    return {
      accountLinks: [] as Array<FederatedIdentity>,
      identityProvides: [] as Array<IdentityProvider>,
      createAccountLinkUrl: undefined as string|undefined,
      loading: false,
    }
  },
  computed: {
    orderedProviders(): Array<IdentityProvider> {
      const providersCopy = [...this.identityProvides]
      return providersCopy.sort(this.compareProviders)
    }
  },

  methods: {
    compareProviders(first: IdentityProvider, second:IdentityProvider): number {
      const firstActive = this.isProviderActive(first);
      const secondActive = this.isProviderActive(second);
      if (firstActive && !secondActive) {
        return -1;
      }
      else if (!firstActive && secondActive) {
        return 1;
      }
      else {
        return this.providerName(first).localeCompare(this.providerName(second));
      }
    },
    providerName(provider: IdentityProvider): string {
      return provider.name ?? defaultProviderNames[provider.code] ?? provider.code;
    },
    providerIcon(provider: IdentityProvider): string {
      return FONT_AWESOME_ICONS[provider.code] ? `$${provider.code}` : `$defaultProvider`;
    },
    isProviderActive(provider: IdentityProvider): boolean {
      return this.providerLink(provider) !== undefined;
    },
    providerLink(provider: IdentityProvider): FederatedIdentity|undefined {
      return this.accountLinks.find(link => link.provider === provider.code)
    },

    async loadAccountLinks(): Promise<void> {
      const identityProvidersInfo: IdentityProvidersResponse|undefined = await this.apiGetProviders();
      this.accountLinks = identityProvidersInfo?._embedded?.accountLinks ?? [];
      this.identityProvides = identityProvidersInfo?._embedded?.providers ?? [];
      this.createAccountLinkUrl = identityProvidersInfo?._links["sef:create-account-link"].href;
    },
    async deleteAccountLink(accountLink: FederatedIdentity): Promise<void> {
      await this.apiDeleteAccountLink(accountLink._links["sef:delete-account-link"].href);
      this.$store.commit('notification/showMessage', {
        header: this.$t('profile.externalIdentities.notifications.disconnected.header'),
        content: this.$t('profile.externalIdentities.notifications.disconnected.content'),
        type: 'success'
      });
      await this.loadAccountLinks();
    },
    async createAccountLink(provider: IdentityProvider) {
      if(!this.createAccountLinkUrl) return;
      const response = await this.apiCreateAccountLink(provider.code, this.createAccountLinkUrl);
      if(response) {
        location.href = response.redirectUrl;
      }
    },

  },

  async created() {
    this.loading = true;
    await this.loadAccountLinks();
    this.loading = false;
  },
})



import mixins from "vue-typed-mixins";
import batchMixin from "@/mixins/batchMixin";
import displayMixin from "@/mixins/displayMixin";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SDialog from "@/component/ui/SDialog.vue";
import SIconBadge from "@/component/ui/SIconBadge.vue";
import FlowBatchItem from "@/component/workflow/batch/FlowBatchItem.vue";
import ProfileBatchItem from "@/component/workflow/batch/ProfileBatchItem.vue";
import {BatchCreatePayload, Flow, FlowStep, Replace, ReportRow, ReportStateType, SignatureProfile,} from "@/types";
import SSignatureSelector from "@/component/ui/form/SSignatureSelector.vue";
import SLoader from "@/component/ui/SLoader.vue";

interface CollapsableFlowRow {
  row: Replace<ReportRow, { _embedded: { flow: Flow; steps: Array<FlowStep> } }>;
  collapsed: boolean;
}

interface CollapsableProfileRow {
  row: Replace<ReportRow, { _embedded: { signingProfile: SignatureProfile; steps: Array<FlowStep> } }>;
  collapsed: boolean;
}

interface CollapsableInterventionProfileRow {
  row: Replace<ReportRow, { _embedded: { signingProfile: SignatureProfile; steps: Array<FlowStep>} }>;
  collapsed: boolean;
}

export default mixins(batchMixin, displayMixin).extend({
  name: 'BatchSignDialog',
  components: {SLoader, SSignatureSelector, ProfileBatchItem, FlowBatchItem, STextButton, SDialog, SIconBadge },

  props: {
    value: Boolean
  },

  data() {
    return {
      batchName: undefined as string|undefined,
      reportStateMapper: {
        130: {
          icon: 'read'
        },
        140: {
          icon: 'sig'
        },
        170: {
          icon: 'location'
        }
      } as Record<number, { icon: string }>,
      reportRows: [] as Array<ReportRow>,
      validStepIds: [] as Array<number>,
      flowRows: [] as Array<CollapsableFlowRow>,
      profileRows: [] as Array<CollapsableProfileRow>,
      interventionRows: [] as Array<CollapsableInterventionProfileRow>,
      image: undefined as string | undefined,
      selectedAdHocStepIds: undefined as number[] | undefined,
      validStepIdsWithoutAdHoc: [] as Array<number>,
      isEmptyImage: true,
      loading: false,
      btnLoading: false,

      //image input methods
      text: false,
      picture: false,
      draw: false,
    };
  },

  computed: {
    dialogTextMulti(): string {
      let text = this.$tc('workflow.batch.dialogs.sign.multi.text.selectedAll', this.flowSelectedCount);
      if(this.getValidStepIdsWithoutAdHoc().length >= 0){
        text += this.$tc('workflow.batch.dialogs.sign.multi.text.flowWithoutIntervention', this.getValidStepIdsWithoutAdHoc().length);
        text += " ";
      }
      if (this.flowErrorCount > 0 || this.flowAdHocCount > 0){
        text += this.$tc('workflow.batch.dialogs.sign.multi.text.actionRequired', this.flowErrorCount + this.flowAdHocCount);
      }
      return text;
    },

    dialogTextSingle(): string {
      return this.$tc('workflow.batch.dialogs.sign.single.text', this.flowSelectedCount,
          {profile: this.singleReportProfile});
    },

    signButtonText(): string {
      let text = this.$t('workflow.batch.dialogs.sign.signButtonTitle',
          {count: this.getValidStepIds().length, total: this.flowSelectedCount});
      return text.toString();
    },

    flowErrorCount(): number {
      return this.reportRows.filter(row => this.isReportStateError(row)).reduce((total, row) => total + row.count, 0);
    },



    flowSelected(): Array<Flow> {
      return this.$store.getters['workflow/flowSelected'];
    },

    flowSelectedCount(): number {
      return this.$store.getters['workflow/flowSelectedCount'];
    },

    isSingleReportValid(): boolean {
      return this.reportRows.length === 1 && !this.isReportStateError(this.reportRows[0]);
    },

    flowAdHocCount(): number {
      let sum = 0
      this.interventionRows.forEach(value => {
        sum += value.row.count;
      })
      return sum;
    },

    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },

    selectedFlowIds(): Array<number> {
      return this.$store.getters['workflow/flowSelected'].map((flow: Flow): number => flow.flowId);
    },

    singleReportProfile(): string {
      return this.formatSigningProfile(this.reportRows[0]);
    },

    imageInputMethods(): Array<string> {
      return this.$store.getters["config/imageInputMethods"];
    }
  },

  watch: {
    localValue(value) {
      if (value)
        this.getBatchReport();
      else
        this.reset();
    },
    image(value) {
      if(value){
        this.isEmptyImage = !this.isEmptyImage;
      }
    }
  },

  methods: {
    close() {
      this.localValue = false;
    },

    adHocAvailableSigningMethods(): void {
      if (this.imageInputMethods) {
        this.imageInputMethods.find((value) => {
          if (value === 'DRAW') {
            this.draw = true;
          }
          if (value === 'LOAD_FILE') {
            this.picture = true;
          }
          if (value === 'TEXT') {
            this.text = true;
          }
        })
      }
    },

    formatSigningProfile(reportRow: ReportRow): string {
      const profile = reportRow._embedded?.signingProfile;
      return profile ? `${profile.profileName} (${profile.legalLevel})` : "";
    },

    async getBatchReport(): Promise<void> {
      let stepIds = this.flowSelected.map(flow => flow.myActiveStepId);
      try {
        this.loading = true
        let data = await this.apiBatchReport(stepIds);
        if (data?.reportRows) {
          this.reportRows = data.reportRows;
          this.validStepIds = this.getValidStepIds();
          this.reportRows.forEach(row => {
            if (row.reportStateType === ReportStateType.ERROR) {
              if(row._embedded?.flows) {
                row._embedded.flow = this.flowSelected.find(f => f.flowId === row._embedded?.flows[0].idSpFlow);
                let item: CollapsableFlowRow = {
                  row: row as Replace<ReportRow, { _embedded: { flow: Flow; steps: Array<FlowStep> } }>,
                  collapsed: this.flowSelectedCount >= 10
                };
                this.flowRows.push(item);
              }
            }
            else if (row._embedded?.signingProfile) {
              if (row._embedded.signingProfile.inputs.required !== undefined &&
                  row._embedded.signingProfile.inputs.required.length > 0) {
                let item: CollapsableInterventionProfileRow = {
                  row: row as Replace<ReportRow, { _embedded: { signingProfile: SignatureProfile; steps: Array<FlowStep> } }>,
                  collapsed: this.flowSelectedCount >= 10
                };
                this.selectedAdHocStepIds = row._embedded.steps?.map((flow) => flow.stepId);
                this.interventionRows.push(item);
              }
              else {
                let item: CollapsableProfileRow = {
                  row: row as Replace<ReportRow, { _embedded: { signingProfile: SignatureProfile; steps: Array<FlowStep> } }>,
                  collapsed: this.flowSelectedCount >= 10
                };
                this.profileRows.push(item);
              }
            }
          });
          this.validStepIdsWithoutAdHoc = this.getValidStepIdsWithoutAdHoc();
        }
      }
          // eslint-disable-next-line no-empty
      catch (ignore) {
      }
      finally {
        this.loading = false;
      }
    },

    getValidStepIds(): Array<number> {
      let validReportRows = this.reportRows.filter(row => !this.isReportStateError(row));
      let validStepIds: Array<number> = [];
      validReportRows.forEach(row => {
        let stepIds = row._embedded?.steps?.map(step => step.stepId);
        validStepIds.push(...stepIds || []);
      });
      return validStepIds;
    },

    getValidStepIdsWithoutAdHoc(): Array<number> {
      let validReportRows = this.reportRows.filter(row => !this.isReportStateError(row));
      let validStepIdsWithoutAdHoc: Array<number> = [];
      validReportRows.forEach(row => {
        let stepIds = row._embedded?.steps?.map(step => step.stepId);
        if (stepIds) {
          stepIds.forEach(value => {
            if (!this.selectedAdHocStepIds?.includes(value)) {
              validStepIdsWithoutAdHoc.push(value);
            }
          })
        }
      });
      return validStepIdsWithoutAdHoc;
    },

    isReportStateError(reportRow: ReportRow): boolean {
      return reportRow.reportStateType === 'error';
    },

    reset(): void {
      this.batchName = undefined;
      this.reportRows = [];
      this.validStepIds = [];
      this.flowRows = [];
      this.profileRows = [];
      this.interventionRows = [];
      this.image = undefined;
      this.validStepIdsWithoutAdHoc = [];
    },

    selectedAdHocFlowIds(): Array<number> {
      let array = [] as number[];

      this.flowSelected?.forEach(flow => {
        if (this.selectedAdHocStepIds?.includes(flow.myActiveStepId)) {
          array.push(flow.flowId);
        }
      })
      return array;
    },

    selectedFlowWithoutAdHoc(): Array<number> {
      let array = [] as number[];

      this.selectedFlowIds.forEach(value => {
        if (!this.selectedAdHocFlowIds().includes(value)) {
          array.push(value);
        }
      })
      return array
    },

    async signWithoutImage(): Promise<void> {
      const batchName = this.batchName || '';
      try {
        this.btnLoading = true;
        const data = await this.$store.dispatch('batches/createBatch', {
          batchName,
          flowIds: this.selectedFlowWithoutAdHoc(),
          flowPath: this.$route.path,
          stepIds: this.validStepIdsWithoutAdHoc,
        } as BatchCreatePayload);

        this.$store.commit('interactionCenter/showBatchProcessing', {batch: data});

      }
      catch (e) {
        console.error('Error occurred while signing the documents in bulk', e);
      }
      finally {
        this.btnLoading = false;
        this.$store.commit('navigation/foldersRefresh');
        this.close();
      }
    },

    async signWithImage(): Promise<void> {
      const batchName = this.batchName || '';
      try {
        this.btnLoading = true;
        const data = await this.$store.dispatch('batches/createBatch', {
          batchName,
          flowIds: this.selectedFlowIds,
          flowPath: this.$route.path,
          stepIds: this.validStepIds,
          signatureImage: this.image,
        } as BatchCreatePayload);

        this.$store.commit('interactionCenter/showBatchProcessing', {batch: data});

      }
      catch (e) {
        console.error('Error occurred while signing the documents in bulk with image', e);
      }
      finally {
        this.btnLoading = false;
        this.$store.commit('navigation/foldersRefresh');
        this.close();
      }
    },

    async sign(): Promise<void> {
      if(this.interventionRows.length > 0){
        let signatureValid = await (this.$refs.signatureSelector as InstanceType<typeof SSignatureSelector>).validate();
        if(!signatureValid) return;
        await this.signWithImage();
      }
      else {
        await this.signWithoutImage();
      }
    }
  },
  created(){
    this.adHocAvailableSigningMethods();
  }
})


import interactionCenterBatchMixin from "@/mixins/interactionCenterBatchMixin";
import mixins from "vue-typed-mixins";
import STooltip from "@/component/ui/STooltip.vue";

export default mixins(interactionCenterBatchMixin).extend({
  name: 'InteractionCenterBatchError',
  components: {STooltip},
  props: {
    mobileView: Boolean
  },

  computed: {
    title(): string {
      return this.isBatch ? this.batchName : this.batch.batchName;
    }
  }
})

import axios from "@/plugin/vueAxios";
import {Flow, FlowsRecall} from "@/types";
import {BulkChangeResult} from "@/types/dto";

const WORKFLOWS_PATH = '/workflows';
const WORKFLOWS_RECALLED_PATH = '/workflows/recalled';

export async function apiWorkflowGet(flowId: Flow['flowId']): Promise<Flow> {
  const response = await axios.get<Flow>(`${WORKFLOWS_PATH}/${flowId}`);
  return response.data;
}

export async function apiWorkflowsRecall(flowIds: FlowsRecall['flowIds'],
                                         recallNote: FlowsRecall['recallNote']): Promise<BulkChangeResult> {
  const recall: FlowsRecall = {
    flowIds,
    recallNote
  };

  const response = await axios.post<BulkChangeResult>(WORKFLOWS_RECALLED_PATH, recall);
  return response.data;
}
import {VuetifyThemeVariant} from "vuetify/types/services/theme";

export const light = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#C02B2B',
  info: '#0070AC',
  success: '#1B7D0F',
  warning: '#936A01',
  // Text
  mainText: '#374355',
  mutedText: '#ADB9C9',
  secondaryText: '#53657D',
  successText: '#FFFFFF',
  // Contrast background
  mainContrast: '#D86985',
  mutedContrast: '#6B7380',
  secondaryContrast: '#4CA7E2',
  // Contrast text
  mainContrastText: '#FFFFFF',
  secondaryContrastText: '#FFFFFF',
  // Common background colors
  defaultBackground: '#FFFFFF', // výchozí pozadí Vuetify pro světlý motiv
  mainBackground: '#E8E9EA',
  secondaryBackground: '#F5F5F5',
  mutedBackground: '#FAFAFA',
  navigationBackground: {
    base: '#EDEDED',
    lighten1: '#F5F5F5'
  },
  // Other
  scrollbar: '#727272',

  //New accessibility color scheme
  textPrimary:'#3D3D3D',
  textSecondary:'#464646',
  textTernary:'#656565',
  backgroundPrimary:'#F8F8F8',
  backgroundSecondary:'#ECECEC',
  backgroundTernary:'#DCDCDC',
  deepContrast:'#F8F8F8',
} as VuetifyThemeVariant

export const dark = {
  primary: '#21CFF3',
  secondary: '#FFE18D',
  accent: '#FF4081',
  error: '#DC5959',
  info: '#3395CA',
  success: '#4BA140',
  warning: '#CF9502',
  //Text
  mainText: '#EAEAEA',
  mutedText: '#8A8A8A',
  secondaryText: '#CACACA',
  successText: '#FFFFFF',
  // Contrast background
  mainContrast: '#D86985',
  mutedContrast: '#979da6',
  secondaryContrast: '#4CA7E2',
  // Contrast text
  mainContrastText: '#FFFFFF',
  secondaryContrastText: '#FFFFFF',
  // Common background colors
  defaultBackground: '#121212', // výchozí pozadí Vuetify pro tmavý motiv
  mainBackground: '#373635',
  secondaryBackground: '#2A2A2A',
  mutedBackground: '#252525',
  navigationBackground: {
    base: '#323232',
    lighten1: '#2A2A2A'
  },
  // Other
  scrollbar: '#727272',

  //New accessibility color scheme
  textPrimary:'#ECECEC',
  textSecondary:'#DCDCDC',
  textTernary:'#989898',
  backgroundPrimary:'#292929',
  backgroundSecondary:'#3D3D3D',
  backgroundTernary:'#464646',
  deepContrast:'#292929',
} as VuetifyThemeVariant

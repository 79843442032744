import {Flow} from "@/types/workflow";
import {UserAccount} from "@/types/user";
import {ThemeOnlineSignatureCustomization} from "@/types/theme";

export type SignatureComponentName = 'SignatureExternalView' | 'SignatureOnlineView';

export type SignatureComponentProps = {
  flowId: number | null,
  flowStepId: number | null,
  personalInfo?: UserAccount,
  redirectUri?: string,
  skipIntro?: boolean,
  skipConfirmation?: boolean,
  token?: string
}

export type SignatureOnlineConfig = {
  componentName: string,
  props: SignatureOnlineConfigProps,
  listeners: object
}

export type SignatureOnlineConfigProps = {
  actionBack?: boolean,
  actionNext?: boolean,
  flow?: Flow,
  navCancel?: boolean,
  customization?: ThemeOnlineSignatureCustomization
}

export enum SignatureOnlinePhase {
  DOCUMENT_DETAIL = 'document_detail',
  DOCUMENT_INVALID = 'document_invalid',
  INTRO = 'intro',
  REJECTION = 'rejection',
  REJECTION_CONFIRMATION = 'rejection_confirmation',
  SUCCESS = 'success',
  USER_NAME = 'user_name',
  USER_VISUALISATION = 'user_visualisation'
}

export enum SignatureOnlineRejectionPhase {
  CANCEL = 'cancel',
  CONFIRMATION = 'confirmation',
  CONFIRMED = 'confirmed'
}

import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import PDFToolbarBtn from "@/component/pdf/toolbar/PDFToolbarBtn.vue";
import PDFToolbarPagination from "@/component/pdf/toolbar/PDFToolbarPagination.vue";
import PDFToolbarScaleSelect from "@/component/pdf/toolbar/PDFToolbarScaleSelect.vue";
import {PDF_SCALE_MAX, PDF_SCALE_MIN} from "@/utils/pdfViewerUtils";
import {PdfToolCustom} from "@/types";

export default mixins(displayMixin).extend({
  name: 'PDFToolbar',

  components: {PDFToolbarScaleSelect, PDFToolbarBtn, PDFToolbarPagination},

  props: {
    color: String,
    customTools: Array as PropType<Array<PdfToolCustom> | undefined>,
    currentPageNumber: {
      type: Number,
      required: true
    },
    currentScaleValue: {
      type: String,
      required: true
    },
    fullscreen: Boolean,
    hasFlowDetailAction: {
      type: Boolean,
      default: false
    },
    pagesCount: {
      type: Number,
      required: true
    },
    scroll: Number
  },

  data() {
    return {
      PDF_SCALE_MAX,
      PDF_SCALE_MIN,
      secondaryToolbarVisible: false
    }
  },

  computed: {
    customToolsVisible(): Array<PdfToolCustom> | undefined {
      return this.customTools?.filter(tool => tool.visible);
    },
    fullscreenToggleIcon(): string {
      return this.fullscreen ? '$pdfFullscreenExit' : '$pdfFullscreen';
    },
    fullscreenToggleText(): string {
      return this.fullscreen ? this.$t('pdfViewer.actions.fullscreenExit').toString() :
          this.$t('pdfViewer.actions.fullscreen').toString();
    },
    page: {
      get(): number {
        return this.currentPageNumber;
      },
      set(value: number): void {
        this.$emit('page-change', value);
      }
    },
    scale: {
      get(): string {
        return this.currentScaleValue;
      },
      set(value: string): void {
        this.$emit('scale-change', value);
      }
    }
  },

  methods: {
    download(): void {
      this.$emit('download');
    },
    fullscreenToggle(): void {
      this.secondaryToolbarVisible = false;
      this.$emit('fullscreen-toggle');
    },
    pageFirst(): void {
      this.$emit('page-first');
    },
    pageLast(): void {
      this.$emit('page-last');
    },
    pageNext(): void {
      this.$emit('page-next');
    },
    pagePrev(): void {
      this.$emit('page-previous');
    },
    rotateClockwise(): void {
      this.$emit('rotate-cw');
    },
    rotateCounterclockwise(): void {
      this.$emit('rotate-ccw');
    },
    zoomIn(): void {
      this.$emit('zoom-in');
    },
    zoomOut(): void {
      this.$emit('zoom-out');
    }
  }
})


import mixins from "vue-typed-mixins";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import SSelect from "@/component/ui/form/SSelect.vue";
import STextField from "@/component/ui/form/STextField.vue";
import {VisualReasonTypes} from "@/types";
import SDialog from "@/component/ui/SDialog.vue";
import displayMixin from "@/mixins/displayMixin";
import SForm from "@/component/ui/form/SForm.vue";
import FlowDetailActionDialog from "@/component/workflow/detail/FlowDetailActionDialog.vue";
import {ValidationObserver} from "vee-validate";

export default mixins(displayMixin).extend ({
  name: "FlowReasonToSignDialog",
  components: {SForm, STextField, SSelect, STextButton, SDialog, FlowDetailActionDialog},
  props: {
    flow: Object,
    title: String,
    icon: String,
    text: String,
    value: {
      type: Boolean,
      required: true
    },
    notSupportedSignDetail: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return{
      open: false,

      //v-models selects
      selectedPlace: undefined as VisualReasonTypes | undefined,
      selectedContact:  undefined as VisualReasonTypes | undefined,
      selectedReason: undefined as VisualReasonTypes | undefined,

      //v-models text fields
      customReason: undefined as string | undefined,
      customPlace: undefined as string | undefined,
      customContact: undefined as string | undefined,

      //show textFields
      showCustomReason: false,
      showCustomPlace: false,
      showCustomContact: false,

    }
  },
  computed: {
    getReasonOptions(): Array<VisualReasonTypes> {
      return this.$store.getters['config/reasonOptions'];
    },
    getContactInfoOptions(): Array<VisualReasonTypes> {
      return this.$store.getters['config/contactInfoOptions'];
    },
    getLocationOptions(): Array<VisualReasonTypes> {
      return this.$store.getters['config/locationOptions'];
    },
    activeStepId(): number {
      return this.flow?.myActiveStepId;
    },
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    setContactValue(): string | undefined {
      return this.selectedContact?.usageMethod === 'USER_INPUT' ? this.customContact : this.selectedContact?.textValue;
    },
    setReasonValue(): string | undefined {
      return this.selectedReason?.usageMethod === 'USER_INPUT' ? this.customReason : this.selectedReason?.textValue;
    },
    setPlaceValue(): string | undefined {
      return this.selectedPlace?.usageMethod === 'USER_INPUT' ? this.customPlace : this.selectedPlace?.textValue;
    },
    async confirm(): Promise<void> {
      let visualReasons = {
        "reason": {"usageMethod": this.selectedReason?.usageMethod, "textValue": this.setReasonValue()},
        "location": {"usageMethod": this.selectedPlace?.usageMethod, "textValue": this.setPlaceValue()},
        "contactInfo": {"usageMethod": this.selectedContact?.usageMethod, "textValue": this.setContactValue()},
      }
      try {
        await this.axios.put('/flow-steps/' + this.activeStepId + '/signature-details', visualReasons);
        this.$emit('reason-set-reload');
        this.$store.commit('notification/showMessage',
            {content: this.$t('docDetail.reasonToSign.successNotification'), type: 'success'});
        this.open = false;
      }
      catch (e) {
        this.$store.commit('notification/showMessage',
            {content: this.$t('docDetail.reasonToSign.errorNotification'), type: 'error'});
        console.log(e);
      }
      finally {
        this.open = false;
        this.$emit('reason-set-reload');
      }
    },
    async save(): Promise<void> {
      let visualReasonForm = await (this.$refs.visualReasonForm as InstanceType<typeof ValidationObserver>).validate();
      if(visualReasonForm){
        await this.confirm();
      }
    },
    clear(): void {
      this.selectedPlace = undefined;
      this.selectedContact = undefined;
      this.selectedReason = undefined;

      this.customPlace = undefined;
      this.customReason = undefined;
      this.customContact = undefined;

      this.showCustomPlace = false;
      this.showCustomReason = false;
      this.showCustomContact = false;
    },
    async loadVisualReasons(): Promise<void> {
      try {
        let response = await this.axios.get('/flow-steps/' + this.activeStepId + '/signature-details');
        if (response.data) {
          if (response.data?.contactInfo) {
            this.selectedContact = this.findVisualReasonType(this.getContactInfoOptions, response.data.contactInfo);
            if (response.data?.contactInfo.usageMethod === 'USER_INPUT' && response.data?.contactInfo.textValue !=
                undefined) {
              this.customContact = response.data.contactInfo.textValue;
            }
            else {
              this.customContact = undefined;
            }
          }
          if (response.data?.location) {
            this.selectedPlace = this.findVisualReasonType(this.getLocationOptions, response.data.location);
            if (response.data?.location.usageMethod === 'USER_INPUT' && response.data?.location.textValue !=
                undefined) {
              this.customPlace = response.data.location.textValue;
            }
            else {
              this.customPlace = undefined;
            }
          }
          if (response.data?.reason) {
            this.selectedReason = this.findVisualReasonType(this.getReasonOptions, response.data.reason);
            if (response.data?.reason?.usageMethod === 'USER_INPUT' && response.data?.reason?.textValue != undefined) {
              this.customReason = response.data.reason.textValue;
            }
            else {
              this.customReason = undefined;
            }
          }
        }
      }
      catch (e) {
        console.log(e)
      }
    },
    findVisualReasonType(config: Array<VisualReasonTypes>, response: VisualReasonTypes): VisualReasonTypes | undefined {
      if (response.usageMethod === 'STATIC_VALUE') {
        return config.find(obj => obj.usageMethod === 'STATIC_VALUE' && obj.textValue === response.textValue);
      }
      else {
        return config.find(obj => obj.usageMethod === response.usageMethod);
      }
    },
    reloadFlow() {
      this.$emit('reason-set-reload');
    },
  },
  watch: {
    selectedReason(value) {
      this.showCustomReason = value.usageMethod === 'USER_INPUT';
    },
    selectedPlace(value) {
      this.showCustomPlace = value.usageMethod === 'USER_INPUT';
    },
    selectedContact(value) {
      this.showCustomContact = value.usageMethod === 'USER_INPUT';
    }
  },
  created() {
    this.loadVisualReasons();
  },
  updated() {
    this.loadVisualReasons();
  }
});

import { render, staticRenderFns } from "./ProfileMenu.vue?vue&type=template&id=4b239d7a&scoped=true&"
import script from "./ProfileMenu.vue?vue&type=script&lang=ts&"
export * from "./ProfileMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b239d7a",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./StepEnclosures.vue?vue&type=template&id=170808d6&scoped=true&"
import script from "./StepEnclosures.vue?vue&type=script&lang=ts&"
export * from "./StepEnclosures.vue?vue&type=script&lang=ts&"
import style0 from "./StepEnclosures.vue?vue&type=style&index=0&id=170808d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170808d6",
  null
  
)

export default component.exports

import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";

export default mixins(displayMixin).extend({
  name: 'SDialog',

  components: {SIconButton},

  props: {
    color: String,
    dataTestid: String,
    fullscreen: Boolean,
    overlay: {
      type: Boolean,
      default: true
    },
    title: String,
    value: Boolean,
    width: {
      type: String,
      default: '65%'
    }
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  }
})

import {AxiosRequestConfig} from "axios";
import axios from "@/plugin/vueAxios";
import {Folder, FolderList, FolderMode} from "@/types";

const FOLDERS_PATH = '/folders';

export async function apiFoldersGet(folderMode: FolderMode): Promise<Array<Folder> | undefined> {
  const config: AxiosRequestConfig = {
    params: {
      folderMode
    }
  };

  const response = await axios.get<FolderList>(FOLDERS_PATH, config);
  return response.data._embedded?.folders;
}
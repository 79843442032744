export const AUTOCOMPLETE_MIN_LENGTH = 3;
export const IMAGE_DATA_URI_PREFIX = "data:image/png;base64,"

export enum ACCOUNT_TYPE {
    STANDARD = 'standard',
    EXTERNAL = 'external',
    TEMPORARY = 'temporary',
    SYSTEM = 'system',
    UNKNOWN = 'unknown'
}

export enum SIGNATURE_PHASE {
    NONE,
    READ,
    SIGNATURE_FIELD,
    SIGN,
    AUTHORIZATION,
    PROCESSING,
    CLIENT_SIGNING,
    ERROR,
    SUCCESS,
    FORBIDDEN,
    BULK_MODE,
    DISABLED,
    REQUIRED_READ
}

export enum HARD_DUE_DATE_MODE {
    CHOOSE = 'CHOOSE',
    SOFT = 'SOFT',
    HARD = 'HARD'
}

export enum OS {
    WINDOWS = 'windows',
    MACOS = 'macos',
    LINUX = 'linux',
    IOS = 'ios',
    ANDROID = 'android'
}

export enum NotificationType {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success'
}

export const FOLDERS_LIMITATIONS = {
    "draft": ["submittedAt"],
    "active-my": ["submittedAt", "stepDueDate"],
    "finished": ["changedAt", "submittedAt"],
    "canceled": ["changedAt", "submittedAt"],
    "active-others": ["changedAt", "submittedAt", "stepDueDate"],
    "search" : ["changedAt", "submittedAt", "stepDueDate", "dueDate"]
} as {[key: string]: Array<string>}

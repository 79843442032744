
import Vue, {PropType} from "vue";
import NavigationTabs from "@/component/nav/NavigationTabs.vue";
import {NavigationMode} from "@/types";

export default Vue.extend({
  name: 'NavigationBottomToolbar',
  components: {NavigationTabs},
  props: {
    batchMode: {
      type: Boolean,
      required: true
    },
    content: {
      type: String as PropType<NavigationMode>,
      required: true
    }
  },
  computed: {
    localBatchMode: {
      get(): number|undefined {
        return this.batchMode ? 0 : undefined;
      },
      set(value: number|undefined) {
        this.$emit('change-batch-mode', value !== undefined);
      }
    },
    localContent: {
      get(): NavigationMode {
        return this.content;
      },
      set(value: NavigationMode) {
        this.$emit('change-content', value);
      }
    },
    visibleBatchMode(): boolean{
      return this.localContent === NavigationMode.NAV;
    }
  }
})

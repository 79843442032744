
import {PropType} from "vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import signatureOnlineMixin from "@/mixins/signatureOnlineMixin";
import AccountInfo from "@/component/AccountInfo.vue";
import CenteredPanelLayout from "@/component/layouts/CenteredPanelLayout.vue";
import SignatureContentAlert from "@/component/signature/SignatureContentAlert.vue";
import SignatureContentLogo from "@/component/signature/SignatureContentLogo.vue";
import SignatureExternalContent from "@/component/signature/external/SignatureExternalContent.vue";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import {apiFlowStepsReject} from "@/services/api/flowStepsApi";
import {apiWorkflowGet} from "@/services/api/workflowsApi";
import {
  FlowStepState,
  SignatureOnlinePhase,
  SignatureOnlineRejectionPhase,
  UserAccount
} from "@/types";
import {parseJwtPayload, parseJwtTokenData} from "@/utils/utils";
import {getStepActive} from "@/utils/flowUtils";

/*
 * Stránka podpisu externím uživatelem je téměř shodná se stránkou online podpisu (SignatureOnlineView.vue). Avšak z
 * důvodu přehlednosti a budoucího rozvoje jsou stránky oddělené.
 */
export default mixins(displayMixin, signatureOnlineMixin).extend({
  name: 'SignatureExternalView',

  components: {
    AccountInfo,
    CenteredPanelLayout,
    SIconButton,
    SignatureContentAlert,
    SignatureContentLogo,
    SignatureExternalContent
  },

  props: {
    personalInfo: {
      type: Object as PropType<UserAccount>,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      phaseOrig: undefined as SignatureOnlinePhase | undefined
    }
  },

  computed: {
    currentPhase(): SignatureOnlinePhase {
      return this.$store.getters["onlineSignature/onlineSignaturePhase"];
    },
    phaseDocumentDetail(): boolean {
      return this.currentPhase === SignatureOnlinePhase.DOCUMENT_DETAIL;
    }
  },

  async created() {
    const jwt = parseJwtPayload(this.token);
    const jwtTokenData = parseJwtTokenData(jwt);
    const tokenFlowId = jwtTokenData.get('flowId');
    const tokenStepId = jwtTokenData.get('stepId') || jwtTokenData.get('stepIds');

    if (jwtTokenData.get('external') === 'true') {
      if (!this.flowId || this.flowId.toString() !== tokenFlowId
          || !this.flowStepId || !this.foundStepId(tokenStepId)) {
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      }
      await this.$store.dispatch('profile/loadVisualisations');
      const visualisations = this.$store.getters['profile/visualisations'];
      if (visualisations && visualisations.length > 0) {
        await this.loadFlow();
      }
      else {
        this.changePhase(SignatureOnlinePhase.USER_NAME);
      }
    }
    else if (jwtTokenData.get('temporary') === 'true') {
      if (!this.flowId || this.flowId.toString() !== tokenFlowId
          || !this.flowStepId || !this.foundStepId(tokenStepId)) {
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      }
      else {
        await this.loadFlow();
      }
    }
    else
      console.error('JWT token data does not contain the external or temporary flag set to true');
  },

  methods: {
    changePhase(phase: SignatureOnlinePhase | undefined): void {
      this.$store.commit('onlineSignature/setOnlineSignaturePhase', phase);
    },
    async loadFlow(setPhase = true): Promise<void> {
      if (!this.flowId || !this.flowStepId)
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      try {
        this.flow = await apiWorkflowGet(this.flowId);
        if (setPhase)
          this.onFlowLoaded();
      }
      catch (e) {
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      }
    },
    onFlowLoaded(): void {
      if (!this.step || !this.flow)
        this.changePhase(SignatureOnlinePhase.DOCUMENT_INVALID);
      else {
        if (getStepActive(this.flow) && this.stepsAvailable)
          this.changePhase(this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO);
        else if ((getStepActive(this.flow)?.state === FlowStepState.FINISHED || this.flow.state == "finished") &&
            !this.stepsAvailable)
          this.changePhase(SignatureOnlinePhase.DOCUMENT_DETAIL);
        else
          this.changePhase(this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO);
      }
    },
    async onFlowRejected(): Promise<void> {
      if (this.redirectUri)
        this.redirectToSystem(true);
      else {
        this.changePhase(SignatureOnlinePhase.REJECTION_CONFIRMATION);
        await this.loadFlow(false);
      }
    },
    async rejection(rejectionPhase: SignatureOnlineRejectionPhase, rejectionReason?: string): Promise<void> {
      switch (rejectionPhase) {
        case SignatureOnlineRejectionPhase.CANCEL:
          this.changePhase(this.phaseOrig)
          break;
        case SignatureOnlineRejectionPhase.CONFIRMATION:
          if (this.currentPhase !== SignatureOnlinePhase.REJECTION)
            this.phaseOrig = this.currentPhase;
          this.changePhase(SignatureOnlinePhase.REJECTION);

          break;
        case SignatureOnlineRejectionPhase.CONFIRMED:
          /*
           * V případě podpisu externím uživatelem je umožněno pouze odmítnutí podpisu, protože odvolání podpisu z
           * pohledu business procesu nedává smysl.
           */
          if (this.flow?.myActiveStepId) {
            try {
              const data = await apiFlowStepsReject([this.flow.myActiveStepId], rejectionReason);
              if (data.succeeded[0] === this.flow.myActiveStepId) {
                this.changePhase(SignatureOnlinePhase.REJECTION_CONFIRMATION);
                await this.onFlowRejected();
              }
              else
                console.error(`An incorrect step ${data.succeeded[0]} has been rejected`);
            }
            catch (e) {
              console.error(`An error occurred while rejecting the step ${this.flow.myActiveStepId}`, e);
            }
          }
          else
            console.warn('The step cannot be rejected due to a missing identifier');
          break;
      }
    },
    async success(): Promise<void> {
      if (!this.isNextStepMine) this.changePhase(SignatureOnlinePhase.SUCCESS);
      await this.loadFlow(false);
    },
    async userConfigured(): Promise<void> {
      await this.loadFlow(false);
      this.changePhase(this.skipIntro ? SignatureOnlinePhase.DOCUMENT_DETAIL : SignatureOnlinePhase.INTRO);
    },
    navOpen(): void {
      this.$emit('nav-open');
    },
    foundStepId(stepIds: string | undefined): boolean {
      if (stepIds) {
        const seperatedStepIds = stepIds?.split(" | ");
        return seperatedStepIds.includes(this.flowStepId.toString());
      }
      else return false
    }
  }
})

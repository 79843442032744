
import {PropType} from "vue";
import {IconSelectItem} from "@/types";
import mixins from "vue-typed-mixins";
import formMixin from "@/mixins/formMixin";

export default mixins(formMixin).extend({
  name: 'SIconSelect',
  props: {
    backgroundColor: {
      type: String,
      default: 'secondaryBackground'
    },
    backgroundRadius: {
      type: Number,
      default: 18,
    },
    itemColor: {
      type: String,
      default: 'secondaryText'
    },
    selectedItemColor: {
      type: String,
      default: 'secondaryText'
    },
    height: {
      type: Number,
      default: 40
    },
    items: {
      type: Array as PropType<IconSelectItem[]>,
      required: true
    },
    iconSize: {
      type: Number,
      default: 20
    },
    value: String
  },
  computed: {
    localValue: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      }
    },
    cssProps(): object {
      return {
        '--s-icon-select-background-radius': this.backgroundRadius + 'px',
        '--s-icon-select-list-background': this.$vuetify.theme.currentTheme[this.backgroundColor]?.toString()
      }
    }
  }
})


import Vue, {PropType} from "vue";
import {Flow} from "@/types";
import {formatDateShort} from "@/utils/dateUtils";
import {nameToDisplay} from "@/utils/accountUtils";

export default Vue.extend({
  name: 'FlowBatchItem',

  props: {
    flow: {
      type: Object as PropType<Flow>,
      required: true
    },
    error: String,
    icon: String,
    iconColor: String,
    collapsed: Boolean,
    enableView: Boolean
  },

  computed: {
    name(): string {
      return nameToDisplay(this.flow._embedded.owner);
    },
    localCollapsed: {
      get(): boolean {
        return this.collapsed;
      },
      set(value: boolean){
        this.$emit('update:collapsed', value);
      }
    }
  },
  methods: {
    formatDate(value: string){
      return formatDateShort(value);
    },
    async view() {
      try {
        this.$store.dispatch('workflow/viewFlow', {flowUrl: this.flow._links.self.href});
        this.$emit('view');
      }
      catch (e) {
        this.$store.commit('notification/showMessage', {content: this.$t('common.errors.general.short'), type: 'error'});
      }
    }
  }

})

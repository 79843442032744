
import SDialog from "@/component/ui/SDialog.vue";
import mixins from "vue-typed-mixins";
import displayMixin from "@/mixins/displayMixin";
import mutationMixin from "@/mixins/mutationMixin";
import {MutationPayload} from "vuex";
import {Category, Dictionary, DocumentField, InputFlow, InputFlowStep, UploadWizardConfig, UserAccount} from "@/types";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import StepUpload from "@/component/workflow/uploadWizard/steps/StepUpload.vue";
import StepDetail from "@/component/workflow/uploadWizard/steps/StepDetail.vue";
import StepEnclosures from "@/component/workflow/uploadWizard/steps/StepEnclosures.vue";
import StepSigners from "@/component/workflow/uploadWizard/steps/StepSigners.vue";
import StepSignatureFields from "@/component/workflow/uploadWizard/steps/StepSignatureFields.vue";
import UploadWizardButtons from "@/component/workflow/uploadWizard/UploadWizardButtons.vue";
import {Vue} from "vue/types/vue"
import {AxiosResponse} from "axios";
import workflowMixin from "@/mixins/workflowMixin";

interface StepInterface extends Vue {
  validate: () => Promise<boolean>
}

export default mixins(displayMixin, mutationMixin, workflowMixin).extend({
  name: 'UploadWizard',

  components: {
    UploadWizardButtons,
    STextButton, SDialog, StepUpload, StepDetail, StepEnclosures, StepSigners, StepSignatureFields
  },

  props: {
    dataTestid: String
  },

  data() {
    return {
      open: false,
      step: 1,
      submitting: false,
      submitted: false,
      flow: undefined as InputFlow | undefined,
      stepValidity: [] as Array<boolean>,
      selectedCategoryCode: undefined as string | undefined,
      userBusinessLevelsMap: {} as Dictionary<{ [key: number]: boolean }>,
      fieldArray: [] as Array<DocumentField>,
      selectedCategory: undefined as Category | undefined
    }
  },

  computed: {
    config(): Array<UploadWizardConfig>{
      return [
        {
          title: 'uploadWizard.steps.docUpload.title',
          dataTestid: 'uploadWizard-stepper-upload',
          showIf: () => true,
          component: {
            name: 'StepUpload',
            ref: 'uploadStep',
            props: {
              value: this.flow,
              active: this.step === 1
            },
            events: {
              input: (value: InputFlow | undefined) => this.flow = value,
              remove: () => this.onFileRemove(),
              getSelectedCode: (value: string) => this.selectedCategoryCode = value,
              selectedCategory: (value: Category) => this.selectedCategory = value,
              valid: (valid: boolean) => this.onStepValid(1, valid),
            }
          },
          actions: ["next"]
        },
        {
          title: 'uploadWizard.steps.people.title',
          dataTestid: 'uploadWizard-stepper-signers',
          showIf: () => !!this.flow,
          component: {
            name: 'StepSigners',
            ref: 'signersStep',
            props: {
              value: this.flow?._embedded.steps,
              businessLevelsMap: this.userBusinessLevelsMap,
              categoryCode: this.selectedCategoryCode
            },
            events: {
              input: (value: InputFlowStep[]) => this.flow ? this.flow._embedded.steps = value : undefined,
              valid: (valid: boolean) => this.onStepValid(2, valid),
            }
          },
          actions: ["back", "next"]
        },
        {
          title: 'uploadWizard.steps.details.title',
          dataTestid: 'uploadWizard-stepper-details',
          showIf: () => !!this.flow,
          component: {
            name: 'StepDetail',
            ref: 'detailStep',
            props: {
              value: this.flow,
              selectedCategory: this.selectedCategory
            },
            events: {
              input: (value: InputFlow) => this.flow = value,
              valid: (valid: boolean) => this.onStepValid(3, valid)
            }
          },
          actions: ["back", "next"]
        },
        {
          title: 'uploadWizard.steps.signatureFields.title',
          dataTestid: 'uploadWizard-stepper-signatureFields',
          showIf: () => !!this.flow,
          component: {
            name: 'StepSignatureFields',
            ref: 'signatureFieldsStep',
            props: {
              active: this.step === 4,
              value: this.flow,
            },
            events: {
              getUserFields: (value: Array<DocumentField>) => this.fieldArray = value,
              valid: (valid: boolean) => this.onStepValid(4, valid),
            }
          },
          actions: ["back", "enclosures", "submit"]
        },
        {
          title: 'uploadWizard.steps.enclosures.title',
          dataTestid: 'uploadWizard-stepper-enclosures',
          showIf: () => !!this.flow,
          component: {
            name: 'StepEnclosures',
            ref: 'enclosureStep',
            props: {
              value: this.flow
            },
            events: {
              valid: (valid: boolean) => this.onStepValid(5, valid)
            }
          },
          actions: ["back", "submit"],
          postValidate: (valid: boolean) => {
            if(!valid) this.$store.commit('notification/showMessage', {content: this.$t('uploadWizard.actions.enclosuresWIP'), type: 'error'});
          }
        }
      ]
    },
    activeStep(): UploadWizardConfig {
      return this.config[this.step - 1];
    },
    availableStep(): number {
      for (let i = 0; i < this.stepValidity.length; i++) {
        if (!this.stepValidity[i]) return i + 1;
      }
      return this.stepValidity.length + 1;
    },
    workflowUpdateLink(): string | undefined {
      return this.flow?._links["sef:edit-flow"]?.href;
    },
    account(): UserAccount | undefined {
      return this.$store.getters["profile/info"]
    },
  },

  methods: {
    async cancel(): Promise<void> {
      if (this.flow && !this.submitted) {
        await this.update();
      }
      this.clear();
      ((this.$refs.uploadStep as Vue[])[0] as InstanceType<typeof StepUpload>).clear();
      this.open = false;
    },
    async submit(): Promise<void> {
      if (!this.workflowUpdateLink || !this.flow || !this.account) return;

      for (const componentConfig of this.config) {
        let component: StepInterface = ((this.$refs[componentConfig.component.ref] as (Vue)[])[0]) as StepInterface;
        let valid = await component.validate();
        if (componentConfig.postValidate) componentConfig.postValidate(valid);
        if (!valid) return;
      }

      try {
        this.submitting = true;
        this.flow._embedded.documentFields = this.fieldArray;
        let response = await this.submitFlow(this.workflowUpdateLink, this.flow, this.account);
        this.$emit("submit", response.data);
        this.submitted = true;
        this.open = false;
        this.$store.commit('navigation/foldersRefresh');
      }
      catch (e) {
        this.flow = this.parseDto(this.flow);
      }
      finally {
        this.submitting = false;
      }
    },
    async update(): Promise<void> {
      if (!this.workflowUpdateLink || !this.flow || !this.account) return;
      try {
        let response = await this.updateDraft(this.workflowUpdateLink, this.flow, this.account);
        this.flow = this.parseDto(response.data);
        this.$store.commit('notification/showMessage',
            {content: this.$t('uploadWizard.actions.savedInDrafts'), type: 'info'});
        this.$store.commit('workflow/updateFlow', {flow: response.data});
        this.$store.commit('navigation/refreshCounters');
      }
      catch (e) {
        this.flow = this.parseDto(this.flow);
      }
    },
    goToStep(step: number) {
      if (this.availableStep >= step) {
        this.step = step;
      }
    },
    prevStep(): void {
      this.goToStep(this.step - 1);
    },
    async nextStep(): Promise<void> {
      let component: StepInterface = ((this.$refs[this.config[this.step -
      1].component.ref] as (Vue)[])[0]) as StepInterface;
      let valid = await component.validate();
      if (valid) this.goToStep(this.step + 1);
    },
    onStepValid(step: number, valid: boolean) {
      this.$set(this.stepValidity, step - 1, valid);
    },
    clear(): void {
      this.step = 1;
      this.flow = undefined;
      this.submitted = false;
      this.stepValidity = [];
      this.userBusinessLevelsMap = {};
      this.fieldArray = [];
    },
    onFileRemove(): void {
      this.clear();
    },
    loadUsersBusinessLevels(): void {
      if (!this.flow) return;
      this.flow._embedded.steps.forEach(step => {
        if (!step._embedded.account?.accountName || !step._embedded.account._links) return;
        if (!this.userBusinessLevelsMap[step._embedded.account.accountName]) {
          this.axios.get(step._embedded.account._links.businessLevels.href).then((response: AxiosResponse) => {
            if (!step._embedded.account?.accountName) return;
            this.$set(this.userBusinessLevelsMap, step._embedded.account.accountName, response.data)
          });
        }
      })
    },
    openDraft(draft: InputFlow) {
      this.flow = this.parseDto(draft);
      ((this.$refs.uploadStep as Vue[])[0] as InstanceType<typeof StepUpload>).addPlaceholderFile(draft._embedded.document.documentName);
      ((this.$refs.uploadStep as Vue[])[0] as InstanceType<typeof StepUpload>).setFileIsUpload(true);
    },
  },

  watch: {
    open(newValue): void {
      if (!newValue) {
        this.cancel();
      }
    },
    flow(value) {
      if (value) this.loadUsersBusinessLevels();
    }
  },

  created() {
    this.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'workflow/openWorkflowWizard') {
        this.open = true;
      }
      if (mutation.type === 'workflow/openDraft') {
        this.open = true;
        this.openDraft(mutation.payload.draft);
      }
    });
  },
})


import Vue from "vue";

export default Vue.extend({
  name: 'PDFSidebar',

  props: {
    value: Boolean
  },

  computed: {
    localValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  }
})


import SForm from "@/component/ui/form/SForm.vue";
import STextField from "@/component/ui/form/STextField.vue";
import {cloneDeep} from "lodash";
import {SIGNING_PROFILES} from "@/utils/urls";
import BankFeature from "@/component/profile/signatures/features/BankFeature.vue";
import ExternalIdFeature from "@/component/profile/signatures/features/ExternalIdFeature.vue";
import Vue, {PropType} from 'vue';
import {
  Replace,
  SignatureMethod,
  SignatureProfile,
  SignatureProfileDetailConfig,
  SignatureProfileFormMode,
  SignatureProvider,
  Visualisation
} from "@/types";
import {ValidationObserver} from "vee-validate";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import ClientProviderCardCertCreateWarning
  from "@/component/profile/signatures/component/ClientProviderCardCertCreateWarning.vue";

interface LocalSignatureProfileEmbedded {
  signatureProvider?: number;
  visual?: number | null;
}

type LocalSignatureProfile = Replace<SignatureProfile, { _embedded: LocalSignatureProfileEmbedded }>

export default Vue.extend({
  name: 'SignatureProfileItem',
  components: {STextButton, ExternalIdFeature, ClientProviderCardCertCreateWarning, BankFeature, STextField, SForm},
  props: {
    item: {
      type: Object as PropType<SignatureProfile>,
      required: true
    },
    providers: {
      type: Array as PropType<SignatureProvider[]>,
      required: true
    },
    method: {
      type: Object as PropType<SignatureMethod>,
      required: true
    },
    config: {
      type: Object as PropType<SignatureProfileDetailConfig>,
      required: true
    }
  },

  data() {
    return {
      // This property is used instead of item in order to prevent unsaved changes to item object
      myItem: {} as Partial<LocalSignatureProfile>,
      // update or create
      mode: SignatureProfileFormMode.NONE as SignatureProfileFormMode,
      saving: false
    }
  },

  computed: {
    visualisations(): Array<Visualisation> | undefined {
      return this.$store.getters["profile/visualisations"];
    },
    isNameEditAllowed(): boolean {
      if (this.mode !== SignatureProfileFormMode.UPDATE) {
        return true
      }
      const allowNameEdit = this.config.allowNameEdit;
      if (allowNameEdit === undefined) {
        return true
      }
      if (typeof allowNameEdit === 'function') {
        return allowNameEdit(this.item)
      }
      return allowNameEdit
    },
    pageTitle(): string | undefined {
      if (this.mode === SignatureProfileFormMode.UPDATE) {
        return this.myItem?.profileName;
      }
      else if (this.mode === SignatureProfileFormMode.CREATE) {
        return "profile.signature.detail.new";
      }
      else {
        return undefined;
      }
    }
  },

  methods: {

    cancel(): void {
      this.$emit('close')
    },

    async save(): Promise<void> {
      let valid = await (this.$refs.itemForm as InstanceType<typeof ValidationObserver>).validate();
      if (this.config.features) {
        for (let i = 0; i < this.config.features.length; i++) {
          let refName = 'feature' + i;
          let ref = this.$refs[refName] as (Vue | Element)[]
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          if (!(ref[0] as unknown as any).valid()) {
            valid = false;
          }
        }
      }
      if (!valid) return;

      if (this.mode === SignatureProfileFormMode.CREATE) {
        await this.create(this.myItem);
      }
      else if (this.mode === SignatureProfileFormMode.UPDATE) {
        await this.update(this.myItem);
      }
    },

    async create(profile: Partial<LocalSignatureProfile>): Promise<void> {
      const mutate = cloneDeep(profile) as SignatureProfile;
      if (!mutate._embedded) return;
      if (this.providers.length === 1) {
        mutate._embedded.signatureProvider = this.providers[0];
      }
      else {
        mutate._embedded.signatureProvider = this.providers.find(
            p => p.providerId === profile._embedded?.signatureProvider);
      }

      if (this.config.visualisation) {
        mutate._embedded.visual = this.visualisations?.find(v => v.visualId === profile._embedded?.visual);
      }

      mutate.isEnabled = true;
      if (this.config.beforeCreateHook) {
        this.config.beforeCreateHook(mutate)
      }

      try {
        this.saving = true;
        let response = await this.axios.post(SIGNING_PROFILES, mutate);
        const createdProfile = response.data;
        this.$store.commit('profile/addSignatureProfileForMethod',
            {method: this.method.providerTypeCode, profile: createdProfile})
        this.saving = false;
        if (this.config.postCreateHook) {
          this.config.postCreateHook(createdProfile)
        }
        this.$emit('close');
      }
      catch (e) {
        this.myItem = profile;
        this.saving = false;
      }
    },

    async update(profile: Partial<LocalSignatureProfile>): Promise<void> {
      if (!profile._links?.["sef:edit-signing-profile"]) return;
      const mutate = cloneDeep(profile) as SignatureProfile;
      if (!mutate._embedded) return;
      mutate._embedded.signatureProvider = this.providers.find(
          p => p.providerId === profile._embedded?.signatureProvider);
      if (this.config.visualisation) {
        mutate._embedded.visual = this.visualisations?.find(v => v.visualId === profile._embedded?.visual);
      }

      try {
        this.saving = true;
        let response = await this.axios.put(profile._links?.["sef:edit-signing-profile"]?.href, mutate);
        this.$store.commit('profile/updateSignatureProfileForMethod',
            {method: this.method.providerTypeCode, profile: response.data})
        this.saving = false;
        this.$emit('close');
      }
      catch (e) {
        this.saving = false;
        this.myItem = profile;
      }
    },

    init() {
      if (this.item && this.item._embedded) {
        const myItem = cloneDeep(this.item) as LocalSignatureProfile;
        if (!myItem._embedded) myItem._embedded = {}
        this.mode = SignatureProfileFormMode.UPDATE;
        if (this.config.visualisation) {
          myItem._embedded.visual = myItem._embedded?.visual ? this.item._embedded.visual?.visualId : null;
        }
        myItem._embedded.signatureProvider = this.item._embedded.signatureProvider?.providerId;
        this.myItem = myItem;
      }
      else {
        this.myItem = {_embedded: {}}
        this.mode = SignatureProfileFormMode.CREATE;
      }
    }

  },

  watch: {
    item: function () {
      this.init();
    },
    pageTitle: {
      immediate: true,
      handler(value) {
        this.$emit('set-title', value);
      }
    }
  },

  created() {
    if (!this.visualisations && this.config.visualisation) {
      this.$store.dispatch('profile/loadVisualisations');
    }
    this.init()
  }

})

import {HalResource, Link} from "@/types/hal";
import {SignatureProfile} from "@/types/signature";
import {UserAccount} from "@/types/user";
import {Flow, FlowStep, FlowStepBase} from "@/types/workflow";
import {OptionalString} from "@/types/utility";

export enum AuthorizationType {
  CLIENT_DEVICE = 1,
  AUTHORIZATION_SERVER = 2,
  MOBILE_APP = 3
}

export enum BatchCreationMode {
  DO_NOT_CREATE = 'do_not_create',
  IGNORE_ERRORS = 'ignore_errors',
  WITHOUT_ERRORS_ONLY = 'without_errors_only'
}

export enum BatchProcessedAction {
  REJECT = 'reject',
  SIGN = 'sign',
  SIGNATURE_CHANGE = 'signature_change'
}

export enum BatchState {
  ACTION_REQUIRED = 'action_required',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  WITH_ERRORS = 'with_errors'
}

export enum ReportStateId {
  UNEXPECTED_STATE_PROCESSING = 123,
  MISSING_READ_FLAG = 130,
  MISSING_VISUAL_FIELD = 170,

  MISSING_PROFILE = 140,
  PROFILE_INACTIVE = 150,
  EXPIRED_CERTIFICATE = 181,

  // Podepsat už nepůjde -> aktualizace hlavičky + globální notifikace (error)
  EXPIRED_FLOW = 118,
  UNEXPECTED_STATE_CANCELLED = 127,
  UNEXPECTED_STATE_REJECTED = 126,

  // Podepsat už nepůjde -> aktualizace hlavičky + globální notifikace (info) -> zde není potřeba uživatele varovat chybovou hláškou
  UNEXPECTED_STATE_FINISHED = 125,
  FLOW_LOCK = 195
}

export enum ReportStateType {
  AUTHORIZATION = 'authorization',
  ERROR = 'error',
  FINISHED = 'finished',
  IN_PROGRESS = 'in_progress'
}

export interface Batch extends HalResource<BatchEmbedded, BatchLinks> {
  batchId: number;
  bulkMode: boolean;
  reportRows: Array<ReportRow>;
  batchName: string;
  batchState: BatchState;
  flowSubject: string;
  changedAt: string;
  count: number;
  createdAt: string;
  creationMode: BatchCreationMode;
  signatureImage?: string;
}

interface BatchEmbedded {
  account: UserAccount;
  steps: Array<FlowStep>;
}

interface BatchLinks {
  self: Link;
}

export interface BatchCreation {
  _embedded: BatchCreationEmbedded
  batchName?: OptionalString;
  bulkMode: boolean;
  creationMode: BatchCreationMode;
  signatureImage?: OptionalString;
  signingTextName?: OptionalString;
}

interface BatchCreationEmbedded {
  steps: Array<FlowStepBase>
}

export interface BatchCreatePayload {
  batchName: string;
  flowIds: Array<number>;
  flowsPath?: string;
  stepIds: Array<number>;
  signatureImage?: string;
}

export interface BatchInProgress {
  batch: Batch;
  checkTimeoutId: number;
  flowIds: Array<number>;
  flowsPath?: string;
}

export interface BatchProcessed {
  action: BatchProcessedAction;
  batch?: Batch;
  flowIds: Array<number>;
  flowsPath?: string;
}

export interface BatchStub extends HalResource<unknown, unknown> {
  batchId: number;
  bulkMode: boolean;
  reportRows: Array<ReportRow>;
}

export interface ReportRow extends HalResource<ReportRowEmbedded, ReportRowLinks> {
  authorizationRedirectCount: number;
  authorizationType: AuthorizationType;
  authorizationTypeDescription: string;
  count: number;
  countCanIncrease: boolean;
  noteCount: number;
  reportStateDescription: string;
  reportStateId: number;
  reportStateName: string;
  reportStateType: ReportStateType;
  signingMethodName: string;
  signingMethodNameShort: string;
  sigProfileIdent: string;
}

interface ReportRowEmbedded {
  flows: Array<ReportRowFlow>;
  signingProfile?: SignatureProfile;
  steps?: Array<FlowStep>;
  flow?: Flow;
}

export interface ReportRowFlow {
  idSpFlow: number;
  subject: string;
  links: ReportRowFlowLinks;
}

interface ReportRowFlowLinks {
  self: Link;
  "sef:document-data-download"?: Link;
  "sef:enclosures-list": Link;
}

export interface ReportRowLinks {
  "sef:abandon-authorization"?: Link;
  "sef:external-authorization"?: Link;
  "sef:get-client-signing-uri"?: Link;
}


import mixins from "vue-typed-mixins";
import mutationMixin from "@/mixins/mutationMixin";
import displayMixin from "@/mixins/displayMixin";
import workflowMixin from "@/mixins/workflowMixin";
import {FlowEnclosure, FlowEnclosureList, InputFlow, isDataEnclosure, PartialRecord, UrlFlowEnclosure} from "@/types";
import {PropType} from "vue";
import {Vue} from "vue/types/vue";
import SForm from "@/component/ui/form/SForm.vue";
import StepEnclosuresForm from "@/component/workflow/uploadWizard/steps/StepEnclosuresForm.vue";
import {downloadBlob, objectToFormJsonBlob} from "@/utils/utils";
import STextButton from "@/component/ui/buttons/STextButton.vue";
import Draggable from "vuedraggable";
import SIconButton from "@/component/ui/buttons/SIconButton.vue";
import {AxiosResponse} from "axios";

export default mixins(mutationMixin, displayMixin, workflowMixin).extend({
  name: "StepEnclosures",
  components: {SIconButton, StepEnclosuresForm, STextButton, SForm, Draggable},
  data(){
    return {
      enclosuresList: null as FlowEnclosureList | null,
      newEnclosureForm: null as {
        name: string,
        note: string,
        externalUrl: string,
        file: File | undefined,
        enclosureType: 'url' | 'data',
        busy: boolean
      } | null,
      editedEnclosure: null as FlowEnclosure | null,
      enclosureState: {} as PartialRecord<string, 'update' | 'delete' | 'none'>,
      enclosureTypes: {
         'url': this.$t('uploadWizard.steps.enclosures.new.type.url'),
        'data': this.$t('uploadWizard.steps.enclosures.new.type.data')
      }
    }
  },
  props: {
    value: {
      type: Object as PropType<InputFlow>,
      required: true
    }
  },

  computed: {
    localValue: {
      get(): InputFlow | undefined {
        return this.value;
      },
      set(value: InputFlow) {
        this.$emit('input', value);
      }
    },
    enclosures(): Array<FlowEnclosure> {
      return this.enclosuresList?._embedded?.enclosures ?? []
    },
    valid(): boolean {
      return !this.isAnyFormOpen;
    },
    isAnyFormOpen(): boolean {
      return this.newEnclosureForm !== null || this.editedEnclosure !== null;
    }
  },

  methods: {
    downloadEnclosureData(enclosure: FlowEnclosure) {
      if (isDataEnclosure(enclosure) && enclosure._links['sef:download-enclosure-data']) {
        this.axios.get(enclosure._links['sef:download-enclosure-data'].href, {
          responseType: 'blob'
        }).then((response: AxiosResponse) => {
          downloadBlob(new Blob([response.data]), enclosure.filename);
        })
      }
    },
    async onDragEnd(dragEvent: { oldIndex: number, newIndex: number }) {
      // When the item is moved, we update its item order and do an update.
      const movedEnclosure = this.enclosures[dragEvent.oldIndex];
      movedEnclosure.itemOrder = dragEvent.newIndex + 1; // item order uses one-based indexing
      await this.updateEnclosure(movedEnclosure);
    },
    async updateEnclosure(enclosure: FlowEnclosure) {
      // validate its form
      const formRef = this.getEnclosureFormId(enclosure);
      // if the modification is caused by change of the item order, then there is no form ()
      // When refs are created in a v-for loop, vue returns an array. When there is no form, there still can be a key in the object, however the array is empty.
      if ((this.$refs[formRef] as Vue[])?.length > 0) {
        let valid = await ((this.$refs[formRef] as Vue[])[0] as InstanceType<typeof SForm>).validate();
        if (!valid) return;
      }

      this.enclosureState = {...this.enclosureState, [enclosure.enclosureId]: 'update'}
      try {
        await this.axios.put(enclosure._links['sef:edit-enclosure'].href, enclosure);
      }
      finally {
        this.enclosureState = {...this.enclosureState, [enclosure.enclosureId]: 'none'}
        this.editedEnclosure = null;
      }
      await this.loadEnclosures();
    },
    async loadEnclosures() {
      const response = await this.axios.get(this.value._links['sef:enclosures-list'].href);
      this.enclosuresList = response?.data;
    },
    getEnclosureFormId(enclosure: FlowEnclosure): string {
      return `enclosureForm_${enclosure.enclosureId}`
    },
    isEdited(enclosure: FlowEnclosure): boolean {
      return enclosure.enclosureId === this.editedEnclosure?.enclosureId
    },
    startEditing(enclosure: FlowEnclosure) {
      // we can do shallow copy just because there is nothing to edit in the embedded object
      this.editedEnclosure = {...enclosure};
    },
    cancelEdit() {
      this.editedEnclosure = null;
    },
    isUpdating(enclosure: FlowEnclosure): boolean {
      return this.enclosureState[enclosure.enclosureId] === 'update';
    },
    isDeleting(enclosure: FlowEnclosure): boolean {
      return this.enclosureState[enclosure.enclosureId] === 'delete';
    },
    async deleteEnclosure(enclosure: FlowEnclosure) {
      this.enclosureState = {...this.enclosureState, [enclosure.enclosureId]: 'delete'}
      try {
        await this.axios.delete(enclosure._links['sef:delete-enclosure'].href);
      }
      finally {
        this.enclosureState = {...this.enclosureState, [enclosure.enclosureId]: 'none'}
      }
      await this.loadEnclosures();
    },
    async validate(): Promise<boolean> {
      // sanity check
      if (!this.localValue) {
        return false;
      }
      return this.valid;
    },
    createNewFormEntity() {
      this.newEnclosureForm = {
        name: '',
        note: '',
        externalUrl: '',
        file: undefined as File | undefined,
        enclosureType: 'url',
        busy: false,
      };
    },
    cancelNewEnclosure() {
      this.newEnclosureForm = null;
      (this.$refs.newEnclosureForm as InstanceType<typeof SForm>).reset();
    },
    async addNewEnclosure() {
      if (!this.enclosuresList || this.newEnclosureForm === null) {
        return
      }
      const valid = await (this.$refs.newEnclosureForm as InstanceType<typeof SForm>).validate();
      if (!valid) return;
      this.newEnclosureForm.busy = true
      try {
        if (this.newEnclosureForm.enclosureType === 'url') {
          // external url enclosure
          await this.axios.post(this.enclosuresList._links['sef:add-enclosure'].href, {
            enclosureType: "url",
            name: this.newEnclosureForm.name,
            note: this.newEnclosureForm.note,
            externalUrl: this.newEnclosureForm.externalUrl,
            itemOrder: this.enclosures.length + 1 // item order uses one based indexing
          } as UrlFlowEnclosure)
        }
        else {
          // data enclosure
          if (!this.newEnclosureForm.file) {
            console.error("Attempt to submit a data enclosure without a file.")
            return;
          }
          const form = new FormData();
          // We have to create blob since it is the only way to set the content type to json. Just setting the json string to the form would end up with content type application/text 400 response code from BE.
          const blob = objectToFormJsonBlob({
            name: this.newEnclosureForm.name,
            note: this.newEnclosureForm.note,
            itemOrder: this.enclosures.length + 1, // item order uses one based indexing
            // we send the name here because there is (afaik, otherwise todo) no way how to use the filename* extension to send utf-8 filenames
            filename: this.newEnclosureForm.file.name
          });
          form.append("enclosure", blob)
          form.append("enclosureData", this.newEnclosureForm.file);

          await this.axios.post(this.enclosuresList._links['sef:add-data-enclosure'].href, form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }
        this.cancelNewEnclosure();
      }
      catch (error) {
        console.error(error)
        this.newEnclosureForm.busy = false
      }
      this.loadEnclosures();
    },
  },

  watch: {
    valid: {
      handler(value) {
        this.$emit('valid', value);
      },
      immediate: true
    }
  },
  mounted() {
    this.loadEnclosures();
  }
})


import {InputFlow, QuickActions} from "@/types";
import SFileDropZone from "@/component/ui/form/SFileDropZone.vue";
import mixins from "vue-typed-mixins";
import workflowMixin from "@/mixins/workflowMixin";
import {PropType} from "vue";

export default mixins(workflowMixin).extend({
  name: "QuickActionDropZone",
  components: {SFileDropZone},
  props: {
    value: {
      type: Object as PropType<InputFlow>,
      required: true
    },
  },
  data() {
    return {
      filename: null as string | null,
      isUpload: false,
      uploading: false,
      documentFile: undefined as File | undefined,
    }
  },
  computed: {
    localValue: {
      get(): InputFlow | undefined {
        return this.value;
      },
      set(value: InputFlow) {
        this.$emit('input', value);
      }
    },
    styleFileSelector(): object {
      return {
        height: '160px',
        width: '250px'
      }
    },
    actions(): Array<QuickActions> {
      return this.$store.getters['config/actions'];
    },
    actionUploadLink(): string | undefined {
      return this.actions[0]._links["sef:perform-action"].href;
    },
  },
  methods: {
    onUploadCompleted(flow: InputFlow): void {
      this.uploading = true;
      let tmpFlow;
      if(flow) {
        tmpFlow = this.parseDto(flow);
        this.localValue = tmpFlow;
        this.isUpload = true;
        setTimeout(() => {
          this.$store.commit('notification/showMessage',
              {content: this.$t('quickActions.notification.fileUploaded'), type: "success"})
          this.$store.commit('navigation/foldersRefresh');
          this.uploading = false;
        }, 2000);
      }
    },
  },
})
